import { Flex, Heading, Text, styled } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useDisplayToast } from 'src/hooks/useDisplayToast';
import { MEBlueprintDropdown } from './MEBlueprintDropdown';
import type { AuthBlueprint } from './ManualEnrollment.types';
import { downloadBlueprint } from './ManualEnrollmentActionCreators';

const EnrollSingleContainer = styled(Flex, {
  border: '1px solid #D7E1ED',
  borderRadius: '$1',
  width: '100%',
  padding: '19px 24px',
});

interface ManualEnrollmentSingleProps {
  blueprints: AuthBlueprint[];
}

export function ManualEnrollmentSingle({
  blueprints,
}: ManualEnrollmentSingleProps) {
  const displayToast = useDisplayToast();

  async function handleOnBlueprintClick(blueprintId: string) {
    await downloadBlueprint(blueprintId);
    displayToast('Profile successfully downloaded.', 'success', '');
  }

  return (
    <EnrollSingleContainer alignItems="center">
      <Flex flow="column" css={{ gap: 6 }}>
        <Heading size={'4'}>Download a one-time use enrollment profile</Heading>
      </Flex>
      <MEBlueprintDropdown
        blueprints={blueprints}
        onValueChange={handleOnBlueprintClick}
      />
    </EnrollSingleContainer>
  );
}
