import {
  Checkbox,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';
import { useFlags } from 'src/config/feature-flags';

import { AddableTextField } from '../common';

const KerberosAdvanced = (props) => {
  const { setting, update, validationDep, isDisabled } = props;

  const { 'DC-WWDC24-SSO-changes': LD_wwdc24_SSO_changes } = useFlags([
    'DC-WWDC24-SSO-changes',
  ]);

  const fieldsToValidate = [
    'cache_name',
    'bundle_id_access',
    'autofill_username',
    'site_code',
    'custom_username_label',
    'help_text',
    'replication_time',
  ];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  const requiredValidator = (v) => ({
    message: 'Required.',
    invalid: () => !v,
    trigger: ['onBlur', validationDep],
  });

  const minMaxValidator = (v, min, max) => ({
    message: `Must be between ${min} and ${max}.`,
    invalid: () => {
      const n = parseInt(v, 10);
      return n < min || n > max;
    },
    trigger: ['onBlur', validationDep],
  });

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Advanced</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Cache name"
              checked={setting.isCacheName}
              isDisabled={isDisabled}
              onChange={() => update('isCacheName', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.cache_name}>
              Specify the GSS name of the Kerberos cache to use. Rarely set by
              an administrator.
            </p>
          </Setting.Helpers>

          {setting.isCacheName && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.cache_name}
                    onChange={(e) => update('cache_name', e.target.value)}
                    disabled={isDisabled}
                    placeholder="https://gss.accuhive.io/"
                    onInvalidate={onInvalidate('cache_name')}
                    validator={(v) => [requiredValidator(v)]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Bundle ID access"
              checked={setting.isBundleIdAccess}
              isDisabled={isDisabled}
              onChange={() => update('isBundleIdAccess', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>A list of bundle IDs allowed to access the TGT.</p>
          </Setting.Helpers>

          {setting.isBundleIdAccess && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <AddableTextField
                    validationDep={validationDep}
                    isDisabled={isDisabled}
                    update={update}
                    items={setting.bundle_id_access}
                    settingKey="bundle_id_access"
                    placeholder="io.accuhive.it.erm"
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Autofill username"
              checked={setting.isAutoFillUsername}
              isDisabled={isDisabled}
              onChange={() => update('isAutoFillUsername', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.autofill_username}>
              Specify the principal name. You do not need to include the realm.
            </p>
          </Setting.Helpers>

          {setting.isAutoFillUsername && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.autofill_username}
                    onChange={(e) =>
                      update('autofill_username', e.target.value)
                    }
                    disabled={isDisabled}
                    placeholder="$EMAIL"
                    onInvalidate={onInvalidate('autofill_username')}
                    validator={(v) => [requiredValidator(v)]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Default realm"
              checked={setting.default_realm}
              isDisabled={isDisabled}
              onChange={() => update('default_realm', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              Specify that this is the default realm if there is more than one
              Kerberos extension configuration.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Require user presence"
              checked={setting.require_user_presence}
              isDisabled={isDisabled}
              onChange={() => update('require_user_presence', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              Require the user to provide Touch ID, Face ID, or their passcode
              to access the keychain entry.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Site Code"
              checked={setting.isSiteCode}
              isDisabled={isDisabled}
              onChange={() => update('isSiteCode', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.site_code}>
              The name of the Active Directory site the Kerberos extension
              should use. Most administrators will never need to modify this
              value, as the Kerberos extension can normally find the site
              automatically.
            </p>
          </Setting.Helpers>

          {setting.isSiteCode && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.site_code}
                    onChange={(e) => update('site_code', e.target.value)}
                    disabled={isDisabled}
                    placeholder="accuhive-hq1"
                    onInvalidate={onInvalidate('site_code')}
                    validator={(v) => [requiredValidator(v)]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Disable Autodiscover for site name"
              checked={setting.disable_site_auto_discovery}
              isDisabled={isDisabled}
              onChange={() => update('disable_site_auto_discovery', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              The Kerberos extension won&apos;t automatically use LDAP and DNS
              to determine its AD site name.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Custom Username Label"
              checked={setting.isCustomUsernameLabel}
              isDisabled={isDisabled}
              onChange={() => update('isCustomUsernameLabel', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.custom_username_label}>
              The custom user name label used in the Kerberos extension instead
              of “Username”. For example, “Company ID”. Available in macOS 11
              and later.
            </p>
          </Setting.Helpers>

          {setting.isCustomUsernameLabel && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.custom_username_label}
                    onChange={(e) =>
                      update('custom_username_label', e.target.value)
                    }
                    disabled={isDisabled}
                    onInvalidate={onInvalidate('custom_username_label')}
                    validator={(v) => [requiredValidator(v)]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Delay User Setup"
              checked={setting.delay_user_setup}
              isDisabled={isDisabled}
              onChange={() => update('delay_user_setup', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              Do not prompt the user to setup the Kerberos extension until
              either the administrator enables it with the app-sso tool or a
              Kerberos challenge is received. Available in macOS 11 and later.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Help Text"
              checked={setting.isHelpText}
              isDisabled={isDisabled}
              onChange={() => update('isHelpText', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.help_text}>
              The text to be displayed to the user at the bottom of the Kerberos
              login window. It can be used to display help information or
              disclaimer text. Available in iOS 14 and later and macOS 11 and
              later.
            </p>
          </Setting.Helpers>

          {setting.isHelpText && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    compact
                    removeValidationOnUnmount
                    value={setting.help_text}
                    onChange={(e) => update('help_text', e.target.value)}
                    disabled={isDisabled}
                    onInvalidate={onInvalidate('help_text')}
                    validator={(v) => [requiredValidator(v)]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Include Managed Apps in BundleId ACL"
              checked={setting.include_managed_apps_in_bundle_id_ACL}
              isDisabled={isDisabled}
              onChange={() =>
                update('include_managed_apps_in_bundle_id_ACL', (p) => !p)
              }
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              The Kerberos extension will allow only managed apps to access and
              use the credential. This is in addition to the
              “credentialBundleIDACL“, if it is specified. Available in iOS 14
              and later.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Disable Monitor Credentials Cache"
              checked={setting.monitor_credentials_cache}
              isDisabled={isDisabled}
              onChange={() => update('monitor_credentials_cache', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p>
              The credential is requested on the next matching Kerberos
              challenge or network state change. If the credential is expired or
              missing, a new one will be created. Available in macOS 11 and
              later.
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              label="Replication Time"
              checked={setting.isReplicationTime}
              isDisabled={isDisabled}
              onChange={() => update('isReplicationTime', (p) => !p)}
            />
          </Setting.Controls>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs.replication_time}>
              The time, in seconds, required to replicate changes in the Active
              Directory domain. The Kerberos extension will use this when
              checking password age after a change. Available in macOS 11 and
              later.
            </p>
          </Setting.Helpers>

          {setting.isReplicationTime && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Controls>
                  <TextInput
                    superCompact
                    value={setting.replication_time}
                    onChange={(e) => {
                      const v = e.target.value;
                      const n = parseInt(v, 10);
                      if (!v.length || !Number.isNaN(n)) {
                        update('replication_time', n);
                      }
                    }}
                    disabled={isDisabled}
                    placeholder="900"
                    onInvalidate={onInvalidate('replication_time')}
                    validator={(v) => [
                      requiredValidator(v),
                      minMaxValidator(v, 0, 999999),
                    ]}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
        {LD_wwdc24_SSO_changes && (
          <>
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  label="Disallow smart card"
                  checked={setting.allowSmartCard}
                  isDisabled={isDisabled}
                  onChange={() => update('allowSmartCard', (p) => !p)}
                />
              </Setting.Controls>
              <Setting.Helpers className="b-txt-light">
                <p>
                  Disallows the user from switching the user interface to smart
                  card mode. Available in macOS 15 and later.
                </p>
              </Setting.Helpers>
            </Setting.Row>
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  label="Disallow password"
                  checked={setting.allowPassword}
                  isDisabled={isDisabled}
                  onChange={() => update('allowPassword', (p) => !p)}
                />
              </Setting.Controls>
              <Setting.Helpers className="b-txt-light">
                <p>
                  Disallows the user from switching the user interface to
                  password mode. Available in macOS 15 and later.
                </p>
              </Setting.Helpers>
            </Setting.Row>
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  label="Start in smart card mode"
                  checked={setting.startInSmartCardMode}
                  isDisabled={isDisabled}
                  onChange={() => update('startInSmartCardMode', (p) => !p)}
                />
              </Setting.Controls>
              <Setting.Helpers className="b-txt-light">
                <p>
                  When checked, the user interface will start in smart card
                  mode. Available in macOS 15 and later.
                </p>
              </Setting.Helpers>
            </Setting.Row>
            <Setting.Row>
              <Setting.Title className="b-txt">
                Smart Card Identity Issuer Auto Select Filter
              </Setting.Title>
              <Setting.Helpers className="b-txt-light">
                <p>
                  A string with wildcards that can use used to filter the list
                  of available smart cards by issuer. If there is one remaining,
                  it will be auto-selected. If there more than one remaining,
                  then the list is shorter.
                </p>
              </Setting.Helpers>
              <Setting.Controls>
                <TextInput
                  compact
                  isOptional
                  value={setting.identityIssuerAutoSelectFilter}
                  onChange={(e) =>
                    update('identityIssuerAutoSelectFilter', e.target.value)
                  }
                  disabled={isDisabled}
                  placeholder="*Acchuive CA*"
                />
              </Setting.Controls>
            </Setting.Row>
          </>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default KerberosAdvanced;
