import {
  Banner,
  Checkbox,
  Select,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  authStrategyMap,
  getSsoConnections,
} from 'features/library-items/data-service/sso/use-sso-service';
import { getDepIntegrationInfo } from 'features/library-items/items/auto-enroll/service/use-dep-profile-service';
import { Setting } from 'features/library-items/template';

import azureIcon from '../../../../../components/common/svg-assets/azure-active-directory.svg';
import customSamlIcon from '../../../../../components/common/svg-assets/custom-saml.svg';
import googleIcon from '../../../../../components/common/svg-assets/google.svg';
import kandjiCircleIcon from '../../../../../components/common/svg-assets/kandji-circle.svg';

import { i18n } from 'i18n';
import OrganizationDetails from './organization-details';

const { EMAIL_PASSWORD, GOOGLE_APPS, AZURE_ACTIVE_DIRECTORY, CUSTOM_SAML } =
  authStrategyMap;

const ssoIconMap = {
  [GOOGLE_APPS]: googleIcon,
  [AZURE_ACTIVE_DIRECTORY]: azureIcon,
  [CUSTOM_SAML]: customSamlIcon,
  [EMAIL_PASSWORD]: kandjiCircleIcon,
};

const useNestedCtrlHelper = (nestedLevels, parentRef, levelLeftOffset = 36) => {
  const [parentWidth, setParentWidth] = useState();

  useEffect(() => {
    const handleResize = throttle(() => {
      if (parentRef.current) {
        setParentWidth(
          Math.floor(parentRef.current.getBoundingClientRect().width),
        );
      }
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nestedGridColStyles = useMemo(
    () =>
      Array.from({ length: nestedLevels }, (_, idx) => {
        if (parentRef.current) {
          const w =
            parentWidth ||
            Math.floor(parentRef.current.getBoundingClientRect().width);

          const level = idx + 1;
          const width = Math.abs(w - level * levelLeftOffset);

          return {
            gridTemplateColumns: `${width}px 1fr`,
          };
        }
        return {};
      }),
    [parentWidth, Boolean(parentRef.current)],
  );

  return nestedGridColStyles;
};

const General = (props) => {
  const [isFetchingSsoConn, setIsFetchingSsoConn] = useState(false);
  const nestedCtrlParentRef = useRef();

  const [prefillNestedStyles, lockPrefilledNestedStyles] = useNestedCtrlHelper(
    2,
    nestedCtrlParentRef,
  );

  const {
    isDisabled,
    isAdding,
    setting,
    update,
    beforeEditModel,
    isSubmitted,
  } = props;

  const isSsoConfigured =
    !!setting.ssoConnections.length &&
    !!setting.ssoConnections.filter(
      ({ strategy }) => strategy !== EMAIL_PASSWORD,
    ).length;

  useEffect(() => {
    const fetchSsoConn = async () => {
      try {
        setIsFetchingSsoConn(true);
        const ssoConnections = await getSsoConnections();
        update('ssoConnections', ssoConnections);
        setIsFetchingSsoConn(true);
      } catch (error) {
        toaster(i18n.t('There was an issue getting your SSO connections'));
      } finally {
        setIsFetchingSsoConn(false);
      }
    };

    if (isAdding) {
      fetchSsoConn();
    }
  }, []);

  useEffect(() => {
    const fetchOrgADEInfo = async () => {
      const {
        org_phone: phoneNumber = '',
        org_email: email = '',
        org_address: location = '',
      } = await getDepIntegrationInfo();

      update('defaultOrgDetails', {
        phoneNumber,
        email,
        location,
      });
    };

    fetchOrgADEInfo();
  }, []);

  // Get SSO connections that are not using email strategy
  const ssoConnOptions =
    setting.ssoConnections.length > 0
      ? setting.ssoConnections
          .filter(({ strategy }) => strategy !== EMAIL_PASSWORD)
          .map(({ strategy, id, display_name: displayName }) => ({
            label: displayName,
            value: id,
            icon: ssoIconMap[strategy],
          }))
      : [];

  // Default to first SSO connection, i.e. not standard email/password auth,
  // if available and required auth is checked without a saved
  // `ssoConnectionId`
  useEffect(() => {
    const { ssoEnabled, ssoConnectionId, ssoConnections } = setting;
    if (ssoEnabled && !ssoConnectionId && ssoConnections?.length > 0) {
      const firstSsoConn = ssoConnections.find(
        ({ strategy }) => strategy !== EMAIL_PASSWORD,
      );

      if (firstSsoConn) {
        update('ssoConnectionId', firstSsoConn.id);
      }
    }
  }, [setting]);

  const getSelectedSsoConn = (id, options) => {
    const hasSsoConnections = options?.length > 0;
    if (hasSsoConnections) {
      return options.find(({ value }) => id === value);
    }

    return null;
  };

  /* if no override contacts, set orgDetails as valid */
  useEffect(() => {
    if (!setting.isContactsOverridden) {
      // updateOrgDetails('isValid', true);
      update('invalidationsMap', {});
    } else {
      /* when toggle, reset to state before edit */

      /* Pre-fill default phone and email values from app's
       * /settings/my-company/integrations page if contacts empty  */
      const defaultOrgContacts = ['phoneNumber', 'email', 'location']
        .map(
          (field) =>
            !beforeEditModel.general.orgDetails[field] && [
              field,
              setting.defaultOrgDetails[field],
            ],
        )
        .filter(Boolean);

      update(
        'orgDetails',
        Object.assign(
          beforeEditModel.general.orgDetails,
          Object.fromEntries(defaultOrgContacts),
        ),
      );
    }
  }, [setting.isContactsOverridden]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Universal settings</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Configure universal settings for Automated Device Enrollment that will
          be applied to all enrolling devices.
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              disabled={!isSsoConfigured || isFetchingSsoConn || isDisabled}
              checked={setting.ssoEnabled}
              onChange={() => update('ssoEnabled', (p) => !p)}
              label="Require authentication"
            />
          </Setting.Controls>
          <Setting.Helpers className="k-ade-require-auth-helper">
            <p className="b-txt-light">
              Require a user to authenticate through single sign-on during
              device enrollment.{' '}
              <a
                href="https://support.kandji.io/support/solutions/articles/72000558683"
                rel="noopener noreferrer"
                target="_blank"
                className="b-alink"
              >
                Learn More...
              </a>
            </p>
            {!isSsoConfigured && !isFetchingSsoConn && (
              <Banner theme="info" kind="block">
                <p>
                  No single sign-on connections are configured to enable this
                  option.
                </p>
              </Banner>
            )}
          </Setting.Helpers>
          {setting.ssoEnabled && (
            <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
              <Setting.SecondaryControlsRow>
                <div>
                  <p className="b-txt">Connection</p>
                </div>
                <div>
                  <p className="b-txt-light">
                    The single sign-on connector to be used for authentication.
                  </p>
                </div>
                <div>
                  <Select
                    kind="icon"
                    disabled={isDisabled}
                    options={ssoConnOptions}
                    compact
                    onChange={(v) => update('ssoConnectionId', v.value)}
                    value={getSelectedSsoConn(
                      setting.ssoConnectionId,
                      ssoConnOptions,
                    )}
                  />
                </div>
              </Setting.SecondaryControlsRow>
              <Setting.SecondaryControlsRow>
                <div>
                  <Checkbox
                    disabled={isDisabled}
                    checked={setting.ssoSyncUserWithDevice}
                    onChange={() => update('ssoSyncUserWithDevice', (p) => !p)}
                    label="Assign user to device record"
                  />
                </div>
                <div ref={nestedCtrlParentRef}>
                  <p className="b-txt-light k-ade-nested-helper__txt">
                    Attempt to automatically assign the authenticated user to
                    the device.
                  </p>
                </div>
                {setting.ssoSyncUserWithDevice && (
                  <Setting.SecondaryControls className="k-ade-prefill-ctrl k-ade-prefill-nested-ctrl">
                    <Setting.SecondaryControlsRow
                      className="k-ade-nested-row-helper"
                      style={prefillNestedStyles}
                    >
                      <div className="k-ade-prefill">
                        <Checkbox
                          disabled={isDisabled}
                          checked={setting.prefill}
                          onChange={() => update('prefill', (p) => !p)}
                          label="Prefill initial account creation details"
                        />
                      </div>
                      <div className="k-ade-prefill-helper">
                        <p className="b-txt-light k-ade-nested-helper__txt k-ade-prefill-helper__txt">
                          Automatically prefill the account details for the
                          initial computer account created during Setup
                          Assistant using the details of the assigned user.
                        </p>
                      </div>
                      {setting.prefill && (
                        <Setting.SecondaryControls className="k-ade-lock-prefilled-ctrl k-ade-prefill-nested-ctrl">
                          <Setting.SecondaryControlsRow
                            className="k-ade-nested-row-helper"
                            style={lockPrefilledNestedStyles}
                          >
                            <div>
                              <Checkbox
                                disabled={isDisabled}
                                checked={setting.lockPrefilled}
                                onChange={() =>
                                  update('lockPrefilled', (p) => !p)
                                }
                                label="Lock pre-filled account creation details"
                              />
                            </div>
                            <div className="k-ade-prefill-nested-helper">
                              <p className="b-txt-light">
                                If enabled, the user cannot modify the account
                                creation details.
                              </p>
                            </div>
                          </Setting.SecondaryControlsRow>
                        </Setting.SecondaryControls>
                      )}
                    </Setting.SecondaryControlsRow>
                  </Setting.SecondaryControls>
                )}
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              disabled={isDisabled}
              checked={setting.isMdmRemovable}
              onChange={() => update('isMdmRemovable', (p) => !p)}
              label="Allow MDM profile removal"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Allow the user to unenroll the device from Kandji.
            </p>
            {setting.isMdmRemovable && (
              <Banner theme="info" kind="block">
                <p>
                  Any local administrator will be able to unenroll the device by
                  removing the MDM profile.
                </p>
              </Banner>
            )}
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              disabled={isDisabled}
              checked={setting.isContactsOverridden}
              onChange={() => update('isContactsOverridden', (p) => !p)}
              label="Override organization details"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              Displayed to the user on the Remote Management screen.
            </p>
          </Setting.Helpers>
          {setting.isContactsOverridden && (
            <OrganizationDetails
              defaultOrgDetails={setting.defaultOrgDetails}
              isDisabled={isDisabled}
              setting={setting.orgDetails}
              update={update}
              isSubmitted={isSubmitted}
            />
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default General;
