import type {
  AllowedSelectValue,
  SelectOptionMemberList,
} from '@kandji-inc/nectar-ui';
import { useEffect } from 'react';
import type { IntegrationGroupsResult } from '../types';
import useGetUserGroupList from './use-get-user-group-list';

type Props = {
  setGroupOptions: (
    arg: SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>,
  ) => void;
  setIsLoading?: (a: boolean) => void;
  groupIds?: string[];
  searchTerm?: string;
};

const useGetUserGroupListOptions = ({
  setGroupOptions,
  setIsLoading,
  groupIds,
  searchTerm,
}: Props) => {
  const { data: userGroupList, isLoading } = useGetUserGroupList(
    searchTerm,
    groupIds,
  );

  useEffect(() => {
    /* istanbul ignore next */
    setIsLoading ? setIsLoading(isLoading) : null;

    if (userGroupList) {
      /* istanbul ignore next */
      const results = userGroupList?.data?.results || [];
      const newList = results.map((r: IntegrationGroupsResult) => ({
        label: `${r.name} (${r.integration_name})`,
        value: `${r.id}|${r.external_id}`, // format: id|external_id
      }));

      setGroupOptions(newList);
    }
  }, [userGroupList, isLoading]);

  return { data: userGroupList?.data?.results };
};

export default useGetUserGroupListOptions;
