import React, { useMemo, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { useShallow } from 'zustand/react/shallow';

import type { DraggableAttributes } from '@dnd-kit/core';
import { useDraggable } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
  Badge,
  Box,
  Flex,
  Heading,
  Icon,
  type IconNames,
  Text,
  styled,
  theme,
} from '@kandji-inc/nectar-ui';

import {
  deviceTypes,
  installTypeIcons,
  installTypes,
  singularizeName,
  updateOnlyIconName,
} from 'src/features/library-items/library/common';

import type { LibraryItem, MapSearchResults } from '../blueprint-flow.types';

import { ORIGIN_TYPES, deviceKinds, deviceToIcon } from '../constants';
import {
  HOTFIX__hasConflictingItemInMap,
  getDeviceRunsOnCamelCase,
  getInstallIconsConfig,
  getItemConfig,
  highlightedText,
  pluralizeString,
  pluralizeWord,
} from '../helpers';
import { useItem } from '../hooks';
import DeleteButton from '../nodes/parts/actions/DeleteButton';
import { MATCH_LIBRARY_ITEM_ID } from '../pages/assignments/map-search';
import useBlueprintFlow from '../store';
import { FlowTippy, TippyContainer } from '../theme';

const ItemCard = styled(Flex, {
  width: '352px',
  padding: '10px',
  paddingRight: '$2',
  borderRadius: '$1',
  border: '1px solid #ECECEC',
  background: '$neutral0',
  userSelect: 'none',
  position: 'relative',
  cursor: 'pointer',
  gap: '2px',

  '&:focus-visible': {
    outline: 'none',
  },

  variants: {
    origin: {
      graph: {
        width: '398px',
      },
      bank: {},
    },
    isDisabled: {
      true: {
        opacity: 0.4,
      },
    },
    isSelected: {
      true: {
        borderRadius: '$1',
        border: '1px solid rgba(53, 65, 78, 0.25)',
        background: '#ECF3FE',
        boxShadow: '0px 0px 0px 2px rgba(16, 106, 242, 0.72)',
      },
    },
    isDragging: {
      true: {
        transform: 'rotate(-1deg)',
        border: '1px solid $blue50',
        borderRadius: '$1',
        background: 'var(--neutral-neutral-00, #FFF)',
        boxShadow: '0px 2px 8px 0px rgba(15, 19, 23, 0.20)',
        cursor: 'grabbing',

        '&:hover': {
          background: '$neutral0',
        },
      },
    },
    isEditing: {
      true: {
        cursor: 'grab',

        '&:hover': {
          backgroundColor: '$neutral5',
          boxShadow: '$1',
        },
      },
    },
    isOnDevicePath: {
      false: {
        transition: 'opacity 0.15s ease-in-out 0s',
        opacity: 0.2,
        '&:hover': {
          opacity: 1,
        },
      },
    },
  },

  compoundVariants: [
    {
      origin: 'graph',
      isSelected: false,
      css: {
        borderRadius: '0',
        border: '1px solid #FFFFFF',
        '&:not(:last-child)': {
          borderBottom: '1px solid #ECECEC',
        },
      },
    },
    {
      origin: 'graph',
      isSelected: false,
      isEditing: true,
      css: {
        '&:hover': {
          borderColor: '$neutral5',
          borderBottom: '1px solid #ECECEC',
        },
      },
    },
    {
      isEditing: true,
      isSelected: true,
      css: {
        '&:hover': {
          border: '1px solid rgba(53, 65, 78, 0.25)',
          background: '$neutral20',
          boxShadow: '0px 0px 0px 2px rgba(16, 106, 242, 0.72)',
        },
      },
    },
  ],
});

type LibraryListItemProps = {
  item: LibraryItem;
  isDragging?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>, item: LibraryItem) => void;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  isSelected?: boolean;
  isDisabled?: boolean;
  isInSidebar?: boolean;
  searchText?: string;
  searchTextDebounce?: any;
  isOnDevicePath?: boolean;
  itemMatched?: MapSearchResults['matches'][number];
};

const LibraryListItem = React.forwardRef(
  (props: LibraryListItemProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      item,
      isDragging,
      isSelected,
      isDisabled,
      searchText,
      isOnDevicePath = true,
      itemMatched,
      onClick,
      listeners,
      attributes,
      isInSidebar = false,
    } = props;
    const [
      isEditingAssignments,
      clearSelectedAssignmentLibraryItems,
      mapSearchTerm,
      isDeletingNode,
      libraryItems,
      isLibraryItemExcludedFromSelectedDevice,
      libraryItemExclusionsCount,
    ] = useBlueprintFlow(
      useShallow((state) => [
        state.isEditingAssignments,
        state.clearSelectedAssignmentLibraryItems,
        state.mapSearchTerm,
        state.isDeletingNode,
        state.libraryItems,
        state.isLibraryItemExcludedFromSelectedDevice,
        state.libraryItemExclusionsCount,
      ]),
    );
    const { getZoom, getNodes } = useReactFlow();
    const { deleteItem } = useItem(item.flowId);
    const [iconImg, setIconImg] = useState(item.icon);
    const [isHovering, setIsHovering] = useState(false);
    const itemConfig = getItemConfig(item);
    const zoom = getZoom();
    const isShowingDelete =
      item.origin === ORIGIN_TYPES.graph &&
      isHovering &&
      !isDragging &&
      !isDisabled &&
      isEditingAssignments &&
      !isDeletingNode;
    const searchTerm =
      item.origin === ORIGIN_TYPES.bank ? searchText : mapSearchTerm;
    const inactiveMatchedColor =
      item.origin === 'graph'
        ? theme.colors.yellow20.value
        : theme.colors.yellow30.value;
    const matchedBoldOptions = item.origin === 'graph' && {
      color: theme.colors.yellow40.value,
      matchIndex: itemMatched?.item?.itemMatchIdx,
    };

    const TEMP__hasConflictingItemInMap = HOTFIX__hasConflictingItemInMap(
      item,
      getNodes(),
      libraryItems,
    );

    const isManuallyExcluded = isLibraryItemExcludedFromSelectedDevice(item.id);
    const manualExclusionsCount = libraryItemExclusionsCount(item.id);
    const installIcons = getInstallIconsConfig(item);

    return (
      <FlowTippy
        content={
          <TippyContainer gap="xs">
            <Text>
              {
                /* istanbul ignore next */ isManuallyExcluded
                  ? 'The selected device is manually excluded from this Library Item.'
                  : 'The selected device did not qualify for this Library Item.'
              }
            </Text>
          </TippyContainer>
        }
        popperOptions={{ strategy: 'fixed' }}
        disabled={isOnDevicePath && !isManuallyExcluded}
      >
        <ItemCard
          id={MATCH_LIBRARY_ITEM_ID(item.origin, item.flowId)}
          tabIndex={0}
          ref={ref}
          wFull={item.origin === ORIGIN_TYPES.bank}
          justifyContent="space-between"
          alignItems="center"
          gap="sm"
          origin={item.origin}
          isDragging={isDragging}
          isSelected={isSelected}
          isDisabled={isDisabled}
          isEditing={isEditingAssignments && !isDeletingNode}
          isOnDevicePath={isOnDevicePath}
          onClick={(e) => onClick?.(e, item)}
          onMouseOver={() => setIsHovering(true)}
          onMouseOut={() => setIsHovering(false)}
          {...attributes}
          {...listeners}
          css={{
            transform:
              isDragging && item.origin === ORIGIN_TYPES.graph
                ? `scale(${zoom}) rotate(-1deg)`
                : '',
          }}
          data-testid="library-item-card"
        >
          <Flex flex={1} alignItems="center" css={{ gap: '9px' }}>
            <img
              style={{ width: '20px', height: '20px' }}
              src={iconImg || itemConfig.icon}
              onError={() => setIconImg(itemConfig.icon)}
              alt="Icon"
              data-testid="library-item-icon"
            />
            <Box css={{ flex: 1, width: 0 }}>
              <Flex alignItems="center">
                <Heading
                  size="5"
                  css={{
                    lineHeight: '$2',
                    wordWrap: 'break-word',
                    fontWeight: 500,
                  }}
                >
                  {highlightedText(
                    item.name,
                    searchTerm,
                    inactiveMatchedColor,
                    itemMatched?.field === 'name' && matchedBoldOptions,
                  )}
                </Heading>

                {item.origin === ORIGIN_TYPES.graph &&
                  TEMP__hasConflictingItemInMap && (
                    // conflictingLibraryItems.includes(item.flowId) &&
                    <FlowTippy
                      disabled={isDragging}
                      popperOptions={{ strategy: 'fixed' }}
                      content={
                        <TippyContainer gap="xs">{`Only one ${singularizeName(
                          itemConfig.name,
                        )} Library Item may be assigned per device. Devices which qualify for more than one will receive whichever is scoped to them last (farthest right) on the map.`}</TippyContainer>
                      }
                    >
                      <Flex data-testid="scli-icon" css={{ marginLeft: '$1' }}>
                        <Icon name="square-stack-slash-fill" size="sm" />
                      </Flex>
                    </FlowTippy>
                  )}
                {Boolean(!isInSidebar && manualExclusionsCount) && (
                  <FlowTippy
                    disabled={isDragging}
                    popperOptions={{ strategy: 'fixed' }}
                    content={
                      <TippyContainer gap="xs">
                        {`${manualExclusionsCount} manual device ${pluralizeString(manualExclusionsCount, 'exclusion', 'exclusions')} on this Library Item in this map.${/* istanbul ignore next */ isEditingAssignments ? ' Click to edit.' : ''}`}
                      </TippyContainer>
                    }
                  >
                    <Badge
                      css={{ padding: '1px 5px 1px 4px', marginLeft: '$1' }}
                      compact
                      icon="hexagon-xmark"
                      color="yellow"
                      data-testid="manual-exclusions-badge"
                    >
                      {manualExclusionsCount}
                    </Badge>
                  </FlowTippy>
                )}
              </Flex>
              <Text
                variant="description"
                size="1"
                css={{ fontWeight: '$medium', fontSize: '11px' }}
              >
                {highlightedText(
                  itemConfig.name,
                  searchTerm,
                  inactiveMatchedColor,
                  itemMatched?.field === 'defaultConfiguration.name' &&
                    matchedBoldOptions,
                )}
                {item.instanceName && ' - '}
                {item.instanceName &&
                  highlightedText(
                    item.instanceName,
                    searchTerm,
                    inactiveMatchedColor,
                    itemMatched?.field === 'instanceName' && matchedBoldOptions,
                  )}
              </Text>
            </Box>
          </Flex>
          <Flex css={{ gap: '2px' }} alignItems="center">
            <Flex flow="column">
              <Flex
                justifyContent="end"
                alignItems="center"
                css={{ padding: '4px 0' }}
              >
                {deviceKinds.map((device) => {
                  const runsOn = item[getDeviceRunsOnCamelCase(device)];
                  if (!runsOn) {
                    return null;
                  }

                  return (
                    <FlowTippy
                      key={device}
                      disabled={isDragging}
                      content={
                        <TippyContainer gap="xs">
                          {deviceTypes[device.toUpperCase()]}
                        </TippyContainer>
                      }
                      popperOptions={{ strategy: 'fixed' }}
                    >
                      <Flex alignItems="center">
                        <Icon size="xs" name={deviceToIcon[device]} />
                      </Flex>
                    </FlowTippy>
                  );
                })}
              </Flex>
              <Flex
                justifyContent="end"
                alignItems="center"
                css={{ gap: '2px', padding: '2px 0' }}
              >
                {installIcons
                  .filter(({ isVisible }) => isVisible)
                  .map(({ label, icon: modifierIcon }) => (
                    <FlowTippy
                      key={label}
                      disabled={isDragging}
                      content={
                        <TippyContainer gap="xs">{label}</TippyContainer>
                      }
                      popperOptions={{ strategy: 'fixed' }}
                    >
                      <Flex alignItems="center">
                        <Icon size="xs" name={modifierIcon as IconNames} />
                      </Flex>
                    </FlowTippy>
                  ))}
              </Flex>
            </Flex>
            {isEditingAssignments && (
              <Icon
                name="grip-dots-vertical"
                style={{
                  height: '14px',
                  width: '14px',
                  visibility: isHovering ? 'unset' : 'hidden',
                }}
              />
            )}
          </Flex>

          {isShowingDelete && (
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                clearSelectedAssignmentLibraryItems();
                deleteItem();
              }}
              css={{
                position: 'absolute',
                top: '-8px',
                right: '-4px',
                border: '1px solid $neutral20',
              }}
              color="red"
              name="delete-item"
              tooltip="Delete Library Item"
            />
          )}
        </ItemCard>
      </FlowTippy>
    );
  },
);

const DraggableLibraryItem = (props: LibraryListItemProps) => {
  const {
    item,
    isDragging,
    isSelected,
    isDisabled,
    searchTextDebounce,
    isOnDevicePath,
  } = props;
  const [
    isEditingAssignments,
    isDeletingNode,
    activeMatches,
    model,
    selectedDevice,
  ] = useBlueprintFlow((state) => [
    state.isEditingAssignments,
    state.isDeletingNode,
    state.activeMatches,
    state.model,
    state.selectedDevice,
  ]);
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: item?.flowId,
    data: item,
    disabled: !isEditingAssignments || isDisabled || isDeletingNode,
  });

  if (!item) {
    return null;
  }

  const activeMatch = activeMatches.matches[activeMatches.currentMatchIndex];
  const isItemMatched = useMemo(
    () => activeMatch?.item?.flowId === item.flowId && activeMatch,
    [activeMatch],
  );

  return useMemo(
    () => (
      <LibraryListItem
        {...props}
        itemMatched={isItemMatched}
        ref={setNodeRef}
        attributes={attributes}
        listeners={listeners}
      />
    ),
    [
      item,
      isDragging,
      isSelected,
      isDisabled,
      isEditingAssignments,
      searchTextDebounce,
      isOnDevicePath,
      isItemMatched,
      isDeletingNode,
      model?.library_item_exclusions,
      selectedDevice,
    ],
  );
};

export { DraggableLibraryItem };
export default LibraryListItem;
