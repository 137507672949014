import { Button, Chip } from '@kandji-inc/bumblebee';
import LibraryItemConfig from 'features/library-items/library/library-item-configurations/items/item-config.class';
import { i18n } from 'i18n';
/* istanbul ignore file */
import React from 'react';
import PreviewHoverTippy from 'src/features/util/components/preview-hover-tippy';
import { displayDeviceType } from '../../common';

const DetailView = (props) => {
  const { row, data, onClose, onConfigure } = props;
  const isPreviewChip = data?.data?.is_preview_chip;
  const requiresSupervision =
    data.data?.requires_supervision ||
    data.type === 'wallpaper' ||
    data.name === 'Home Screen Layout';

  let displayName = data.name;
  let displayDescription = data.description;
  if (data instanceof LibraryItemConfig) {
    displayName = data.getName();
    displayDescription = data.getDescription();
  }

  return (
    <div
      className="li-add-library-item__detail"
      style={{ gridRowStart: row + 2 }}
    >
      <div className="li-add-library-item__detail-header">
        <div className="b-flex">
          <img
            src={data.icon}
            className="li-add-library-item__detail-icon b-mr"
          />
          <h3 className="b-h3 b-flex-vc li-add-library-item__detail-name">
            {displayName}
          </h3>
        </div>

        {isPreviewChip && <PreviewHoverTippy />}
      </div>
      <div className="li-add-library-item__detail-content">
        <div className="li-add-library-item__detail-left-column-content">
          <div className="li-add-library-item__detail-description b-txt-light">
            {displayDescription}
          </div>
          <div className="b-flex-vc b-flex-g li-add-library-item__detail-button">
            <Button onClick={onConfigure} className="--first">
              {i18n.t('Add & Configure')}
            </Button>
          </div>
        </div>
        <div className="li-add-library-item__detail-info">
          <span className="b-txt-light">{i18n.t('Publisher:')}</span>
          <span className="b-txt">{data.publisher}</span>
          <span className="b-txt-light">{i18n.t('Device families:')}</span>
          <span className="b-flex-g">
            {data.devices.map((device) => (
              <Chip
                key={device}
                text={displayDeviceType(device)}
                kind="secondary"
              />
            ))}
          </span>
          {data.osRequirements && data.osRequirements.length ? (
            <>
              <span className="b-txt-light">{i18n.t('OS requirement:')}</span>
              <span className="b-flex-vgtiny">
                {data.osRequirements.map((os) => (
                  <span key={os} className="b-txt">
                    {os}
                    {requiresSupervision && i18n.t(' (Requires Supervision)')}
                  </span>
                ))}
              </span>
            </>
          ) : null}
        </div>
      </div>
      <Button
        className="li-add-library-item__detail-close"
        kind="link"
        icon="xmark"
        onClick={onClose}
      />
    </div>
  );
};

export default DetailView;
