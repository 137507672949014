import {
  Banner,
  Button,
  TextInput,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { bool, func, number, object, string } from 'prop-types';
import React, { useEffect } from 'react';

import { i18n } from 'i18n';
import { TYPE_PROP_TYPE } from '../../../../constants';
import { newIntegrationsService } from '../../../../data-service/new-integrations-service';
import assignUsers from '../assets/assign-users.svg';
import userDirectory from '../assets/user-directory.svg';

function GenericNameSlide({
  icon,
  newIntegrationData,
  typeName,
  updateField,
  isActive,
}) {
  const nameIsEmpty = !newIntegrationData.name;
  useEffect(() => {
    updateField('nextButtonDisabled', isActive && nameIsEmpty);
  }, [nameIsEmpty, isActive]);

  return (
    <div className="cmn-modal_info-section">
      <img src={icon} className="b-mb2" alt="type-icon" />
      <h1 className="b-h1 b-mb1">Configure {typeName}</h1>
      <p className="b-txt b-mb1">
        Provide a unique name for the user integration and sign in to your{' '}
        {typeName} account. You will need to authenticate as an administrator.
      </p>
      <Banner theme="info" icon="circle-info" className="b-mb3">
        <p>
          Note: Please make sure that your admin account has API access enabled
          and permission to read users.
        </p>
      </Banner>
      <TextInput
        value={newIntegrationData.name}
        maxLength={32}
        placeholder="Enter a unique name"
        onChange={(e) => {
          e.persist();
          const {
            target: { value },
          } = e;
          updateField('newIntegrationData', (curr) => ({
            ...curr,
            name: value,
          }));
        }}
      />
    </div>
  );
}

GenericNameSlide.propTypes = {
  icon: string.isRequired,
  typeName: string.isRequired,
  newIntegrationData: object.isRequired,
  updateField: func.isRequired,
  isActive: bool.isRequired,
};

function GenericFooterButtons({
  onClose,
  currentStepIdx,
  setCurrentStepIdx,
  icon,
  nextButtonDisabled,
  typeName,
  type,
  newIntegrationData,
  isProcessing,
  updateField,
}) {
  return (
    <>
      <Button kind="outline" onClick={onClose} className="b-mr">
        Cancel
      </Button>
      <Button
        onClick={() => {
          if (currentStepIdx) {
            updateField('isProcessing', true);
            newIntegrationsService
              .createIntegration({ type, name: newIntegrationData.name })
              .then(({ data }) => {
                window.location = data.redirect_url;
              })
              .catch((e) => {
                console.log(e);
                toaster(i18n.t('Encountered an error while logging in'));
              })
              .finally(() => updateField('isProcessing', false));
          } else {
            setCurrentStepIdx((curr) => curr + 1);
          }
        }}
        disabled={nextButtonDisabled || isProcessing}
        isProgress={isProcessing}
        icon={isProcessing ? 'arrows-rotate' : null}
      >
        {currentStepIdx ? (
          <>
            {!isProcessing && (
              <img src={icon} alt="small-icon" className="b-mr-tiny" />
            )}
            Sign in with {typeName}
          </>
        ) : (
          'Get started'
        )}
      </Button>
    </>
  );
}

GenericFooterButtons.propTypes = {
  onClose: func.isRequired,
  currentStepIdx: number.isRequired,
  setCurrentStepIdx: func.isRequired,
  icon: string.isRequired,
  nextButtonDisabled: bool.isRequired,
  typeName: string.isRequired,
  type: TYPE_PROP_TYPE.isRequired,
  newIntegrationData: object.isRequired,
  updateField: func.isRequired,
  isProcessing: bool.isRequired,
};

const INITIAL_STATE = {
  isProcessing: false,
  nextButtonDisabled: false,
  newIntegrationData: { name: '' },
};

function getDescription(title) {
  return (
    `Establishing a user integration with ${title}` +
    ' allows you to import your user directory and assign devices.'
  );
}

function getTickets(title) {
  return [
    {
      text: `Automatically imports your user directory from ${title} and allows for real-time syncing.`,
      title: 'Import and sync your user directory',
      link: 'https://support.kandji.io/support/solutions/articles/72000560544',
      icon: userDirectory,
    },
    {
      text:
        'This integration will allow you to assign devices directly ' +
        'to your end users through your identity provider.',
      title: 'Assign devices to your users',
      link: 'https://support.kandji.io/support/solutions/articles/72000560504',
      icon: assignUsers,
    },
  ];
}

export {
  GenericNameSlide,
  GenericFooterButtons,
  INITIAL_STATE,
  getDescription,
  getTickets,
};
