import {
  ChipSelect,
  DropdownIndicator,
  Select,
  TextInput,
  useChipSelect,
} from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import './library-filter.css';

import { i18n } from 'i18n';
import { components } from 'react-select';
import {
  deviceTypes,
  displayDeviceType,
  displayLibraryCategory,
} from '../common';

const Option = (props) => {
  const { data, isSelected } = props;
  const { counts, showCounts } = props.selectProps;
  const category = counts.find((c) => c.category === data.label);
  const total = counts.reduce((a, c) => a + (c.count || 0), 0);

  return (
    <components.Option {...props}>
      <div
        className={`b-flex-btw b-txt b-flex-vc li-library-filter__option ${
          isSelected ? '--selected' : ''
        }`}
      >
        <div className="li-library-filter__label">{data.label}</div>
        {showCounts && (
          <div className="li-library-filter__option-count b-txt">
            {category?.count ?? total}
          </div>
        )}
      </div>
    </components.Option>
  );
};

const Control = ({ children, ...props }) => {
  const { counts, value, showCounts } = props.selectProps;
  const category = counts.find((c) => c.category === value.label);
  const total = counts.reduce((a, c) => a + (c.count || 0), 0);
  return (
    <components.Control {...props}>
      {children}
      {showCounts && (
        <div className="li-library-filter__control">
          {category ? category.count : total}
        </div>
      )}
    </components.Control>
  );
};

const LibraryFilter = (props) => {
  const {
    className,
    style,
    categoryOrder,
    onFilter,
    counts = [],
    showCounts,
    sticky,
  } = props;
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const deviceOptions = [
    { value: 'All', label: i18n.t('All'), all: true },
    { value: deviceTypes.MAC, label: displayDeviceType(deviceTypes.MAC) },
    {
      value: deviceTypes.IPHONE,
      label: displayDeviceType(deviceTypes.IPHONE),
    },
    { value: deviceTypes.IPAD, label: displayDeviceType(deviceTypes.IPAD) },
    { value: deviceTypes.TV, label: displayDeviceType(deviceTypes.TV) },
  ];

  const { selectOneOrAll, selected: selectedDevices } = useChipSelect({
    options: deviceOptions,
    defaultSelected: [deviceOptions[0].value],
  });

  useEffect(() => {
    if (categoryOrder) {
      const options = [
        { value: '', label: i18n.t('All Library Items') },
        ...categoryOrder
          .filter(
            (category) =>
              !showCounts ||
              counts.find((d) => d.category === category && d.count),
          )
          .map((c) => ({ value: c, label: displayLibraryCategory(c) })),
      ];
      setCategories(options);
      setCategory(options[0]);
    }
  }, [categoryOrder]);

  useEffect(() => {
    onFilter(
      category?.value,
      // Inconsistencies with Chip select.
      selectedDevices[0] === 'All'
        ? deviceOptions.slice(1).map((d) => d.value)
        : selectedDevices,
      searchTerm,
    );
  }, [category, selectedDevices, searchTerm]);

  const translatedCounts = counts.map((c) => ({
    category: displayLibraryCategory(c.category),
    count: c.count,
  }));

  return (
    <div
      className={`li-library-filter ${sticky ? '--sticky' : ''} ${
        className || ''
      }`}
      style={style}
    >
      <Select
        options={categories}
        value={category}
        onChange={(category) => setCategory(category)}
        counts={translatedCounts}
        showCounts={showCounts}
        components={{ DropdownIndicator, Option, Control }}
        styles={{
          menuList: (styles) => ({
            ...styles,
            minHeight: '240px',
            height: '240px',
          }),
        }}
      />
      <ChipSelect
        selected={selectedDevices}
        setSelected={selectOneOrAll}
        options={deviceOptions}
        selectionType="selectOneOrAll"
      />
      <TextInput
        placeholder={i18n.t('Search')}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        icon={searchTerm ? 'circle-xmark' : 'magnifying-glass'}
        onIconClick={() => setSearchTerm((prev) => (prev ? '' : prev))}
      />
    </div>
  );
};

export default LibraryFilter;
