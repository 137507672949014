import type { z } from 'zod';

import type {
  AllowedSelectValue,
  SelectOptionMember,
  SelectOptionMemberList,
} from '@kandji-inc/nectar-ui';

import type {
  compliancePoliciesSchema,
  integrationDetailsSchema,
  integrationGroupsResultSchema,
} from './schema';

export enum DEVICE_PLATFORMS {
  macos = 'macos',
  ios = 'ios',
}
export type PlatformStatus =
  | DEVICE_PLATFORMS.macos
  | DEVICE_PLATFORMS.ios
  | null;

export type GroupOption = SelectOptionMember<
  AllowedSelectValue,
  'value',
  'label'
>;
export type GroupOptionList = SelectOptionMemberList<
  AllowedSelectValue,
  'value',
  'label'
>;

export type IntegrationGroupsResult = z.infer<
  typeof integrationGroupsResultSchema
>;
export type IntegrationDetails = z.infer<typeof integrationDetailsSchema>;
export type CompliancePolicies = z.infer<typeof compliancePoliciesSchema>;
