import { Flex, styled } from '@kandji-inc/nectar-ui';
import useAccount from 'src/contexts/account';
import { usePrismAIContext } from '../../contexts/PrismAIContext';
import { usePrismUrlContext } from '../../contexts/PrismUrlContext';
import type { ChatMessage } from '../ai.types';
import { Message } from './message';

export const Messages = ({ messages }: { messages: ChatMessage[] }) => {
  const { setCategoryAndFilters } = usePrismUrlContext();
  const { isFullWidth, toggleWidth } = usePrismAIContext();
  const userData = useAccount();
  const firstname = userData?.userFirstName;
  const lastname = userData?.userLastName;

  const initials =
    firstname && lastname ? `${firstname[0]}${lastname[0]}` : 'ME';

  const handleOnClickUrl = (url: string) => {
    setCategoryAndFilters(url);
    if (isFullWidth) {
      toggleWidth();
    }
  };

  return (
    <Container>
      {messages
        .slice()
        .reverse()
        .map((message) => (
          <Message
            key={message.id}
            sender={message.sender}
            initials={initials}
            components={message.message_components}
            onClickUrl={handleOnClickUrl}
          />
        ))}
    </Container>
  );
};

const Container = styled(Flex, {
  display: 'flex',
  padding: '$6 0px 16px 0px',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  gap: '$6', // 32px
  alignSelf: 'stretch',
  flex: '1 0 0',
});
