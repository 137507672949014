import {
  Flex,
  Tabs,
  Text,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';

import { useEffect, useRef, useState } from 'react';
import { SeverityProgressChips } from '../../components/severity-progress-chips';
import { SeverityTooltip } from '../../components/severity-tooltip';
import { Timeline } from '../../components/timeline';
import type { TimelineItem } from '../../components/timeline/timeline';
import { displayAndTruncateList } from '../../helpers';
import useVulnerability from '../../store';
import { Severity } from '../../vulnerability.types';
import { DeviceList } from './tabs/device-list';

const DetailRow = styled(Flex, {
  flexDirection: 'row',
});

const DetailKey = styled(Text, {
  color: '$neutral70',
  fontSize: '$1',
  width: '150px',
});

const DetailValue = styled(Text, {
  fontSize: '$1',
  fontWeight: '$medium',
});

const tabsContainerCss = {
  '& > div[data-nectar-styled-name="group"]': {
    position: 'sticky',
    top: 0,
    bg: '$neutral0',
    zIndex: 2,
    marginInline: -20,
    paddingInline: 20,
    width: 'calc(100% + 40px)',
    '& div[data-nectar-styled-name="list"]': {
      position: 'relative',
    },
    '& > div[data-nectar-styled-name="separator"]': {
      width: 'calc(100% - 40px)',
    },
  },
  flex: 1,
};

const Body = () => {
  const selectedVulnerability = useVulnerability(
    (state) => state.selectedVulnerability,
  );
  const {
    cve_modified_at,
    cve_published_at,
    description,
    device_count,
    cvss_score,
    latest_detection_date,
    first_detection_date,
    severity,
    versions,
  } = selectedVulnerability;

  const [isDescriptionOverflowing, setIsDescriptionOverflowing] =
    useState(false);
  const descriptionTextRef = useRef<HTMLDivElement>(null);

  const { displayed: displayedVersions, hidden: hiddenVersions } =
    displayAndTruncateList(versions);

  const recommendations = [
    'Install new application update when available from the developer',
    'Block the application using the Application Blocking Parameter',
    'Uninstall the application using a custom script',
  ];

  const timelineItems = [
    {
      label: 'Published on',
      date: cve_published_at,
      theme: 'default',
      icon: 'file-plus',
    },
    {
      label: 'First detected',
      date: first_detection_date,
      theme: 'danger',
      icon: 'bell-exclamation',
    },
    ...(cve_modified_at
      ? [
          {
            label: 'Modified on',
            date: cve_modified_at,
            theme: 'default',
            icon: 'pen-field',
          },
        ]
      : []),
    {
      label: 'Last detected',
      date: latest_detection_date,
      theme: 'danger',
      icon: 'bell-exclamation',
    },
  ].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  useEffect(
    /* istanbul ignore next */ () => {
      if (descriptionTextRef.current) {
        setIsDescriptionOverflowing(
          descriptionTextRef.current.scrollWidth >
            descriptionTextRef.current.clientWidth,
        );
      }
    },
    [],
  );

  return (
    <Flex flow="column" gap="sm" pt2 flex={1} css={{ marginBottom: '80px' }}>
      {description && (
        <DetailRow>
          <DetailKey>Description</DetailKey>
          <Flex flow="row">
            <DetailValue
              ref={descriptionTextRef}
              css={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '490px',
              }}
            >
              {description}
            </DetailValue>

            {/* istanbul ignore next */}
            {isDescriptionOverflowing && (
              <TooltipProvider>
                <Tooltip
                  side="bottom"
                  theme="light"
                  align="end"
                  maxWidth="316px"
                  content={
                    <Flex flow="column" gap="xs">
                      <DetailValue>Description</DetailValue>
                      <Text>{description}</Text>
                    </Flex>
                  }
                  css={{ zIndex: 100, padding: '$3 $4' }}
                >
                  <DetailValue variant="primary">See more</DetailValue>
                </Tooltip>
              </TooltipProvider>
            )}
          </Flex>
        </DetailRow>
      )}

      {versions && (
        <DetailRow>
          <DetailKey>Versions</DetailKey>
          <DetailValue>{displayedVersions.join(', ')}</DetailValue>
          {hiddenVersions.length > 0 && (
            <Flex gap="xs">
              <DetailValue>,</DetailValue>
              <Tooltip
                side="bottom"
                theme="dark"
                content={<DetailValue>{hiddenVersions.join(', ')}</DetailValue>}
                css={{ zIndex: 2 }}
              >
                <DetailValue>{`+${hiddenVersions.length}`}</DetailValue>
              </Tooltip>
            </Flex>
          )}
        </DetailRow>
      )}

      <DetailRow>
        <DetailKey>Severity | CVSS score</DetailKey>
        <SeverityTooltip severity={severity}>
          <Flex flow="row">
            <SeverityProgressChips severity={severity} />
            <DetailValue>
              {severity !== 'Undefined' ? severity : ''}
              {severity !== 'Undefined' && cvss_score ? ' | ' : '-'}
              {cvss_score ?? ''}
            </DetailValue>
          </Flex>
        </SeverityTooltip>
      </DetailRow>

      <DetailRow>
        <DetailKey>Recommendations</DetailKey>
        <Flex gap="xs">
          <DetailValue>{`${recommendations[0]}, `}</DetailValue>
          <Tooltip
            side="bottom"
            theme="light"
            align="end"
            maxWidth="316px"
            content={
              <ul style={{ paddingInlineStart: '14px', marginBlockEnd: 0 }}>
                {recommendations.slice(1).map((recommendation, index) => (
                  <li key={index}>{recommendation}</li>
                ))}
              </ul>
            }
            css={{ zIndex: 2, padding: '$3 $4' }}
          >
            <DetailValue>+{recommendations.length - 1}</DetailValue>
          </Tooltip>
        </Flex>
      </DetailRow>

      <DetailRow>
        <Timeline items={timelineItems as TimelineItem[]} />
      </DetailRow>

      <Flex flex={1}>
        {/* @ts-ignore - we only have one tab at the moment */}
        <Tabs.Container
          compact
          tabs={[
            {
              label: (
                <Text css={{ fontWeight: '$medium' }}>
                  Devices{' '}
                  <span
                    style={{ fontWeight: '400' }}
                  >{`(${device_count})`}</span>
                </Text>
              ),
              tabId: 'devices',
            },
          ]}
          defaultTabId="devices"
          css={tabsContainerCss}
        >
          <Tabs.Content tabId="devices">
            <DeviceList />
          </Tabs.Content>
        </Tabs.Container>
      </Flex>
    </Flex>
  );
};

export { Body };
