import { Select } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
import featureFlags from 'src/config/feature-flags';
import type { BehavioralDetectionsProps } from '../../avert.types';
import AvertService from '../../service/avert.service';

const BehavioralDetections = (props: BehavioralDetectionsProps) => {
  const {
    isDisabled,
    settings,
    update,
    processMonitoringEnabledSetting,
    updateProcessMonitoringEnabled,
  } = props;

  if (!featureFlags.getFlag('edr_083024_behavioral-detections-avert-li')) {
    return null;
  }

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Behavioral detections')}</h3>
        <Setting.HeaderToggle
          isEnabled={processMonitoringEnabledSetting}
          isDisabled={isDisabled}
          onChange={updateProcessMonitoringEnabled}
          chipText={{
            enabled: i18n.t('On'),
            disabled: i18n.t('Off'),
          }}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Configure the security posture for malicious behavioral event detections.',
          )}{' '}
          <a
            className="b-alink"
            href="https://support.kandji.io/support/solutions/articles/72000600372"
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.t('Learn more')}
            ...
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Helpers className="b-txt-light">
            {i18n.t(
              'Specify the desired security posture for malicious behavioral detections. Detect mode will identify and report malicious behavioral detections. Protect mode will identify, report, and block malicious behavioral detections. Suspicious behavioral detections can only be set to Detect mode, as these events are not confirmed as malicious but warrant attention due to their unusual activity.',
            )}
          </Setting.Helpers>
          <Setting.Controls>
            <div className="b-mb3">
              <Select
                label={i18n.t('Malicious behavior posture')}
                options={AvertService.postureOptions}
                value={settings.process_mal_posture}
                onChange={(v) => update('process_mal_posture', v)}
                disabled={isDisabled || !processMonitoringEnabledSetting}
                testId="process_mal_posture"
              />
            </div>
            <Select
              label={i18n.t('Suspicious behavior posture')}
              options={[AvertService.postureOptions[1]]}
              value={settings.process_pup_posture}
              disabled
              testId="process_pup_posture"
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default BehavioralDetections;
