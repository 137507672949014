import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  TextArea,
  TextField,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

const MAX_DESC = 256;

type FromScratchProps = {};

const FromScratch = (props: FromScratchProps) => {
  const [isShowingDescription, setIsShowingDescription] = useState(false);
  const { register, watch, formState } = useFormContext();

  const blueprintDescription = watch('blueprintDescription');

  return (
    <div>
      <Flex gap="md" alignItems="center" css={{ marginBottom: '$5' }}>
        <Box
          css={{
            padding: '$2',
            borderRadius: '50%',
            backgroundColor: 'var(--colors-blue10)',
          }}
        >
          <Icon name="kandji-blueprint" color="var(--colors-blue60)" />
        </Box>
        <Heading size="4" css={{ fontWeight: '$medium' }}>
          {i18n.t('New Blueprint from scratch')}
        </Heading>
      </Flex>

      <Flex flow="column" gap="lg" css={{ width: '320px' }}>
        <div>
          <TextField
            id="blueprint-name"
            label={i18n.t('Blueprint name')}
            placeholder={i18n.t('Enter a unique name')}
            {...register('blueprintName', {
              shouldUnregister: true,
              required: {
                value: true,
                message: i18n.t('Enter a unique name'),
              },
              maxLength: {
                value: 100,
                message: i18n.t('Must be no more than {max} characters', {
                  max: 100,
                }),
              },
            })}
            required
            state={
              /* istanbul ignore next */ formState?.errors?.blueprintName
                ?.message
                ? 'error'
                : 'default'
            }
            // @ts-expect-error
            hint={
              /* istanbul ignore next */ formState?.errors?.blueprintName
                ?.message
                ? {
                    label: formState?.errors?.blueprintName?.message,
                  }
                : { label: '' }
            }
            data-testid="blueprint-name"
          />
          {!isShowingDescription && (
            <Button
              variant="subtle"
              compact
              icon={{
                name: 'plus',
                position: 'left',
              }}
              onClick={() => setIsShowingDescription(!isShowingDescription)}
              css={{
                marginTop: '$2',
              }}
              data-testid="add-description-button"
            >
              {i18n.t('Add description (optional)')}
            </Button>
          )}
        </div>
        {isShowingDescription && (
          <TextArea
            isOptional
            label={i18n.t('Description')}
            placeholder={i18n.t('Enter a description (optional)')}
            {...register('blueprintDescription', {
              shouldUnregister: true,
              maxLength: {
                value: MAX_DESC,
                message: i18n.t('Must be no more than {max} characters', {
                  max: MAX_DESC,
                }),
              },
            })}
            state={
              /* istanbul ignore next */ formState?.errors?.blueprintDescription
                ?.message
                ? 'error'
                : 'default'
            }
            hint={{
              label: formState?.errors?.blueprintDescription?.message,
              charCounter: {
                count: blueprintDescription?.length,
                max: MAX_DESC,
              },
            }}
          />
        )}
      </Flex>
    </div>
  );
};

export default FromScratch;
