import { Button } from '@kandji-inc/bumblebee';
import { api } from 'app/api/base';
import Menu from 'features/self-service-new/menu';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ReactSVG } from 'react-svg';
import ResizeObserver from 'resize-observer-polyfill';
import { categories } from '../common';
import EmptyBeehive from './assets/empty-beehive.svg';
import LibraryItemCard from './library-item-card/library-item-card';
import './library-list.css';
import { i18n } from 'i18n';

const LibraryCategory = React.forwardRef(
  ({ category, items, canSync, onSync, isSyncing, cardDimensions }, ref) => (
    <div>
      <div className="b-flex-vc b-mb">
        <h3 className="b-h3 li-library-list_category-header">{category}</h3>
        {canSync && (
          <Menu
            options={[
              {
                name: i18n.t('Sync Now'),
                icon: 'arrows-rotate',
                disabled: isSyncing,
                loading: isSyncing,
                onClick: onSync,
              },
            ]}
            placement="bottom-start"
          >
            <div className="b-ml-tiny li-library-list__category-button">
              <Button kind="link" icon="ellipsis" />
            </div>
          </Menu>
        )}
      </div>
      <div className="li-library-list__category-items-grid">
        {items.map((item) => (
          <LibraryItemCard
            key={item.id}
            ref={ref}
            dimensions={cardDimensions}
            item={item}
          />
        ))}
      </div>
    </div>
  ),
);

const LibraryList = (props) => {
  const { items, reloadItems } = props;
  const [singleCardBodyRef, setSingleCardBodyRef] = useState();
  const [cardDimensions, setCardDimensions] = useState();
  const [isSyncing, setIsSyncing] = useState(false);
  const [loadSlice, setLoadSlice] = useState(1);

  useEffect(() => {
    let resizeObserver;
    if (singleCardBodyRef) {
      const onResize = debounce((entries) => {
        const { width, height } = entries[0].contentRect;
        if (resizeObserver) {
          setCardDimensions({
            width,
            height,
          });
        }
      }, 400);
      resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(singleCardBodyRef);
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver = null;
      }
    };
  }, [singleCardBodyRef]);

  const filteredItems = useMemo(() => {
    if (!items.length) {
      return (
        <div className="b-flex-vc b-flex-hc b-flex-col">
          <ReactSVG className="b-mb" src={EmptyBeehive} />
          <h3 className="b-h3">{i18n.t('No results found')}</h3>
          <p className="b-txt">
            {i18n.t(
              'Try changing the filter or search with different keywords.',
            )}
          </p>
        </div>
      );
    }

    return (
      <InfiniteScroll
        className="li-library-list__category-grid"
        pageStart={loadSlice}
        loadMore={() => setLoadSlice((prev) => ++prev)}
        hasMore={loadSlice < items.length}
        loader={<div key={0}></div>}
      >
        {items
          .filter((item) => item.data.length)
          .slice(0, loadSlice)
          .map((item) => (
            <LibraryCategory
              key={item.category}
              category={item.category}
              items={item.data}
              canSync={item.category === categories.APP_STORE}
              onSync={() => {
                setIsSyncing(true);
                return api('/vpp/sync-now/')
                  .post()
                  .then(reloadItems)
                  .then(() => setIsSyncing(false))
                  .catch(() => setIsSyncing(false));
              }}
              isSyncing={isSyncing}
              ref={(r) => setSingleCardBodyRef(r)}
              cardDimensions={cardDimensions}
            />
          ))}
      </InfiniteScroll>
    );
  }, [items, cardDimensions, isSyncing, loadSlice]);

  return <div className="li-library-list">{filteredItems}</div>;
};

export default LibraryList;
