import { Flex, Separator, Text, styled } from '@kandji-inc/nectar-ui';
import EDRDropdownButton from '../../../../common/components/EDRDropdownButton';
import { constants } from '../../../common';
import type { PageSize, PaginationState } from '../../../threat.types';
import ThreatListTablePagination from './ThreatListTablePagination';

const PaginationSeparator = styled(Separator, {
  margin: '0 10px',
  background: '$neutral20',
});

const PerPageText = styled(Text, {
  paddingLeft: 3,
  alignSelf: 'center',
});

type ThreatListTableProps = Readonly<{
  dataCount: number | undefined;
  pagination: PaginationState;
  onPaginationChange: (page: number, pageSize: PageSize) => void;
  bulkActions?: React.ReactNode;
  isPageSizeShown: boolean;
}>;

const ThreatListTablePaginationBar = (props: ThreatListTableProps) => {
  const {
    dataCount = 0,
    pagination: { page = 1, pageSize = 10 },
    onPaginationChange,
    bulkActions,
    isPageSizeShown,
  } = props;
  const pageCount: number = dataCount ? Math.ceil(dataCount / pageSize) : 1;

  return (
    <Flex flex={1}>
      {isPageSizeShown && (
        <Flex>
          <EDRDropdownButton
            triggerLabel={pageSize.toString()}
            value={pageSize.toString()}
            options={constants.THREAT_TABLE_ROWS_PER_PAGE_OPTIONS.map(
              (page) => ({
                label: page.toString(),
                value: page.toString(),
              }),
            )}
            onChange={(e) => onPaginationChange(1, parseInt(e, 10) as PageSize)}
            disabled={pageCount === 1 && pageSize <= 10}
            testId="threats-table-rows-per-page-select"
          />
          <PerPageText variant="description">results per page</PerPageText>
          <PaginationSeparator orientation="vertical" />
        </Flex>
      )}
      <Flex flex={1}>
        <ThreatListTablePagination
          currentPage={page}
          totalPages={pageCount}
          totalItems={dataCount}
          itemsPerPage={pageSize}
          onPageChange={(newPage) => onPaginationChange(newPage, pageSize)}
          bulkActions={!isPageSizeShown && bulkActions}
        />
      </Flex>
    </Flex>
  );
};

export default ThreatListTablePaginationBar;
