import type {
  BaseLibraryItemModel,
  BlueprintConflicts,
  LibraryItemPageProps,
  UpdateFunction,
} from '../../library-items.types';

export const PostureValue = {
  PROTECT: 'protect',
  DETECT: 'detect',
} as const;

export type PostureValue = (typeof PostureValue)[keyof typeof PostureValue];

export const PostureLabel = {
  PROTECT: 'Protect',
  DETECT: 'Detect',
} as const;

export type PostureLabel = (typeof PostureLabel)[keyof typeof PostureLabel];

export const ItemTypeValue = {
  HASH: 'hash',
  PATH: 'path',
} as const;

export type ItemTypeValue = (typeof ItemTypeValue)[keyof typeof ItemTypeValue];

export const ItemTypeLabel = {
  HASH: 'Hash',
  PATH: 'Path',
} as const;

export type ItemTypeLabel = (typeof ItemTypeLabel)[keyof typeof ItemTypeLabel];

export const EventTypeValue = {
  ALLOW: 'allow',
  BLOCK: 'block',
} as const;

export type EventTypeValue =
  (typeof EventTypeValue)[keyof typeof EventTypeValue];

export const EventTypeLabel = {
  ALLOW: 'Allowed',
  BLOCK: 'Blocked',
} as const;

export type EventTypeLabel =
  (typeof EventTypeLabel)[keyof typeof EventTypeLabel];

export type SortColumnName =
  | 'name'
  | 'details'
  | 'item_type'
  | 'event_type'
  | 'none';

export type SortColumnDirection = 'desc' | 'asc' | 'none';

export type AllowBlockEvent = {
  id: string;
  name: string;
  item_type: ItemTypeValue;
  details: string;
  event_type: EventTypeValue;
};

export type AllowBlockFilters = {
  search: string;
  eventType: EventTypeValue | 'all';
  itemType: ItemTypeValue | 'all';
  sortByColumn: SortColumnName;
  sortDirection: SortColumnDirection;
};

export type EventTypeFilters = ReadonlyArray<{
  value: EventTypeValue | 'all';
  label: EventTypeLabel | 'All';
}>;

export type ItemTypeFilters = ReadonlyArray<{
  value: ItemTypeValue | 'all';
  label: ItemTypeLabel | 'All types';
}>;

export type PostureOptions = ReadonlyArray<{
  label: PostureLabel;
  value: PostureValue;
}>;

export type ItemTypeOptions = Array<{
  label: ItemTypeLabel;
  value: ItemTypeValue;
}>;

export type EventTypeOptions = ReadonlyArray<{
  label: EventTypeLabel;
  value: EventTypeValue;
}>;

export type AllowBlockListSetting = {
  events: AllowBlockEvent[];
};

export type EndUserNotificationsSetting = {
  end_user_notifications_enabled: boolean;
};

export type UnknownFileUploadSetting = {
  file_upload_enabled: boolean;
};

export type GeneralSetting = {
  pup_posture: PostureValue;
  malware_posture: PostureValue;
  process_pup_posture: PostureValue;
  process_mal_posture: PostureValue;
};

export interface AvertModelSettings {
  allowBlock: AllowBlockListSetting;
  endUserNotifications: EndUserNotificationsSetting;
  unknownFileUpload: UnknownFileUploadSetting;
  general: GeneralSetting;
  processMonitoringEnabled: boolean;
}

export interface AvertModel extends BaseLibraryItemModel, AvertModelSettings {
  skip_blueprint_conflict?: boolean;
  excludedBlueprints: string[];
  isAllBlueprints: boolean;
}

type AvertSectionProps<T extends Record<string, unknown>> = Readonly<{
  settings: T;
  isDisabled: boolean;
  update: UpdateFunction<T>;
  generalSettings?: GeneralSetting;
  validationDep?: number;
}>;

export type AvertPageProps = LibraryItemPageProps<Readonly<AvertModel>> & {
  blueprintConflicts: BlueprintConflicts;
};

export type AllowBlockListProps = AvertSectionProps<AllowBlockListSetting> & {
  pageSize?: number;
};

export type EndUserNotificationsProps =
  AvertSectionProps<EndUserNotificationsSetting>;

export type UnknownFileUploadProps =
  AvertSectionProps<UnknownFileUploadSetting>;

export type GeneralProps = AvertSectionProps<GeneralSetting>;

export type BehavioralDetectionsProps = AvertSectionProps<GeneralSetting> & {
  processMonitoringEnabledSetting: boolean;
  updateProcessMonitoringEnabled: () => void;
};
