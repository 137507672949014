import { Row } from '@kandji-inc/bumblebee';
import { Button } from '@kandji-inc/nectar-ui';
import { PropTypes } from 'prop-types';
import React, { useContext, useState, useEffect, useMemo } from 'react';

import { getComputerCount } from 'src/app/_actions/computer';
import { billingTypes, planTypes } from 'src/app/common/constants';
import { AccountContext } from 'src/contexts/account';
import {
  Card,
  Header,
  Rows,
  Settings,
  SubHeader,
  Title,
} from 'src/features/library-items/template';

const INTERNAL = {
  title: 'Internal Account 🐝',
  desc: 'This tenant is issued for sales, support, and demo purposes to a Kandji employee. Please contact it@kandji.io for additional information.',
};

const FREE = {
  desc: 'This tenant is issued for sales, support, and demo purposes to a Kandji Partner. Please contact partnerships@kandji.io for additional information.',
};

const APPLE = {
  desc: 'This tenant is issued for sales, support, and demo purposes to an Apple employee. Please contact apple@kandji.io for additional information.',
};

const TRIAL = {
  desc: 'Your organization is currently enrolled in a trial. Please contact your account executive for purchasing information.',
};

const CUSTOMER = {
  desc: 'Contact your Customer Success Manager for additional billing information.',
};

const PARTNER = {
  desc: 'Your organization is billed through a partner agreement. Please contact your partner for billing information.',
};

const AUTOMATED_TRIAL = {
  desc: 'Your organization is currently enrolled in a trial. Please contact your account executive for purchasing information.',
};

const AUTOMATED_BILLING = {
  desc: 'Your organization is currently enrolled in a trial. Please contact your account executive for purchasing information..',
};

export const CONTENT = {
  [billingTypes.CUSTOMER]: CUSTOMER,
  [billingTypes.INTERNAL]: INTERNAL,
  [billingTypes.FREE]: FREE,
  [billingTypes.APPLE]: APPLE,
  [billingTypes.TRIAL]: TRIAL,
  [billingTypes.PARTNER]: PARTNER,
  [billingTypes.AUTOMATED_TRIAL]: AUTOMATED_TRIAL,
  [billingTypes.AUTOMATED_BILLING]: AUTOMATED_BILLING,
};

export const billingTypeDisplays = {
  [billingTypes.FREE]: 'NFR',
  [billingTypes.APPLE]: 'Apple',
  [billingTypes.TRIAL]: 'Trial',
  [billingTypes.CUSTOMER]: 'Customer',
  [billingTypes.INTERNAL]: 'Internal',
  [billingTypes.AUTOMATED_TRIAL]: 'Automated Trial',
  [billingTypes.AUTOMATED_BILLING]: 'Automated Billing',
};

const displayBillingType = (billingType) => billingTypeDisplays[billingType];

const formatTrialEndDate = (dateString) => {
  if (!dateString) {
    return '';
  }
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
};

const enrollmentUtilization = (max, current) => {
  const percentage =
    max === 0 ? (current > 0 ? 100 : 0) : (current / max) * 100;
  return {
    display: `${Math.round(percentage)}%`,
    warning: percentage >= 95,
  };
};

const UtilizationDisplay = ({ display, warning, label }) => (
  <p className="b-txt">
    <span style={{ color: warning ? 'var(--color-red-50)' : 'inherit' }}>
      {display}
    </span>{' '}
    {label}
  </p>
);

UtilizationDisplay.propTypes = {
  display: PropTypes.string.isRequired,
  warning: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

const PlanData = ({
  maxComputerCount,
  planType,
  maxDevicesPerPlatform,
  computerCount,
  billingType,
  trialBannerData,
}) => {
  const singleLimitUtilization = useMemo(() => {
    return planType === planTypes.SINGLE_LIMIT
      ? enrollmentUtilization(
          maxComputerCount,
          computerCount.macos + computerCount.other,
        )
      : { display: '', warning: false };
  }, [planType, maxComputerCount, computerCount]);

  const perPlatformUtilization = useMemo(() => {
    return planType === planTypes.PER_PLATFORM_LIMIT
      ? {
          macos: enrollmentUtilization(
            maxDevicesPerPlatform.macos,
            computerCount.macos,
          ),
          ios_ipados: enrollmentUtilization(
            maxDevicesPerPlatform.ios_ipados,
            computerCount.other,
          ),
        }
      : {
          macos: { display: '', warning: false },
          ios_ipados: { display: '', warning: false },
        };
  }, [planType, maxDevicesPerPlatform, computerCount]);

  return (
    <div className="b-flex b-mb3" style={{ boxShadow: 'none' }}>
      <div style={{ marginRight: 64 }}>
        <p className="b-h4 b-mb1">Enrollment Limits</p>
        {planType === planTypes.SINGLE_LIMIT && (
          <p className="b-txt">{maxComputerCount} Devices</p>
        )}
        {planType === planTypes.PER_PLATFORM_LIMIT && (
          <>
            <p className="b-txt">{maxDevicesPerPlatform.macos} macOS</p>
            <p className="b-txt">
              {maxDevicesPerPlatform.ios_ipados} iOS, iPadOS, & tvOS
            </p>
          </>
        )}
      </div>
      <div style={{ marginRight: 64 }}>
        <p className="b-h4 b-mb1">Enrolled Devices</p>
        <>
          <p className="b-txt">{computerCount.macos} macOS</p>
          <p className="b-txt">{computerCount.other} iOS, iPadOS, & tvOS</p>
        </>
      </div>
      <div style={{ marginRight: 64 }}>
        <p className="b-h4 b-mb1">Enrollment Utilization</p>
        {planType === planTypes.SINGLE_LIMIT && (
          <UtilizationDisplay
            display={singleLimitUtilization.display}
            warning={singleLimitUtilization.warning}
            label="device limit"
          />
        )}
        {planType === planTypes.PER_PLATFORM_LIMIT && (
          <>
            <UtilizationDisplay
              display={perPlatformUtilization.macos.display}
              warning={perPlatformUtilization.macos.warning}
              label="macOS limit"
            />
            <UtilizationDisplay
              display={perPlatformUtilization.ios_ipados.display}
              warning={perPlatformUtilization.ios_ipados.warning}
              label="iOS, iPadOS, & tvOS limit"
            />
          </>
        )}
      </div>
      <div style={{ marginRight: 64 }}>
        <p className="b-h4 b-mb1">Billing Type</p>
        <p className="b-txt">{displayBillingType(billingType)}</p>
      </div>
      {(billingType === billingTypes.TRIAL ||
        billingType === billingTypes.AUTOMATED_TRIAL) &&
        trialBannerData?.is_enabled && (
          <>
            <div style={{ marginRight: 64 }}>
              <p className="b-h4 b-mb1">Trial End Date</p>
              <p className="b-txt">
                {formatTrialEndDate(trialBannerData?.trial_end_date)}
              </p>
            </div>
            {trialBannerData?.hubspot_quote_link && (
              <div>
                <p style={{ marginBottom: 8 }}>&nbsp;</p>
                <Button
                  compact
                  variant="primary"
                  onClick={() =>
                    window.open(
                      trialBannerData.hubspot_quote_link,
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }
                >
                  View Quote
                </Button>
              </div>
            )}
          </>
        )}
    </div>
  );
};

PlanData.propTypes = {
  maxComputerCount: PropTypes.number.isRequired,
  planType: PropTypes.string.isRequired,
  maxDevicesPerPlatform: PropTypes.shape({
    macos: PropTypes.number,
    ios_ipados: PropTypes.number,
  }).isRequired,
  computerCount: PropTypes.shape({
    macos: PropTypes.number,
    other: PropTypes.number,
  }).isRequired,
  billingType: PropTypes.string.isRequired,
  trialBannerData: PropTypes.shape({
    is_enabled: PropTypes.bool,
    trial_end_date: PropTypes.string,
    hubspot_quote_link: PropTypes.string,
  }).isRequired,
};

export default () => {
  const { currentCompany = {} } = useContext(AccountContext);
  const { title = '', desc = '' } = CONTENT[currentCompany.billing_type] || {};
  const [computerCount, setComputerCount] = useState({
    macos: 0,
    other: 0,
  });

  const getAndsetComputerCount = async () => {
    const count = await getComputerCount();
    setComputerCount(count);
  };

  useEffect(() => {
    getAndsetComputerCount();
  }, []);

  return (
    <>
      <Settings className="k-company-settings">
        <Card>
          <Header>
            <h3 className="b-h3">License management</h3>
          </Header>
          <SubHeader>
            {title && (
              <Title>
                <p className="b-h4 b-mb1">{title}</p>
              </Title>
            )}
            <Row>
              <p className="b-txt">{desc}</p>
            </Row>
          </SubHeader>
          <Rows>
            <PlanData
              maxComputerCount={currentCompany.max_devices}
              planType={currentCompany.plan_type}
              maxDevicesPerPlatform={currentCompany.max_devices_per_platform}
              computerCount={computerCount}
              billingType={currentCompany.billing_type}
              trialBannerData={currentCompany.trial_banner_data}
            />
          </Rows>
        </Card>
      </Settings>
    </>
  );
};
