import { useQuery } from '@tanstack/react-query';
import { msDeviceComplianceService } from '../services';
import { msDeviceComplianceKeys } from './query-key-factory';

const useGetUserGroupList = (searchTerm?: string, groupIds?: string[]) => {
  return useQuery({
    queryFn: () =>
      msDeviceComplianceService.getIntegrationUserGroupList(
        searchTerm,
        groupIds,
      ),
    queryKey: msDeviceComplianceKeys.userGroupList(
      searchTerm,
      groupIds?.join(','),
    ),
  });
};

export default useGetUserGroupList;
