import {
  type CSS,
  type IconNames,
  ScrollContainer,
  Table,
  Tbody,
  Thead,
  Tr,
} from '@kandji-inc/nectar-ui';
import {
  type ColumnDef,
  type Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react';
import {
  TextCell,
  getHeaderDisplayNameFromMeta,
} from 'src/features/visibility/prism/utils/column-helpers/column-utils';
import { HeaderCell, RowCell } from './components/cells';
import { SelectionColumn } from './components/selection-column';
import { useToggleResizingClass } from './hooks';
import { getTableCss } from './utils';

export interface SortState {
  col?: string;
  direction?: 'asc' | 'desc' | 'none';
}

interface MenuOption {
  label: string;
  icon?: IconNames;
  onClick?: () => void;
  disabled?: boolean;
}

export const DataTable = ({
  columns,
  pinnedColumns,
  data,
  rowId = 'id',
  onRowClick,
  getColumnMenu,
  columnSizing = { sizes: {} },
  searchTerm = '',
  selectionModel = {},
  sort,
  offsets,
  css: externalCss,
}: {
  columns: ColumnDef<unknown, any>[];
  pinnedColumns: string[];
  data: any[];
  enableColumnResizing?: boolean;
  rowId?: string;
  onRowClick?: (
    row: any,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
  columnSizing?: {
    sizes: Record<string, number>;
    setSizes?: (sizes: Record<string, number>) => void;
  };
  getColumnMenu?: (column: ColumnDef<any, unknown>) => MenuOption[];
  searchTerm?: string;
  selectionModel?: {
    selection?: string[];
    setSelection?: (selection: string[]) => void;
    maxCount?: number;
  };
  sort?: { sortState: SortState; setSortState?: (state: SortState) => void };
  offsets: { content?: number; table?: number; container?: number };
  css?: CSS;
}) => {
  // istanbul ignore next
  const defaultColumn = {
    header: getHeaderDisplayNameFromMeta,
    cell: TextCell,
    footer: (info) => info.column.id,
    size: 150,
    minSize: 100,
    maxSize: 750,
  };

  const selectedCss = {
    backgroundColor: '$blue05',
    borderTop: '1px solid $blue30',
    borderBottom: '1px solid $blue30',
  };

  const { maxCount, selection, setSelection } = selectionModel;

  const selectionColumn = useMemo(() => {
    if (selection) {
      return SelectionColumn({
        rowId,
        selection,
        onCheckedChange: !setSelection
          ? undefined
          : ({
              checked,
              row,
              rows,
              isHeader,
            }: {
              checked: boolean | 'indeterminate';
              row: Row<any>;
              rows: Row<any>[];
              isHeader: boolean;
            }) => {
              if (isHeader) {
                if (checked === true || checked === 'indeterminate') {
                  setSelection([]);
                } else {
                  const selected = new Set(selection);
                  rows.forEach((r) => {
                    if (maxCount == null || selected.size < maxCount) {
                      selected.add(r.original[rowId]);
                    }
                  });
                  setSelection(Array.from(selected));
                }
              } else if (!checked) {
                if (maxCount == null || selection.length < maxCount) {
                  setSelection([...selection, row.original[rowId]]);
                }
              } else {
                setSelection(
                  selection.filter((id) => id !== row.original[rowId]),
                );
              }
            },
      });
    }
  }, [maxCount, rowId, selection, setSelection]);

  const handleSort = useCallback(
    (columnId) => {
      if (!sort?.sortState || !sort?.setSortState) return;
      if (sort.sortState.col === columnId) {
        const { direction } = sort.sortState;
        if (direction === 'asc') {
          sort.setSortState({ col: columnId, direction: 'desc' });
        } else if (direction === 'desc') {
          sort.setSortState({ col: columnId, direction: 'none' });
        } else {
          sort.setSortState({ col: columnId, direction: 'asc' });
        }
      } else {
        sort.setSortState({ col: columnId, direction: 'asc' });
      }
    },
    [sort?.setSortState, sort?.sortState],
  );

  const table = useReactTable({
    columns: selectionColumn ? [selectionColumn, ...columns] : columns,
    data,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnSizing: columnSizing.sizes,
      globalFilter: searchTerm,
    },
  });

  const isEmpty =
    columns.length === 0 || (table.getRowModel()?.rows?.length || 0) === 0;
  const {
    content: contentOffset,
    table: tableOffset,
    container: containerOffset,
  } = offsets;

  const css = useMemo(
    () => getTableCss({ contentOffset, tableOffset }),
    [contentOffset, tableOffset],
  );

  const tableCss = {
    ...css.tableContainer,
    ...(isEmpty ? { borderBottom: 'none' } : {}),
    maxHeight: `calc(100% - ${containerOffset || 0}px)`,
  };

  const { isResizingColumn, startSize, deltaOffset } =
    table.getState().columnSizingInfo;

  React.useEffect(() => {
    if (
      typeof columnSizing.setSizes === 'function' &&
      isResizingColumn &&
      startSize != null &&
      deltaOffset != null
    ) {
      const size = Math.min(
        Math.max(startSize + deltaOffset, defaultColumn.minSize),
        defaultColumn.maxSize,
      );
      columnSizing.setSizes({
        ...columnSizing.sizes,
        [isResizingColumn]: size,
      });
    }
  }, [
    isResizingColumn,
    startSize,
    deltaOffset,
    columnSizing.setSizes,
    columnSizing.sizes,
    defaultColumn.maxSize,
    defaultColumn.minSize,
  ]);

  useToggleResizingClass(isResizingColumn);

  return (
    <ScrollContainer
      css={{ ...tableCss, ...externalCss }}
      showScrollShadowRight
    >
      <Table aria-label="data" css={css.table}>
        <Thead data-pinned>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const minSize =
                  header.column.columnDef.minSize || defaultColumn.minSize;
                const maxSize =
                  header.column.columnDef.maxSize || defaultColumn.maxSize;
                const size =
                  isResizingColumn === header.id
                    ? Math.min(
                        Math.max(startSize + deltaOffset, minSize),
                        maxSize,
                      )
                    : header.getSize();
                const menuOptions =
                  getColumnMenu && getColumnMenu(header.column.columnDef);
                const showMenu = menuOptions && menuOptions.length > 0;
                const headerPinned = pinnedColumns.includes(header.column.id);
                const columnDefCSS = header.column.columnDef.meta?.css || {};
                return (
                  <HeaderCell
                    title={(header.column.columnDef.header as string) || ''}
                    columnId={header.id}
                    key={header.id}
                    colSpan={header.colSpan}
                    size={size}
                    showMenu={showMenu}
                    showMenuOnHover={showMenu}
                    menuOptions={menuOptions}
                    resizable={
                      typeof columnSizing.setSizes === 'function' &&
                      header.column.getCanResize()
                    }
                    isResizingColumn={isResizingColumn}
                    handleResize={header.getResizeHandler()}
                    css={{
                      ...columnDefCSS,
                      pointerEvents:
                        isResizingColumn === header.id ? 'none' : 'auto',
                      left:
                        headerPinned && selectionColumn
                          ? `${selectionColumn.size}px!important`
                          : columnDefCSS.left,
                    }}
                    data-pinned={headerPinned}
                    sort={
                      sort && header.column.columnDef.enableSorting !== false
                        ? {
                            state:
                              sort.sortState.col === header.id
                                ? sort.sortState.direction
                                : 'none',
                            onSort: () => handleSort(header.id),
                          }
                        : undefined
                    }
                    showSortOnHover={sort != null}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </HeaderCell>
                );
              })}
            </Tr>
          ))}
        </Thead>
        {!isEmpty && (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                css={onRowClick ? { cursor: 'pointer' } : {}}
                key={row.id}
                onClick={(e) => {
                  e.stopPropagation();
                  return onRowClick && onRowClick(row.original, e);
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const cellPinned = pinnedColumns.includes(cell.column.id);
                  const cellScope = cellPinned ? 'row' : undefined;
                  const title =
                    'title' in cell.column.columnDef
                      ? (cell.column.columnDef.title as string)
                      : ((typeof cell.getValue() === 'string'
                          ? cell.getValue()
                          : '') as string);
                  const columnDefCSS = cell.column.columnDef.meta?.css || {};
                  const isSelected = selection?.includes(row.original[rowId]);
                  return (
                    <RowCell
                      columnId={cell.column.id}
                      key={cell.id}
                      data-pinned={cellPinned}
                      scope={cellScope}
                      size={cell.column.getSize()}
                      isResizingColumn={isResizingColumn}
                      title={title}
                      css={{
                        ...(isSelected ? selectedCss : {}),
                        ...columnDefCSS,
                        left:
                          cellPinned && selectionColumn
                            ? `${selectionColumn.size}px!important`
                            : columnDefCSS.left,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </RowCell>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
    </ScrollContainer>
  );
};
