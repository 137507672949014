import {
  Banner,
  Button,
  Checkbox,
  Chip,
  Flex,
  Select,
  TextInput,
  Toggle,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import { Flex as NectarFlex, Text, styled } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';
import { MultiText } from 'features/util/components/multi-text';
import { useFlags } from 'src/config/feature-flags';
import SingleSignOnExtensionService from '../service/single-sign-on-extension.service';
import { MultipleTextField } from './common';
import UserGroup from './user-group';

const UserGroupsContainer = styled(NectarFlex, {
  gap: '24px', // @todo: use stitches variable for $2 space
});

const authModes = [
  {
    label: 'Standard',
    value: SingleSignOnExtensionService.AuthorizationMode.Standard,
  },
  {
    label: 'Admin',
    value: SingleSignOnExtensionService.AuthorizationMode.Admin,
  },
  {
    label: 'Groups',
    value: SingleSignOnExtensionService.AuthorizationMode.Groups,
  },
];

const authMethods = [
  {
    label: 'Password',
    value: SingleSignOnExtensionService.AuthorizationMethod.Password,
  },
  {
    label: 'User Secure Enclave Key',
    value:
      SingleSignOnExtensionService.AuthorizationMethod.UserSecureEnclaveKey,
  },
  {
    label: 'Smart Card',
    value: SingleSignOnExtensionService.AuthorizationMethod.SmartCard,
  },
];

const policyAuthenticationOptions = [
  {
    label: 'Attempt authentication',
    value: SingleSignOnExtensionService.PolicyAuthenticationOptions.Attempt,
  },
  {
    label: 'Require authentication',
    value: SingleSignOnExtensionService.PolicyAuthenticationOptions.Required,
  },
];

const PlatformSSO = (props) => {
  const { setting, update, validationDep, isDisabled } = props;

  const { 'DC-WWDC24-SSO-changes': LD_wwdc24_SSO_changes } = useFlags([
    'DC-WWDC24-SSO-changes',
  ]);

  const { refs, onInvalidate } = useInputsValidators(
    ['LoginFrequency', 'OfflineGracePeriod', 'AuthenticationGracePeriod'],
    update,
  );

  const canShowOfflineGracePeriod = [
    setting.SetFileVaultPolicy &&
      setting.FileVaultPolicy ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      setting.FileVaultPolicyAllowOfflineGracePeriod,
    setting.SetLoginPolicy &&
      setting.LoginPolicy ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      setting.LoginPolicyAllowOfflineGracePeriod,
    setting.SetUnlockPolicy &&
      setting.UnlockPolicy ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      setting.UnlockPolicyAllowOfflineGracePeriod,
  ].some((val) => !!val);

  const canShowAuthenticationGracePeriod = [
    setting.SetFileVaultPolicy &&
      setting.FileVaultPolicy ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      setting.FileVaultPolicyAllowAuthenticationGracePeriod,
    setting.SetLoginPolicy &&
      setting.LoginPolicy ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      setting.LoginPolicyAllowAuthenticationGracePeriod,
    setting.SetUnlockPolicy &&
      setting.UnlockPolicy ===
        SingleSignOnExtensionService.PolicyAuthenticationOptions.Required &&
      setting.UnlockPolicyAllowAuthenticationGracePeriod,
  ].some((val) => !!val);

  const addUserGroup = () => {
    update('AuthorizationGroups', (p) => [
      ...p,
      SingleSignOnExtensionService.getEmptyUserGroup(),
    ]);
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Platform SSO (macOS 14+)</h3>
        <div className="b-flex">
          <Chip
            disabled={isDisabled}
            className="b-mr-tiny"
            text={setting.PlatformSSOActive ? 'Active' : 'Inactive'}
            kind={setting.PlatformSSOActive ? 'active' : 'primary'}
          />
          <Toggle
            disabled={isDisabled}
            checked={setting.PlatformSSOActive}
            onToggle={() => update('PlatformSSOActive', (p) => !p)}
          />
        </div>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Platform SSO requires a compatible Identity Provider app extension to
          be present on the device, which may also require vendor-specific
          configurations. It is supported on macOS 14 Sonoma or later.{' '}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000559822-single-sign-on-extension"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn more
          </a>
        </p>
      </Setting.SubHeader>
      {setting.PlatformSSOActive && (
        <Setting.Rows>
          <Setting.Row>
            <Setting.Title className="b-txt">
              Authentication method
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Specify the type of sign-on authorization to use for Platform
                SSO.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                options={authMethods}
                onChange={({ value }) => update('AuthenticationMethod', value)}
                value={authMethods.find(
                  ({ value }) => value === setting.AuthenticationMethod,
                )}
                disabled={isDisabled}
                compact
              />
            </Setting.Controls>
          </Setting.Row>
          {LD_wwdc24_SSO_changes &&
            setting.AuthenticationMethod ===
              SingleSignOnExtensionService.AuthorizationMethod.Password && (
              <>
                <Setting.Row>
                  <Setting.Controls>
                    <Checkbox
                      disabled={isDisabled}
                      checked={setting.SetFileVaultPolicy}
                      onChange={() => {
                        update('SetFileVaultPolicy', (p) => !p);

                        // Set the default value for FileVaultPolicy if it isn't already set:
                        if (
                          !setting.SetFileVaultPolicy &&
                          !setting.FileVaultPolicy
                        ) {
                          update(
                            'FileVaultPolicy',
                            SingleSignOnExtensionService
                              .PolicyAuthenticationOptions.Attempt,
                          );
                        }
                      }}
                      label="Set FileVault policy"
                    />
                  </Setting.Controls>
                  <Setting.Helpers className="b-txt-light">
                    <p>
                      Optional. The policy to apply when using Platform SSO at
                      FileVault unlock on Mac computers with Apple Silicon.
                      Available in macOS 15 and later.
                    </p>
                  </Setting.Helpers>

                  {setting.SetFileVaultPolicy && (
                    <Setting.SecondaryControls>
                      <Setting.SecondaryControlsRow>
                        <Setting.Helpers className="b-txt-light">
                          <p>
                            Whether Platform SSO authentication is attempted or
                            required before proceeding.
                          </p>
                        </Setting.Helpers>
                        <NectarFlex gap="xl" flow="column" alignItems="start">
                          <Select
                            options={policyAuthenticationOptions}
                            onChange={({ value }) =>
                              update('FileVaultPolicy', value)
                            }
                            value={policyAuthenticationOptions.find(
                              ({ value }) => value === setting.FileVaultPolicy,
                            )}
                            disabled={isDisabled}
                            compact
                          />
                          {setting.FileVaultPolicy ===
                            SingleSignOnExtensionService
                              .PolicyAuthenticationOptions.Required && (
                            <>
                              <Checkbox
                                disabled={isDisabled}
                                checked={
                                  setting.FileVaultPolicyAllowOfflineGracePeriod
                                }
                                onChange={() =>
                                  update(
                                    'FileVaultPolicyAllowOfflineGracePeriod',
                                    (p) => !p,
                                  )
                                }
                                label="Allow offline grace period"
                              />
                              <Checkbox
                                disabled={isDisabled}
                                checked={
                                  setting.FileVaultPolicyAllowAuthenticationGracePeriod
                                }
                                onChange={() =>
                                  update(
                                    'FileVaultPolicyAllowAuthenticationGracePeriod',
                                    (p) => !p,
                                  )
                                }
                                label="Allow authentication grace period"
                              />
                            </>
                          )}
                        </NectarFlex>
                      </Setting.SecondaryControlsRow>
                    </Setting.SecondaryControls>
                  )}
                </Setting.Row>

                <Setting.Row>
                  <Setting.Controls>
                    <Checkbox
                      disabled={isDisabled}
                      checked={setting.SetLoginPolicy}
                      onChange={() => {
                        update('SetLoginPolicy', (p) => !p);

                        // Set the default value for LoginPolicy if it isn't already set:
                        if (!setting.SetLoginPolicy && !setting.LoginPolicy) {
                          update(
                            'LoginPolicy',
                            SingleSignOnExtensionService
                              .PolicyAuthenticationOptions.Attempt,
                          );
                        }
                      }}
                      label="Set login policy"
                    />
                  </Setting.Controls>
                  <Setting.Helpers className="b-txt-light">
                    <p>
                      Optional. The policy to apply when using Platform SSO at
                      the login window. Available in macOS 15 and later.
                    </p>
                  </Setting.Helpers>

                  {setting.SetLoginPolicy && (
                    <Setting.SecondaryControls>
                      <Setting.SecondaryControlsRow>
                        <Setting.Helpers className="b-txt-light">
                          <p>
                            Whether Platform SSO authentication is attempted or
                            required before proceeding.
                          </p>
                        </Setting.Helpers>
                        <NectarFlex gap="xl" flow="column" alignItems="start">
                          <Select
                            options={policyAuthenticationOptions}
                            onChange={({ value }) =>
                              update('LoginPolicy', value)
                            }
                            value={policyAuthenticationOptions.find(
                              ({ value }) => value === setting.LoginPolicy,
                            )}
                            disabled={isDisabled}
                            compact
                          />
                          {setting.LoginPolicy ===
                            SingleSignOnExtensionService
                              .PolicyAuthenticationOptions.Required && (
                            <>
                              <Checkbox
                                disabled={isDisabled}
                                checked={
                                  setting.LoginPolicyAllowOfflineGracePeriod
                                }
                                onChange={() =>
                                  update(
                                    'LoginPolicyAllowOfflineGracePeriod',
                                    (p) => !p,
                                  )
                                }
                                label="Allow offline grace period"
                              />
                              <Checkbox
                                disabled={isDisabled}
                                checked={
                                  setting.LoginPolicyAllowAuthenticationGracePeriod
                                }
                                onChange={() =>
                                  update(
                                    'LoginPolicyAllowAuthenticationGracePeriod',
                                    (p) => !p,
                                  )
                                }
                                label="Allow authentication grace period"
                              />
                            </>
                          )}
                        </NectarFlex>
                      </Setting.SecondaryControlsRow>
                    </Setting.SecondaryControls>
                  )}
                </Setting.Row>

                <Setting.Row>
                  <Setting.Controls>
                    <Checkbox
                      disabled={isDisabled}
                      checked={setting.SetUnlockPolicy}
                      onChange={() => {
                        update('SetUnlockPolicy', (p) => !p);

                        // Set the default value for UnlockPolicy if it isn't already set:
                        if (!setting.SetUnlockPolicy && !setting.UnlockPolicy) {
                          update(
                            'UnlockPolicy',
                            SingleSignOnExtensionService
                              .PolicyAuthenticationOptions.Attempt,
                          );
                        }
                      }}
                      label="Set unlock policy"
                    />
                  </Setting.Controls>
                  <Setting.Helpers className="b-txt-light">
                    <p>
                      Optional. The policy to apply when using Platform SSO at
                      screensaver unlock. Available in macOS 15 and later.
                    </p>
                  </Setting.Helpers>

                  {setting.SetUnlockPolicy && (
                    <Setting.SecondaryControls>
                      <Setting.SecondaryControlsRow>
                        <Setting.Helpers className="b-txt-light">
                          <p>
                            Whether Platform SSO authentication is attempted or
                            required before proceeding.
                          </p>
                        </Setting.Helpers>
                        <NectarFlex gap="xl" flow="column" alignItems="start">
                          <Select
                            options={policyAuthenticationOptions}
                            onChange={({ value }) =>
                              update('UnlockPolicy', value)
                            }
                            value={policyAuthenticationOptions.find(
                              ({ value }) => value === setting.UnlockPolicy,
                            )}
                            disabled={isDisabled}
                            compact
                          />
                          {setting.UnlockPolicy ===
                            SingleSignOnExtensionService
                              .PolicyAuthenticationOptions.Required && (
                            <>
                              <Checkbox
                                disabled={isDisabled}
                                checked={
                                  setting.UnlockPolicyAllowOfflineGracePeriod
                                }
                                onChange={() =>
                                  update(
                                    'UnlockPolicyAllowOfflineGracePeriod',
                                    (p) => !p,
                                  )
                                }
                                label="Allow offline grace period"
                              />
                              <Checkbox
                                disabled={isDisabled}
                                checked={
                                  setting.UnlockPolicyAllowAuthenticationGracePeriod
                                }
                                onChange={() =>
                                  update(
                                    'UnlockPolicyAllowAuthenticationGracePeriod',
                                    (p) => !p,
                                  )
                                }
                                label="Allow authentication grace period"
                              />
                              <Checkbox
                                disabled={isDisabled}
                                checked={
                                  setting.UnlockPolicyAllowTouchIDOrWatchForUnlock
                                }
                                onChange={() =>
                                  update(
                                    'UnlockPolicyAllowTouchIDOrWatchForUnlock',
                                    (p) => !p,
                                  )
                                }
                                label="Allow TouchID or Watch to unlock the screensaver"
                              />
                            </>
                          )}
                        </NectarFlex>
                      </Setting.SecondaryControlsRow>
                    </Setting.SecondaryControls>
                  )}
                </Setting.Row>

                {canShowOfflineGracePeriod && (
                  <Setting.Row>
                    <Setting.Title className="b-txt">
                      Offline grace period
                    </Setting.Title>
                    <Setting.Helpers className="b-txt-light">
                      <p>
                        The amount of time after the last successful Platform
                        SSO login a local  account password can be used offline.
                        Required as “Allow Offline Grace Period” is selected.
                        Available in macOS 15 and later.
                      </p>
                    </Setting.Helpers>
                    <Setting.Controls>
                      <NectarFlex
                        gap="md"
                        alignItems="center"
                        ref={refs.OfflineGracePeriod}
                      >
                        <TextInput
                          compact
                          type="number"
                          disabled={isDisabled}
                          value={setting.OfflineGracePeriod}
                          placeholder="120"
                          onChange={(e) =>
                            update('OfflineGracePeriod', e.target.value)
                          }
                          validator={(v) => [
                            {
                              trigger: ['onBlur', validationDep],
                              message: 'Required',
                              invalid: () => !v,
                            },
                          ]}
                          onInvalidate={onInvalidate('OfflineGracePeriod')}
                        />
                        <Text>seconds</Text>
                      </NectarFlex>
                    </Setting.Controls>
                  </Setting.Row>
                )}

                {canShowAuthenticationGracePeriod && (
                  <Setting.Row>
                    <Setting.Title className="b-txt">
                      Authentication grace period
                    </Setting.Title>
                    <Setting.Helpers className="b-txt-light">
                      <p>
                        The amount of time after the last successful Platform
                        SSO login a local  account password can be used offline.
                        Required as “Allow Offline Grace Period” is selected.
                        Available in macOS 15 and later.
                      </p>
                    </Setting.Helpers>
                    <Setting.Controls>
                      <NectarFlex
                        gap="md"
                        alignItems="center"
                        ref={refs.AuthenticationGracePeriod}
                      >
                        <TextInput
                          compact
                          type="number"
                          disabled={isDisabled}
                          value={setting.AuthenticationGracePeriod}
                          placeholder="120"
                          onChange={(e) =>
                            update('AuthenticationGracePeriod', e.target.value)
                          }
                          validator={(v) => [
                            {
                              trigger: ['onBlur', validationDep],
                              message: 'Required',
                              invalid: () => !v,
                            },
                          ]}
                          onInvalidate={onInvalidate(
                            'AuthenticationGracePeriod',
                          )}
                        />
                        <Text>seconds</Text>
                      </NectarFlex>
                    </Setting.Controls>
                  </Setting.Row>
                )}
              </>
            )}
          <Setting.Row>
            <Setting.Title className="b-txt">
              Existing user permissions
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Set the permissions to apply to an account each time the user
                authenticates.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                options={authModes}
                onChange={({ value }) => update('UserAuthorizationMode', value)}
                value={authModes.find(
                  ({ value }) => value === setting.UserAuthorizationMode,
                )}
                disabled={isDisabled}
                compact
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">
              New user permissions
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Set the permissions to apply for newly created accounts at
                login.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                options={authModes}
                onChange={({ value }) =>
                  update('NewUserAuthorizationMode', value)
                }
                value={authModes.find(
                  ({ value }) => value === setting.NewUserAuthorizationMode,
                )}
                disabled={isDisabled}
                compact
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Controls>
              <Checkbox
                checked={setting.UseSharedDeviceKeys}
                onChange={() => update('UseSharedDeviceKeys', (p) => !p)}
                label="Use shared device keys"
              />
            </Setting.Controls>
            <Setting.Helpers className="b-txt-light" style={{ gridArea: 1 }}>
              <div>
                <p>
                  Specify if the system uses the same signing and encryption
                  keys for all users.
                </p>
                <Banner theme="info">
                  <span>
                    Required to allow authorization and new account creation.
                  </span>
                </Banner>
              </div>
            </Setting.Helpers>
            {setting.UseSharedDeviceKeys && (
              <Setting.SecondaryControls>
                <Setting.SecondaryControlsRow>
                  <div>
                    <div>
                      <Checkbox
                        checked={setting.EnableAuthorization}
                        onChange={() =>
                          update('EnableAuthorization', (p) => !p)
                        }
                        label="Allow authorization (with identity provider account)"
                      />
                    </div>
                    <div>
                      <Checkbox
                        checked={setting.EnableCreateUserAtLogin}
                        onChange={() =>
                          update('EnableCreateUserAtLogin', (p) => !p)
                        }
                        label="Allow creation of new users at login window"
                      />
                    </div>
                  </div>
                </Setting.SecondaryControlsRow>
              </Setting.SecondaryControls>
            )}
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">
              Account display name
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Enter the account name to be displayed in notifications and
                authentication requests.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <div ref={refs.AccountDisplayName}>
                <TextInput
                  compact
                  disabled={isDisabled}
                  value={setting.AccountDisplayName}
                  placeholder="Accuhive Platform SSO"
                  onChange={(e) => update('AccountDisplayName', e.target.value)}
                />
              </div>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">
              Require full login after
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Specify when the system requires a full login instead of a
                refresh.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <div ref={refs.LoginFrequency}>
                <Flex style={{ gap: '8px' }} align="center">
                  <TextInput
                    compact
                    type="number"
                    disabled={isDisabled}
                    value={setting.LoginFrequency}
                    placeholder="3601"
                    onChange={(e) => update('LoginFrequency', e.target.value)}
                    validator={(v) => [
                      {
                        trigger: ['onBlur', validationDep],
                        message: 'Required',
                        invalid: () => !v,
                      },
                      {
                        message: 'Must be greater than 3600 seconds',
                        invalid: () => parseFloat(v) < 3601,
                        trigger: ['onBlur', validationDep],
                      },
                    ]}
                    onInvalidate={onInvalidate('LoginFrequency')}
                  />
                  <Text>seconds</Text>
                </Flex>
              </div>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">Token mapping</Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                The attribute mapping to use when creating new users or for
                authorization.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <Flex className="b-flex-vc b-flex-gtiny" style={{ gap: '8px' }}>
                <div ref={refs.AccountName}>
                  <TextInput
                    superCompact
                    disabled={isDisabled}
                    value={setting.AccountName}
                    placeholder="AccountName"
                    onChange={(e) => update('AccountName', e.target.value)}
                  />
                </div>
                <div ref={refs.FullName}>
                  <TextInput
                    superCompact
                    disabled={isDisabled}
                    value={setting.FullName}
                    placeholder="FullName"
                    onChange={(e) => update('FullName', e.target.value)}
                  />
                </div>
              </Flex>
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">Admin groups</Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                The list of groups to use for administrator access. macOS
                requests membership during authentication.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <MultiText
                name="AdministratorGroups"
                values={setting.AdministratorGroups}
                placeholder="Type admin group and press enter..."
                onChange={(v) => update('AdministratorGroups', v.values)}
                disabled={isDisabled}
                hint="Press enter to add another group"
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">Additional groups</Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>
                Groups specified here will be created as local group if they
                don&lsquo;t exist, and will be available in the local directory
                service.
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <MultiText
                name="AdditionalGroups"
                placeholder="Type additional group and press enter..."
                values={setting.AdditionalGroups}
                onChange={(v) => update('AdditionalGroups', v.values)}
                disabled={isDisabled}
                hint="Press enter to add another group"
              />
            </Setting.Controls>
          </Setting.Row>
          <Setting.Row>
            <Setting.Title className="b-txt">
              Authorization Rights
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              <p>Specify authorization right to user group mappings.</p>
              <Banner theme="info">
                <span>
                  Each right can only be specified once and mapped to a single
                  group.
                </span>
              </Banner>
            </Setting.Helpers>
            <Setting.Controls>
              <UserGroupsContainer className="b-mb2 b-flex-vgtiny">
                {setting.AuthorizationGroups.map((ag) => (
                  <UserGroup
                    key={ag.id}
                    isDisabled={isDisabled}
                    setting={ag}
                    allUserGroups={setting.AuthorizationGroups}
                    validationDep={validationDep}
                    updateValidation={update}
                    update={(field, val) =>
                      update('AuthorizationGroups', (p) => {
                        const itemIdx = p.findIndex(
                          (authGroup) => authGroup.id === ag.id,
                        );

                        return [
                          ...p.slice(0, itemIdx),
                          {
                            ...p[itemIdx],
                            [field]: val,
                          },
                          ...p.slice(itemIdx + 1),
                        ];
                      })
                    }
                    canDelete
                    onDelete={() =>
                      update('AuthorizationGroups', (p) => {
                        const itemIdx = p.findIndex(
                          (authGroup) => authGroup.id === ag.id,
                        );

                        return [
                          ...p.slice(0, itemIdx),
                          ...p.slice(itemIdx + 1),
                        ];
                      })
                    }
                  />
                ))}
              </UserGroupsContainer>
              <Button
                kind="link"
                icon="circle-plus"
                onClick={addUserGroup}
                isDisabled={isDisabled}
              >
                Add group
              </Button>
            </Setting.Controls>
          </Setting.Row>
          {LD_wwdc24_SSO_changes && (
            <Setting.Row>
              <Setting.Title className="b-txt">
                Non Platform SSO accounts
              </Setting.Title>
              <Setting.Helpers className="b-txt-light">
                <p>
                  Optional. The list of local accounts that are not subject to
                  the File Vault Policy, Login Policy, or Unlock Policy. The
                  accounts are also not prompted to register for Platform SSO.
                </p>
              </Setting.Helpers>
              <Setting.Controls>
                <MultipleTextField
                  validationDep={validationDep}
                  isDisabled={isDisabled}
                  update={update}
                  items={setting.NonPlatformSSOAccounts || []}
                  settingKey="NonPlatformSSOAccounts"
                  placeholder="Enter non Platform SSO account"
                  addButtonText="Add account"
                />
              </Setting.Controls>
            </Setting.Row>
          )}
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default PlatformSSO;
