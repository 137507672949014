import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
/* istanbul ignore file */
import { create } from 'zustand';
import {
  defaultAllVulnerabilitiesFilter,
  defaultDeviceVulnerabilitiesFilter,
} from './constants';
import type { Vulnerability } from './vulnerability.types';

type State = {
  selectedVulnerability: Vulnerability | null;
  allVulnerabilitiesFilter: {
    term: string;
    severity: string[];
    firstDetected: DetectionDateFilterFields;
    latestDetected: DetectionDateFilterFields;
  };
  deviceVulnerabilitiesFilter: {
    term: string;
    severity: string[];
    detectionDate: DetectionDateFilterFields;
  };
};

type Action = {
  setSelectedVulnerability: (
    vulnerability: Partial<State['selectedVulnerability']>,
  ) => void;
  setAllVulnerabilitiesFilter: (
    filter: Partial<State['allVulnerabilitiesFilter']>,
  ) => void;
  setDeviceVulnerabilitiesFilter: (
    filter: Partial<State['deviceVulnerabilitiesFilter']>,
  ) => void;
};

const useVulnerability = create<Partial<State> & Partial<Action>>((set) => ({
  selectedVulnerability: null,
  allVulnerabilitiesFilter: defaultAllVulnerabilitiesFilter,
  deviceVulnerabilitiesFilter: defaultDeviceVulnerabilitiesFilter,

  setSelectedVulnerability: (vulnerability) =>
    set((state) => ({
      selectedVulnerability: {
        ...state.selectedVulnerability,
        ...vulnerability,
      },
    })),
  setAllVulnerabilitiesFilter: (filter) =>
    set((state) => ({
      allVulnerabilitiesFilter: {
        ...state.allVulnerabilitiesFilter,
        ...filter,
      },
    })),
  setDeviceVulnerabilitiesFilter: (filter) =>
    set((state) => ({
      deviceVulnerabilitiesFilter: {
        ...state.deviceVulnerabilitiesFilter,
        ...filter,
      },
    })),
}));

export type { State as VulnerabilityState };
export default useVulnerability;
