import { Button, Flex, Icon, TextField, styled } from '@kandji-inc/nectar-ui';
import * as React from 'react';

export const ChatInput = ({
  onSubmit,
  disabled,
}: { onSubmit: (message: string) => void; disabled: boolean }) => {
  const [messageInput, setMessageInput] = React.useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageInput(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(messageInput);
    setMessageInput('');
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <TextInputContainer>
        <TextField
          value={messageInput}
          onChange={handleInputChange}
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '$1',
            flex: '1 0 0',
          }}
          placeholder="What can I find for you today?"
        />
        <Button
          variant="primary"
          type="submit"
          css={{ '& svg': { width: '16px', height: '16px' }, height: '100%' }}
          disabled={messageInput.length === 0 || disabled}
        >
          <Icon name="arrow-up" />
        </Button>
      </TextInputContainer>
    </StyledForm>
  );
};

const StyledForm = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const TextInputContainer = styled(Flex, {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$2',
  alignSelf: 'stretch',
  padding: '$2',
});
