import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { msDeviceComplianceService } from '../services';
import type { PlatformStatus } from '../types';
import { msDeviceComplianceKeys } from './query-key-factory';

const useDeleteDevicePlatform = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (platform: PlatformStatus) =>
      msDeviceComplianceService.deleteGroupPolicies(id, platform),
    onSuccess: () => {
      // refetch the integration details
      queryClient.invalidateQueries({
        queryKey: msDeviceComplianceKeys.integrationDetails(id),
      });
    },
  });
};

export default useDeleteDevicePlatform;
