// istanbul ignore file
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { prismService } from '../../prism/data-service/prism-service';
import type { CustomViewsOrderRequestSchema } from '../../prism/types/prism.types';

const CUSTOM_VIEWS_ORDER_QUERY_KEY = ['customViewsOrder'];
const CUSTOM_VIEWS_ORDER_MUTATION_KEY = ['updateCustomViewsOrder'];

export const useCustomViewsOrderQuery = () => {
  return useQuery({
    queryKey: CUSTOM_VIEWS_ORDER_QUERY_KEY,
    queryFn: () => prismService.getCustomViewsOrder(),
  });
};

export const useUpdateCustomViewsOrderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: CUSTOM_VIEWS_ORDER_MUTATION_KEY,
    mutationFn: (order: CustomViewsOrderRequestSchema) =>
      prismService.updateCustomViewsOrder(order),
    onMutate: async (newOrder) => {
      await queryClient.cancelQueries({
        queryKey: CUSTOM_VIEWS_ORDER_QUERY_KEY,
      });

      // Snapshot the previous value
      const previousOrder = queryClient.getQueryData(
        CUSTOM_VIEWS_ORDER_QUERY_KEY,
      );

      // Optimistically update to the new value
      queryClient.setQueryData(CUSTOM_VIEWS_ORDER_QUERY_KEY, newOrder);

      // Return a context object with the snapshotted value
      return { previousOrder };
    },
    onError: (err, newOrder, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      queryClient.setQueryData(
        CUSTOM_VIEWS_ORDER_QUERY_KEY,
        context?.previousOrder,
      );
    },
    onSettled: () => {
      // Always refetch after error or success to ensure we have the latest data
      queryClient.invalidateQueries({ queryKey: CUSTOM_VIEWS_ORDER_QUERY_KEY });
    },
  });
};
