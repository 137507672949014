import {
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import React from 'react';

import { Setting } from 'features/library-items/template';

import { AddableTextField } from '../common';

const Redirect = (props) => {
  const { setting, update, validationDep, isDisabled } = props;

  const fieldsToValidate = ['extension_identifier'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  return (
    <>
      <Setting.Row>
        <Setting.Title className="b-txt">Extension identifier</Setting.Title>
        <Setting.Helpers className="b-txt-light">
          <p ref={refs[0]}>
            The bundle identifier of the app extension that performs single
            sign-on.
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <TextInput
            compact
            removeValidationOnUnmount
            disabled={isDisabled}
            value={setting.extension_identifier}
            onChange={(e) => update('extension_identifier', e.target.value)}
            placeholder="com.accuhive.enterpriseSSO.extension"
            onInvalidate={onInvalidate('extension_identifier')}
            validator={(v) => [
              {
                message: 'Required.',
                invalid: () => !v,
                trigger: ['onBlur', validationDep],
              },
            ]}
          />
        </Setting.Controls>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title className="b-txt">
          Team identifier <span className="b-txt-light">(optional)</span>
        </Setting.Title>
        <Setting.Helpers className="b-txt-light">
          <p>
            The team identifier of the app extension that performs single
            sign-on. Required if extension will be assigned to macOS devices.
            Team identifier must be a 10-digit alphanumeric string.
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <TextInput
            compact
            disabled={isDisabled}
            value={setting.team_identifier}
            onChange={(e) => update('team_identifier', e.target.value)}
            placeholder="P3FGV63VK7"
          />
        </Setting.Controls>
      </Setting.Row>

      <Setting.Row>
        <Setting.Title className="b-txt">URLs</Setting.Title>
        <Setting.Helpers className="b-txt-light">
          <p>
            The URL prefixes of identity providers on whose behalf the app
            extension performs single sign-on. Query parameters and URL
            fragments are not allowed. The URLs must begin with http:// or
            https:// and be unique for all configured Single Sign-On Extension
            profiles installed on a device.
          </p>
        </Setting.Helpers>
        <Setting.Controls>
          <AddableTextField
            validationDep={validationDep}
            isDisabled={isDisabled}
            update={update}
            items={setting.urls}
            settingKey="urls"
            placeholder="https://identity.accuhive.io"
          />
        </Setting.Controls>
      </Setting.Row>
    </>
  );
};

export default Redirect;
