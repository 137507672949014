/* istanbul ignore file - legacy code */
import { Banner } from '@kandji-inc/bumblebee';

import { setModal, setSnackbar } from 'app/_actions/ui';
import { libraryAxios } from 'app/api/base';
import { colors, links } from 'app/common/constants';
import ActivityTabNew from 'app/components/ActivityTabNew';
import KandjiSupportLink from 'app/components/common/KandjiSupportLink';
import { Row } from 'app/components/good/layout.styles';
import { LineLoader } from 'app/components/interface/LineLoader';
import ToggleButton from 'app/components/interface/buttons/ToggleButton/ToggleButton';
import {
  Active,
  Devices,
  Inactive,
} from 'app/components/library/common/LibraryPage.styles';
import classNames from 'classnames';
import { InterfaceContext } from 'contexts/interface';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { i18n } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  ApplicationBanner,
  ApplicationDescription,
  ApplicationInfo,
  ApplicationName,
  ApplicationNameInput,
  Nav,
  SmallName,
  WhiteCardHeader,
  WhiteCardHeaderBottom,
  WhiteCardHeaderTop,
  Wrapper,
} from './SCEP.styles';
import { SCEPAssignment } from './SCEPAssignment';
import { SCEPAssignmentDisplay } from './SCEPAssignmentDisplay';
import SCEPBreadcrumbs from './SCEPBreadcrumbs';
import { SCEPProfile } from './SCEPProfile';
import { SCEPSettingsContext } from './SCEPSettingsContext';
import { Tag } from './SCEPShortenedTags';
import { ProfilesAPI } from './SHAREDApi';

const BreadcrumbsWrapper = styled('section')`
  ${(props) =>
    props.sticky &&
    `
    background-color: ${colors['grey-80']};
    display: flex;
    padding-bottom: 20px;
    position: sticky;
    z-index: 115;
    top: ${props.bannerTopOffset}px;
    div {
      padding-bottom: 0;
      }
  `}
`;

const DIRECTIONS = {
  from: 'from',
  to: 'to',
};

const styleName = (name) =>
  name.length >= 65
    ? { fontSize: '14px', lineHeight: '20px' }
    : name.length >= 25
      ? { fontSize: '22px', lineHeight: '26px' }
      : null;

const checkBlueprintAssignmentConflicts = (
  type,
  identifier,
  itemId,
  blueprints,
) =>
  libraryAxios('check-conflicts/')
    .post({
      type,
      identifier,
      item_id: itemId,
      blueprints,
    })
    .then((res) => res.data.conflicts);

const SCEPProfilePageInner = ({ history, match, location, testId }) => {
  const profile = SCEPProfile;
  const { tab, profileId } = match.params;
  const MAX_TITLE_LEN = 32;
  const [state, setState] = useState(profile.defaultState);
  const dispatch = useDispatch();
  const { isEditingItem, setIsEditingItem } = useContext(SCEPSettingsContext);
  const { bannerTopOffset } = useContext(InterfaceContext);

  const changeState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  function handleProfileData(payload, direction = DIRECTIONS.from) {
    const isFrom = direction === DIRECTIONS.from;
    const cleanData = { ...payload };
    if (isFrom) {
      changeState({ profileData: cleanData, initialProfileData: cleanData });
    }
    return cleanData;
  }

  useEffect(() => {
    if (profileId) {
      ProfilesAPI.retrieve(profileId)
        .then(handleProfileData)
        .catch(() => {
          history.push(links.library);
          dispatch(setSnackbar(i18n.common.error()));
        })
        .finally(() => changeState({ isLoading: false }));
    } else {
      setIsEditingItem(true);
      changeState({ isLoading: false });
    }
  }, []);

  const switchEditName = () => changeState({ editName: false });

  const onNameError = () => {
    changeState({ nameError: true });
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const changeName = (e) => {
    const { profileData } = state;
    changeState({
      profileData: { ...profileData, name: e.target.value },
      nameError: !e.target.value || e.target.value.length > MAX_TITLE_LEN,
    });
  };

  const changeBlueprints = (values) => {
    const { profileData } = state;
    changeState({ profileData: { ...profileData, blueprints: values } });
  };

  const checkBlueprintConflicts = () => {
    if (profile.withoutBlueprintConflicts) {
      return Promise.resolve();
    }

    const { profileData } = state;
    return checkBlueprintAssignmentConflicts(
      profile.type,
      profile.identifier,
      profileId,
      profileData.blueprints,
    );
  };

  const onSaveClick = (settings) => {
    const { profileData } = state;

    if (state.nameError) {
      onNameError(); // To do a scroll to top on save error.
      return Promise.reject();
    }

    // --------- Update Profile ---------
    if (profileId) {
      const toSend = handleProfileData(
        {
          name: profileData.name,
          blueprints: profileData.is_active ? profileData.blueprints : [],
          is_active: profileData.is_active,
          settings,
        },
        DIRECTIONS.to,
      );
      return ProfilesAPI.update(profileId, toSend)
        .then((apiData) => {
          const newData = handleProfileData(apiData);
          dispatch(setSnackbar(i18n.t('Profile has been saved')));
          return newData;
        })
        .catch(() => dispatch(setSnackbar(i18n.common.error())));
    }

    // --------- Create Profile ---------
    const payload = {
      ...profileData,
      blueprints: profileData.is_active ? profileData.blueprints : [],
      settings,
      identifier: profile.identifier,
    };

    const toSend = handleProfileData(payload, DIRECTIONS.to);

    return ProfilesAPI.create(toSend)
      .then((apiData) => {
        const newData = handleProfileData(apiData);
        dispatch(setSnackbar(i18n.t('Profile has been saved')));
        history.push(`/library/${profile.slug}/${newData.id}/settings`);
        return newData;
      })
      .catch(() => dispatch(setSnackbar(i18n.common.error())));
  };

  const onDeleteClick = () => {
    const { profileData } = state;

    dispatch(
      setModal('WIDE_DIALOG', {
        text: `Delete ${profileData.name}?`,
        caption: (
          <>
            <span
              style={{ fontWeight: 500 }}
            >{`Are you sure you want to delete ${profileData.name}? `}</span>
            This action is permanent, and cannot be undone.
          </>
        ),
        cancelText: 'Delete',
        cancelAction: () =>
          ProfilesAPI.delete(profileId)
            .then(() =>
              dispatch(setSnackbar(`${profileData.name} was Deleted`)),
            )
            .then(() => history.push(links.library)),
        effectText: "Don't delete",
        effectAction: () => Promise.resolve(),
      }),
    );
  };

  const renderNav = () =>
    profileId && (
      <Nav className="nav mb-4">
        <Link
          className={classNames('nav-link', { active: tab === 'settings' })}
          to={`${links.library}/${profile.slug}/${profileId}/settings`}
        >
          Settings
        </Link>
        <Link
          className={classNames('nav-link', { active: tab === 'status' })}
          to={`${links.library}/${profile.slug}/${profileId}/status`}
        >
          Status
        </Link>
        <Link
          className={classNames('nav-link', { active: tab === 'activity' })}
          to={`${links.library}/${profile.slug}/${profileId}/activity`}
        >
          Activity
        </Link>
      </Nav>
    );

  const renderTab = () => {
    const { initialProfileData, profileData } = state;
    const { SettingsTab } = profile;
    const routerProps = () => ({
      location,
      match,
      history,
    });

    switch (tab) {
      default:
      case 'settings':
        return (
          <SettingsTab
            profileId={profileId}
            onClickCancel={() => {
              if (profileId) {
                setIsEditingItem(false);
                changeState({ profileData: initialProfileData });
              } else {
                history.push(links.library);
              }
            }}
            onSaveClick={onSaveClick}
            onDelete={onDeleteClick}
            settings={profileData.settings}
            profileName={profileData.name}
            initialProfileId={profileData.id}
            onNameError={onNameError}
          />
        );
      case 'status':
        return <NewStatusTab />;
      case 'activity':
        return (
          <ActivityTabNew
            libraryItemId={profileData.id}
            activityTabType="app"
            filters={['type', 'period']}
            {...routerProps()}
          />
        );
    }
  };

  const { isLoading, profileData, nameError } = state;

  const isAppLock = profile.slug === 'app-lock';

  const sidebarOpen = useSelector((state) => state.ui.sidebar.opened);

  useEffect(() => {
    document.querySelector('body').classList.add('hubspot-handler');

    return () =>
      document.querySelector('body').classList.remove('hubspot-handler');
  }, []);

  if (isLoading) {
    return <LineLoader />;
  }
  return (
    <>
      <BreadcrumbsWrapper
        sticky={profile.sticky}
        bannerTopOffset={bannerTopOffset}
      >
        <SCEPBreadcrumbs
          sticky={profile.sticky}
          crumbs={[
            { link: links.library, name: 'Library' },
            { name: profileData.name || profile.name },
          ]}
        />
      </BreadcrumbsWrapper>
      <Wrapper data-testid={testId}>
        <WhiteCardHeader>
          <WhiteCardHeaderTop>
            {profile.getIcon(profile, 40)}
            {isEditingItem ? (
              <ApplicationNameInput
                type="text"
                value={profileData.name}
                onChange={changeName}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    switchEditName();
                  }
                }}
                placeholder={`${profile.name} Name`}
                error={nameError}
              />
            ) : (
              <ApplicationName style={styleName(profileData.name)}>
                {profileData.name}
              </ApplicationName>
            )}

            {profileData.is_active ? (
              <Active>Active</Active>
            ) : (
              <Inactive>Inactive</Inactive>
            )}

            <ToggleButton
              extraClass="ToggleGreen"
              checked={profileData.is_active}
              disabled={!isEditingItem}
              onToggle={() =>
                setState((prevState) => ({
                  ...prevState,
                  profileData: {
                    ...prevState.profileData,
                    is_active: !prevState.profileData.is_active,
                  },
                }))
              }
            />
          </WhiteCardHeaderTop>
          <WhiteCardHeaderBottom sidebarOpen={sidebarOpen} banner={false}>
            <ApplicationDescription>
              <SmallName>{`${profile.name} Profile`}</SmallName>
              {profile.description}
              {profile.learnMoreURL && (
                <KandjiSupportLink
                  className="left-space"
                  href={profile.learnMoreURL}
                  text="Learn More"
                />
              )}
            </ApplicationDescription>
            <ApplicationInfo>
              <ApplicationInfo.Key>Publisher:</ApplicationInfo.Key>
              <ApplicationInfo.Value>Apple, Inc.</ApplicationInfo.Value>
              <ApplicationInfo.Key>Device family:</ApplicationInfo.Key>
              <ApplicationInfo.Value>
                <Row spacing={6} style={{ marginTop: '4px' }}>
                  {profile.deviceFamily.map((family, idx) => (
                    <Tag light fontDark key={idx}>
                      {family}
                    </Tag>
                  ))}
                </Row>
              </ApplicationInfo.Value>
              <ApplicationInfo.Key>OS requirement:</ApplicationInfo.Key>
              <ApplicationInfo.Value>
                <Devices>
                  <Devices.Grid>
                    {profile.osRequirement.map((requirement, idx) => (
                      <Devices.Text key={idx}>{requirement}</Devices.Text>
                    ))}
                  </Devices.Grid>
                </Devices>
              </ApplicationInfo.Value>
            </ApplicationInfo>
            {isAppLock && (
              <ApplicationBanner>
                <Banner theme="info" kind="block" className="app-lock--banner">
                  <div className="b-flex b-flex-align-center b-flex-justify-start">
                    <span>App Lock requires device supervision.</span>
                  </div>
                </Banner>
              </ApplicationBanner>
            )}
            {profileData.is_active &&
              (isEditingItem ? (
                <SCEPAssignment
                  blueprintAssignmentData={{
                    selectedBlueprints: profileData.blueprints,
                    setSelectedBlueprints: changeBlueprints,
                    checkBlueprintConflicts,
                  }}
                />
              ) : (
                <SCEPAssignmentDisplay
                  selectedBlueprints={profileData.blueprints}
                />
              ))}
          </WhiteCardHeaderBottom>
        </WhiteCardHeader>
        {renderNav()}
        {renderTab()}
      </Wrapper>
    </>
  );
};

export default withRouter(SCEPProfilePageInner);
