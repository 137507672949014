import { Box, Flex, Loader } from '@kandji-inc/nectar-ui';
import { useContext, useState } from 'react';
import { DataTable } from 'src/components';
import { Pagination } from 'src/components/ui';
import { InterfaceContext } from 'src/contexts/interface';
import useAdjustSidebarChatBubble from 'src/features/integrations/hooks/use-adjust-sidebar-chat-bubble';
import { usePagination } from 'src/features/visibility/prism/hooks';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { NoResultsFound } from '../common/table/no-results-found';
import { NoVulnerabilities } from '../common/table/no-vulnerabilities';
import { getColumns } from '../common/table/table-columns';
import { useGetVulnerabilities } from '../hooks/use-get-vulnerabilities';
import { useVulnerabilitySort } from '../hooks/use-vulnerability-sort';
import useVulnerability from '../store';
import { FilterBar } from './filter-bar';
import { VulnerabilitySidePanel } from './side-panel';
import { transformAllVulnerabilitiesFilterToApi } from './transformers/transformToApi';

const VulnerabilitiesTable = () => {
  useWhiteBackground();

  useAdjustSidebarChatBubble();
  const { paginationState: pagination, setPagination } = usePagination();
  const { sidebarDocked } = useContext(InterfaceContext);
  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;

  const columns = getColumns({ isOnDeviceRecord: false });

  const [selectedVulnerabilityId, setSelectedVulnerabilityId] = useState(null);
  const filter = useVulnerability((state) => state.allVulnerabilitiesFilter);

  const { sort, sortBy, handleSort } = useVulnerabilitySort();

  const { vulnerabilities, count, isLoading } = useGetVulnerabilities(
    [pagination, sortBy, filter],
    {
      size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      sort_by: sortBy,
      filter: transformAllVulnerabilitiesFilterToApi(filter),
      search: filter.term,
    },
  );

  const hasAtLeastOneNonDateFilterApplied =
    filter.term || filter.severity.length > 0;
  const hasVulnerabilities = vulnerabilities?.length > 0;
  const hasNoVulnerabilities = !isLoading && !hasVulnerabilities;

  return (
    <Box hFull>
      <FilterBar />

      <Box
        css={{
          height: 'calc(100% - 231px)',

          // Provide extra space at the bottom of the scroll container so that
          // the ellipsis menus don't get overlapped by the chat bubble
          ':where(table)': {
            marginBottom: '100px',
          },

          // since we have space between last row and the bottom of the
          // scroll container, we must manually add a bottom border
          ':where(tbody) tr:last-of-type': {
            borderBottom: '1px solid $neutral30',
          },

          // Apply margin between left edge of the table and the first column header
          ':where(thead) th:first-of-type': {
            paddingLeft: '36px',
          },

          // Apply margin between left edge of the table and the first column data cell
          ':where(tbody) td:first-of-type': {
            paddingLeft: '36px',
          },

          // Ensure ellipsis menu does not increase the height of each row
          ':where(tbody) td:last-of-type': {
            padding: 0,
          },
        }}
      >
        <DataTable
          // @ts-ignore - type Vulnerability is specified which is more clear that the expected `any` type
          columns={columns}
          data={vulnerabilities}
          pinnedColumns={[]}
          offsets={{}}
          onRowClick={(row) => setSelectedVulnerabilityId(row.cve_id)}
          sort={{ sortState: sort, setSortState: handleSort }}
          css={isLoading || hasNoVulnerabilities ? { maxHeight: '36px' } : {}}
        />

        {isLoading && (
          <Flex hFull alignItems="center" justifyContent="center">
            <Loader size="lg" data-testid="loader" />
          </Flex>
        )}

        {hasNoVulnerabilities && hasAtLeastOneNonDateFilterApplied && (
          <NoResultsFound />
        )}

        {hasNoVulnerabilities && !hasAtLeastOneNonDateFilterApplied && (
          <NoVulnerabilities
            firstDetectedFilter={filter.firstDetected}
            lastDetectedFilter={filter.latestDetected}
            onDeviceRecord={false}
          />
        )}
      </Box>

      <Box
        css={{
          position: 'fixed',
          bottom: 0,
          padding: '$3 $5',
          borderTop: '1px solid $neutral20',
          backgroundColor: '$neutral0',
          width: sidebarDocked
            ? `calc(100% - ${SIDEBAR_DOCKED_OFFSET}px)`
            : `calc(100% - ${SIDEBAR_CLOSE_OFFSET}px)`,
        }}
      >
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalItems={count}
          itemsPerPage={pagination.pageSize}
          onPageChange={
            /* istanbul ignore next */ (page) =>
              setPagination({ ...pagination, pageIndex: page - 1 })
          }
          onItemsPerPageChange={
            /*  istanbul ignore next */ (itemsPerPage) => {
              setPagination({
                ...pagination,
                pageIndex: 0,
                pageSize: itemsPerPage,
              });
            }
          }
        />
      </Box>

      {selectedVulnerabilityId && (
        <VulnerabilitySidePanel
          selectedVulnerabilityId={selectedVulnerabilityId}
          clearSelectedVulnerabilityId={() => setSelectedVulnerabilityId(null)}
        />
      )}
    </Box>
  );
};

export { VulnerabilitiesTable };
