import { PostureValue } from './avert.types';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,

  general: {
    malware_posture: PostureValue.DETECT,
    pup_posture: PostureValue.DETECT,
    process_mal_posture: PostureValue.DETECT,
    process_pup_posture: PostureValue.DETECT,
  },
  processMonitoringEnabled: true,
  allowBlock: {
    events: [],
  },
  unknownFileUpload: {
    file_upload_enabled: true,
  },
  endUserNotifications: {
    end_user_notifications_enabled: true,
  },
};
