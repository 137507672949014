import { Box, type CSS, Checkbox } from '@kandji-inc/nectar-ui';
import type { ColumnDef } from '@tanstack/react-table';

const CheckboxCell = ({
  disabled,
  selection,
  isHeader,
  row,
  rowId,
  table,
  onCheckedChange,
}: {
  disabled?: boolean;
  selection: string[];
  isHeader: boolean;
  row?: any;
  rowId: string;
  table: any;
  onCheckedChange?: ({ checked, row, rows, isHeader }) => void;
}) => {
  const rows = table.getRowModel().rows;
  const allSelected =
    rows?.length > 0 &&
    rows?.every((row) => selection.includes(row.original[rowId]));
  const someSelected = rows?.some((row) =>
    selection.includes(row.original[rowId]),
  );
  const headerChecked = allSelected || (someSelected ? 'indeterminate' : false);
  const checked = isHeader
    ? headerChecked
    : Boolean(selection.includes(row.original[rowId]));
  return (
    <Box>
      <Checkbox
        data-testid={`checkbox-${isHeader ? 'header' : row.original[rowId]}`}
        checked={checked}
        onCheckedChange={() =>
          onCheckedChange && onCheckedChange({ checked, row, rows, isHeader })
        }
        disabled={onCheckedChange === undefined || disabled}
      />
    </Box>
  );
};

export const SelectionColumn = ({
  id = 'selection',
  rowId = 'id',
  onCheckedChange,
  selection,
}): ColumnDef<unknown, any> => {
  return {
    id,
    header: (info) => {
      return (
        <CheckboxCell
          table={info.table}
          isHeader
          rowId={rowId}
          selection={selection}
          onCheckedChange={onCheckedChange}
        />
      );
    },
    cell: (info) => {
      return (
        <CheckboxCell
          table={info.table}
          row={info.row}
          isHeader={false}
          rowId={rowId}
          selection={selection}
          onCheckedChange={onCheckedChange}
        />
      );
    },
    enableSorting: false,
    minSize: 44,
    maxSize: 44,
    size: 44,
    meta: { css: { position: 'sticky', left: 0, zIndex: 1, width: 44 } },
  };
};
