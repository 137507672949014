/* istanbul ignore file */
import { Badge, Flex, Text } from '@kandji-inc/nectar-ui';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import featureFlags from 'src/config/feature-flags';
import useAccount from 'src/contexts/account';
import { ThreatTabs } from './edr-tabs';
import ThreatList from './threat/ThreatList';

const EDR_PATHS = {
  threats: { path: 'threats', label: 'Threats' },
  vulnerability: {
    path: 'vulnerability',
    label: (
      <Flex gap="xs" alignItems="center">
        <Text css={{ fontWeight: '$medium' }}>Vulnerability</Text>{' '}
        <Badge color="blue" icon="sparkles" compact>
          New
        </Badge>
      </Flex>
    ),
  },
};

const EdrRouter = ({ location }) => {
  const { path } = useRouteMatch();
  const {
    currentCompany: { feature_configuration },
  }: any = useAccount();

  const isThreatsV3On = featureFlags.getFlag('edr_041724_threats-v3');
  const isVulnOn = featureFlags.getFlag('vm_06252024_vuln-poc');
  const isVulnEnabled =
    isVulnOn && feature_configuration?.vulnerability_management?.enabled;

  const AVAILABLE_EDR_PATHS = isVulnEnabled
    ? EDR_PATHS
    : { threats: EDR_PATHS.threats };

  const threatRouteTabs = Object.values(AVAILABLE_EDR_PATHS)
    .map(({ path }) => path)
    .join('|');

  if (!isVulnEnabled) {
    return <ThreatList />;
  }

  return (
    <Switch>
      <Route
        exact={!isThreatsV3On}
        path={`${path}/:tab(${threatRouteTabs})`}
        component={ThreatTabs}
      />

      {/* Default to the Threat tab */}
      <Redirect
        to={{
          ...location,
          pathname: `${path}/${AVAILABLE_EDR_PATHS.threats.path}`,
        }}
      />
    </Switch>
  );
};

export { EdrRouter, EDR_PATHS };
