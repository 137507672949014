/* istanbul ignore file */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Flex,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';
import { getLibraryItem } from 'src/features/blueprint-flow/helpers';

import type { DraggableLibraryItem } from '../../blueprint-flow.types';
import { Deck } from '../../components';
import { DraggableLibraryItem as DraggableItem } from '../../components/library-item';
import useBlueprintFlow from '../../store';
import { nodePadding, statementLineHeight } from '../../theme';

type ScrollPosition = 'top' | 'middle' | 'bottom';

type ItemsProps = {
  items: DraggableLibraryItem[];
  itemIdsOnDevicePath?: Array<string>; // If provided, assume device path is active
  isScrollable?: boolean;
  setIsHoveringOverLibraryItems?: (isHovering: boolean) => void;
};

function Items(props: ItemsProps) {
  const {
    items,
    itemIdsOnDevicePath,
    isScrollable,
    setIsHoveringOverLibraryItems,
  } = props;
  const [
    libraryItems,
    graphItemsExpansion,
    selectedAssignmentLibraryItems,
    draggingLibraryItems,
    setGraphItemsExpansion,
    setSelectedAssignmentLibraryItems,
    isDeletingNode,
    activeMatches,
    model,
    setSelectedExclusionLibraryItemId,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.libraryItems,
      state.graphItemsExpansion,
      state.selectedAssignmentLibraryItems,
      state.draggingLibraryItems,
      state.setGraphItemsExpansion,
      state.setSelectedAssignmentLibraryItems,
      state.isDeletingNode,
      state.activeMatches,
      state.model,
      state.setSelectedExclusionLibraryItemId,
    ]),
  );
  const [isOpen, setIsOpen] = useState(true);
  const prevItems = useRef<Array<DraggableLibraryItem>>();
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>('top');

  const handleOnAccordianChange = () => {
    setIsOpen((prev) => !prev);
    setGraphItemsExpansion(null);
  };

  useEffect(() => {
    if (graphItemsExpansion) {
      setIsOpen(graphItemsExpansion === 'expand');
    }
  }, [graphItemsExpansion]);

  useEffect(() => {
    if (prevItems.current?.length < items.length) {
      setIsOpen(true);
      setGraphItemsExpansion(null);
    }

    prevItems.current = items;
  }, [items]);

  useEffect(() => {
    if (itemIdsOnDevicePath?.length && !isOpen) {
      setIsOpen(true);
      setGraphItemsExpansion(null);
    }
  }, [itemIdsOnDevicePath]);

  useEffect(() => {
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        setScrollPosition('bottom');
      } else if (scrollTop === 0) {
        setScrollPosition('top');
      } else {
        setScrollPosition('middle');
      }
    };

    const container = scrollContainer.current;
    setIsOverflowing(container?.scrollHeight > container?.clientHeight);
    container?.addEventListener('scroll', handleScroll);

    return () => container?.removeEventListener('scroll', handleScroll);
  }, [items]);

  const getMaskImage = (scrollPosition: ScrollPosition) => {
    if (!isOverflowing) {
      return 'none';
    }

    switch (scrollPosition) {
      case 'top':
        return 'linear-gradient(to top, transparent 0%, black 5%)';
      case 'bottom':
        return 'linear-gradient(to top, black 95%, transparent 100%)';
      default:
        return 'linear-gradient(to top, transparent 0%, black 5%, black 95%, transparent 100%)';
    }
  };

  const onItemSelect = useCallback(
    (e, item) => {
      const { id, flowId } = item;
      if (!e.shiftKey) {
        setSelectedAssignmentLibraryItems({
          origin: 'graph',
          items: { [id]: { id, flowId } },
          lastItemClicked: [],
        });
        setSelectedExclusionLibraryItemId(null);
      }

      return null;
    },
    [setSelectedAssignmentLibraryItems, setSelectedExclusionLibraryItemId],
  );

  const itemsWithLiData = useMemo(
    () =>
      items
        .map((item) => {
          // TODO: When graph LI's are reliably on the FE, we can remove
          // this..I think.
          const libraryItem = getLibraryItem(item.data.id, libraryItems);
          if (!libraryItem) {
            return null;
          }

          return {
            ...libraryItem,
            flowId: item.data.flowId,
            origin: item.data.origin,
          };
        })
        .filter(Boolean),
    [libraryItems, items],
  );

  useEffect(() => {
    const hasActiveMatchesInside = itemsWithLiData.some((item) =>
      activeMatches.matches.some(
        (match) => match.item && match.item.flowId === item.flowId,
      ),
    );

    if (hasActiveMatchesInside && !isOpen) {
      setIsOpen(true);
      setGraphItemsExpansion(null);
    }
  }, [activeMatches]);

  if (itemsWithLiData?.length > 0) {
    const renderItems = itemsWithLiData?.map((item) => {
      const isDraggableItemDisabled = Boolean(
        draggingLibraryItems?.items.find(
          (active) => active.data.flowId === item.flowId,
        ),
      );
      const isItemOnDevicePath =
        !itemIdsOnDevicePath || itemIdsOnDevicePath?.includes(item.id);

      const isSelected =
        selectedAssignmentLibraryItems?.origin === 'graph' &&
        selectedAssignmentLibraryItems?.items[item.id]?.flowId === item.flowId;

      return (
        <DraggableItem
          key={item.flowId}
          item={item}
          isDisabled={isDraggableItemDisabled}
          isSelected={isSelected}
          isOnDevicePath={isItemOnDevicePath}
          onClick={!isDeletingNode ? onItemSelect : () => {}}
        />
      );
    });

    return (
      <Flex flow="column">
        <Accordion
          value={isOpen ? 'content' : ''}
          type="single"
          onValueChange={handleOnAccordianChange}
        >
          <AccordionItem value="content">
            <AccordionTrigger
              css={{
                margin: '0',
                padding: nodePadding,

                '&[data-state="open"] .flow-deck': {
                  display: 'none',
                },

                '& .stateIcon:not(.stateIcon-custom)': {
                  display: 'none',
                },
              }}
            >
              <Flex flex="1" alignItems="center" justifyContent="space-between">
                <Flex gap="sm">
                  <Text css={{ lineHeight: statementLineHeight }}>
                    assign ({items.length})
                  </Text>
                  <Deck items={items} size={10} />
                </Flex>
                <Flex
                  className="stateIcon stateIcon-custom"
                  css={{
                    margin: '1px -$2 0',
                  }}
                >
                  <Icon name="angle-up" size="sm" />
                </Flex>
              </Flex>
            </AccordionTrigger>

            <AccordionContent>
              <Flex
                ref={scrollContainer}
                className={isOverflowing ? 'nowheel' : ''}
                flow="column"
                css={{
                  marginTop: '-$1',
                  padding: `8px ${nodePadding} ${nodePadding}`,
                  gap: '2px',

                  ...(isScrollable && {
                    maxHeight: '1240px', // Enough for 20.5 Library Items to show
                    overflow: 'auto',
                    maskImage: getMaskImage(scrollPosition),
                  }),
                }}
                onMouseEnter={() => setIsHoveringOverLibraryItems?.(true)}
                onMouseLeave={() => setIsHoveringOverLibraryItems?.(false)}
              >
                {renderItems}
              </Flex>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </Flex>
    );
  }

  return null;
}

export default memo(Items);
