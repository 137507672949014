import {
  Button,
  modal as Modal,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { bool, func, oneOf, string } from 'prop-types';
import React from 'react';

import { i18n } from 'i18n';
import successTick from '../../../../common-assets/success-tick.svg';
import { INT_TYPES } from '../../../../constants';

const GREETING_TEXT = {
  [INT_TYPES.gsuite]:
    'You can view details, sync users and manage this integration on the Integrations ' +
    'overview page. All users imported through this directory will display in the Users' +
    ' table and will be available to assign to devices.',
  [INT_TYPES.office365]:
    'You can view details, sync users and manage this integration on the Integrations' +
    ' overview page. All users imported through this directory will display in the ' +
    'Users table and will be available to assign to devices.',
};

function UserIntCreationSuccessModal({ name, type, isOpen, onClose }) {
  function closeCompletely() {
    onClose();
    if (name.length) {
      toaster(
        i18n.t('{name} has been added to your integrations library.', { name }),
      );
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeCompletely}
      withoutCloseButton
      parts={{
        content: {
          children: (
            <>
              <img src={successTick} alt="success-tick" className="b-mb2" />
              <h1 className="b-h1 b-mb1">{name} was successfully connected.</h1>
              <p className="b-txt-light">{GREETING_TEXT[type] || ''}</p>
            </>
          ),
          className: 'cmn-modal-content_size',
        },
        footer: {
          className: 'cmn-modal_footer--narrow',
          children: <Button onClick={closeCompletely}>Done</Button>,
        },
      }}
    />
  );
}

UserIntCreationSuccessModal.propTypes = {
  name: string,
  type: oneOf([INT_TYPES.gsuite, INT_TYPES.office365]),
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

UserIntCreationSuccessModal.defaultProps = {
  name: '',
  type: null,
};

export default UserIntCreationSuccessModal;
