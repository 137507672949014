import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MsDeviceComplianceInputs } from '../../integrator/types/ms-device-compliance.types';
import MsDeviceComplianceService, {
  msDeviceComplianceService,
} from '../services';
import { msDeviceComplianceKeys } from './query-key-factory';

const useAddDevicePlatform = (integrationId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (formInputs: MsDeviceComplianceInputs) => {
      await msDeviceComplianceService.setGroupPolicies(
        integrationId,
        formInputs,
      );
    },
    onSuccess: () => {
      // refetch the integrations from maint-backend whenever we delete this integration
      queryClient.invalidateQueries({
        queryKey: msDeviceComplianceKeys.integrationDetails(integrationId),
      });
    },
  });
};

export default useAddDevicePlatform;
