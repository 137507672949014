import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import type { VulnerabilityState } from '../../store';

const addDay = (dateString: string) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  return date.toISOString();
};

const removeTimestamp = (dateString: string) => dateString.split('T')[0];

export const transformDetectionDateFilterToApi = (
  dateFilter: DetectionDateFilterFields,
) => {
  if (!dateFilter) {
    return null;
  }

  const { value, operator, dateRangeFrom, dateRangeTo, selectedDate } =
    dateFilter;

  if (!value || value === 'all_time') {
    return null;
  }

  // Note: we are currently removing timestamps to account for the fact that the BE does not have timestamps.
  // We should remove the `removeTimestamp` function when the BE supports timestamps.
  if (operator) {
    if (operator === 'ib') {
      return {
        gte: removeTimestamp(dateRangeFrom),
        lte: removeTimestamp(dateRangeTo),
      };
    }

    if (operator === 'eq') {
      return {
        gte: removeTimestamp(selectedDate),
        lt: removeTimestamp(addDay(selectedDate)),
      };
    }

    return { [operator]: removeTimestamp(selectedDate) };
  }

  return { relative: value };
};

export const transformAllVulnerabilitiesFilterToApi = (
  filter: VulnerabilityState['allVulnerabilitiesFilter'],
) => {
  const { severity, firstDetected, latestDetected } = filter;

  return {
    severity: { in: severity.length ? severity : null },
    first_detection_date: transformDetectionDateFilterToApi(firstDetected),
    latest_detection_date: transformDetectionDateFilterToApi(latestDetected),
  };
};

export const transformDeviceVulnerabilitiesFilterToApi = (
  filter: VulnerabilityState['deviceVulnerabilitiesFilter'],
) => {
  const { severity, detectionDate } = filter;

  return {
    severity: { in: severity },
    detection_date: transformDetectionDateFilterToApi(detectionDate),
  };
};

export const transformVulnerableDevicesFilterToApi =
  /* istanbul ignore next */ (filter: {
    detectionDate: DetectionDateFilterFields;
    blueprints: string[];
  }) => {
    const { detectionDate, blueprints } = filter;

    return {
      detection_datetime: transformDetectionDateFilterToApi(detectionDate),
      blueprint_id: { in: blueprints.length ? blueprints : null },
    };
  };
