/* Adding tests later since this is only a placeholder */
/* istanbul ignore file */
import { styled } from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useBodyNoMargin } from 'src/hooks/useBodyNoMargin';
import { useHubspotHandler } from 'src/hooks/useHubspotHandler';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { ENROLLMENT_HEADER_OFFSET } from '../ADEListView/ADEListView';
import { ManualEnrollmentPortal } from './ManualEnrollmentPortal';
import { ManualEnrollmentSingle } from './ManualEnrollmentSingle';
import { useGetAuthBlueprints } from './hooks/useGetAuthBlueprints';
import { useGetAuthConnectors } from './hooks/useGetAuthConnectors';

const ManualEnrollmentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${ENROLLMENT_HEADER_OFFSET}px)`,
  width: '100%',
  padding: '$4 $5 24px $5',
  overflow: 'auto',
  gap: '$5',
});

interface ManualEnrollmentProps {
  enrollmentPortalActive: boolean;
  toggleEnrollmentPortalStatus: ({
    is_enrollment_portal_active,
  }: {
    is_enrollment_portal_active: boolean;
  }) => Promise<any>;
}

export function ManualEnrollment({
  enrollmentPortalActive,
  toggleEnrollmentPortalStatus,
}: ManualEnrollmentProps) {
  const { data: authBlueprintData, refetch: refetchBlueprints } =
    useGetAuthBlueprints();
  const { data: authConnectorData } = useGetAuthConnectors();

  const authBlueprints = authBlueprintData ?? [];
  const authConnections = authConnectorData ?? [];

  useBodyNoMargin();
  useWhiteBackground();
  useHubspotHandler();

  return (
    <ManualEnrollmentContainer>
      <ManualEnrollmentSingle
        key={authBlueprints[0]?.name}
        blueprints={authBlueprints}
      />
      <ManualEnrollmentPortal
        enrollmentPortalActive={enrollmentPortalActive}
        toggleEnrollmentPortalStatus={toggleEnrollmentPortalStatus}
        authBlueprints={authBlueprints}
        authConnections={authConnections}
        refetchBlueprints={refetchBlueprints}
      />
    </ManualEnrollmentContainer>
  );
}
