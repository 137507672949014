import { getSelectedDevicesFromRunsOn } from '../../data-service/library-item/devices';
import SoftwareUpdateService from './service/software-update-service';

export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  devices: getSelectedDevicesFromRunsOn({
    runs_on_mac: true,
    runs_on_iphone: true,
    runs_on_ipad: true,
  }),

  modernConfigurations: {
    // Automatic Software Updates
    Download: SoftwareUpdateService.ALLOWED_OPTIONS.allowed.value,
    InstallOSUpdates: SoftwareUpdateService.ALLOWED_OPTIONS.allowed.value,
    InstallSecurityUpdate: SoftwareUpdateService.ALLOWED_OPTIONS.allowed.value,

    AutomaticCheckEnabled: true,
    AutomaticallyInstallAppUpdates: true,

    // Rapid Security Responses
    Enable: true,
    EnableRollback: true,

    // Defer iOS
    deferiOsUpdates: false,
    CombinedPeriodInDays: 14,
    RecommendedCadence:
      SoftwareUpdateService.RECOMMENDED_CADENCE_OPTIONS.all.value,

    // Defer macOS
    deferMacOSUpdates:
      SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.doNotDefer.value,

    // Defer all
    enforcedSoftwareUpdateDelay: 14,

    // Defer by type
    deferMajorMacOsUpgrades: false,
    enforcedSoftwareUpdateMajorOSDeferredInstallDelay: 14,
    deferMinorMacOsUpgrades: false,
    enforcedSoftwareUpdateMinorOSDeferredInstallDelay: 14,
    deferSystemUpdates: false,
    enforcedSoftwareUpdateNonOSDeferredInstallDelay: 14,

    // Other settings
    Notifications: SoftwareUpdateService.NOTIFICATIONS_OPTIONS.always.value,
    'restrict-software-update-require-admin-to-install': false,
    AllowPreReleaseInstallation: true,

    invalidationsMap: {},
  },

  supportsRules: true,
};
