import {
  Badge,
  Button,
  DropdownMenu,
  FilterButton,
  Flex,
  type IconNames,
  MultiSelect,
  Text,
  TextField,
  theme,
} from '@kandji-inc/nectar-ui';
import React from 'react';
import {
  deviceTypes,
  installTypes,
  updateOnlyIconName,
} from 'src/features/library-items/library/common';
import type { LibraryItemFilterSort } from '../../blueprint-flow.types';
import { libraryItemSortOptions } from '../../constants';

export type Filter = {
  term: string;
  libraryItemType: string[];
  status: string[];
  device: string[];
  installation: string[];
  sort: LibraryItemFilterSort;
};

export const defaultFilter: Filter = {
  term: '',
  libraryItemType: [],
  status: [],
  device: [],
  installation: [],
  sort: 'li_type_az',
};

export type FilterBarProps = {
  filter: Filter;
  onChange: <K extends keyof Filter>(field: K, value: Filter[K]) => void;
  libraryItemTypeOptions: Array<{
    section: string;
    showSectionLabel: boolean;
    options: Array<{ label: string; value: string }>;
  }>;
};

const FilterBar = (props: FilterBarProps) => {
  const { filter, onChange, libraryItemTypeOptions } = props;

  const statusOptions = [
    {
      value: 'successCount',
      icon: 'circle-check' as IconNames,
      color: theme.colors.green50.value,
      label: 'Success',
      theme: 'success',
    },
    {
      value: 'failingCount',
      icon: 'octagon-exclamation' as IconNames,
      color: theme.colors.red50.value,
      label: 'Error',
    },
    {
      value: 'pendingCount',
      icon: 'octagon-minus' as IconNames,
      color: theme.colors.neutral60.value,
      label: 'Other',
    },
  ];

  const deviceFamilyOptions = [
    {
      label: deviceTypes.IPHONE,
      value: 'runsOnIphone',
      icon: 'sf-iphone' as IconNames,
    },
    {
      label: deviceTypes.IPAD,
      value: 'runsOnIpad',
      icon: 'sf-ipad-landscape' as IconNames,
    },
    {
      label: deviceTypes.MAC,
      value: 'runsOnMac',
      icon: 'sf-desktop-computer' as IconNames,
    },
    {
      label: deviceTypes.TV,
      value: 'runsOnTv',
      icon: 'sf-apple-tv' as IconNames,
    },
  ];

  const installationOptions = [
    {
      label: 'Self Service',
      value: 'isSelfService',
      icon: 'kandji-logo' as IconNames,
    },
    {
      label: 'Continuously Enforce',
      value: installTypes.CONTINUOUS,
      icon: 'infinity' as IconNames,
    },
    {
      label: 'Install Once',
      value: installTypes.ONCE,
      icon: 'install-once-16px' as IconNames,
    },
    {
      label: 'Update Only',
      value: 'update_only',
      icon: updateOnlyIconName as IconNames,
    },
  ];
  const libraryItemTypeOptionsCount = libraryItemTypeOptions.reduce(
    (a, c) => a + c.options.length,
    0,
  );

  const getFilterBadge = (filterValue: Array<string>, max: number) => {
    if (filterValue.length > 1 && filterValue.length !== max) {
      return (
        <Badge
          compact
          css={{
            color: '$neutral0',
            backgroundColor: '$blue50',
            marginLeft: '$1',
          }}
        >
          +{filterValue.length - 1}
        </Badge>
      );
    }

    return null;
  };

  const statusIconCss = statusOptions.reduce(
    (a, c) => ({
      ...a,
      [`& svg[name='${c.icon}']`]: {
        color: c.color,
      },
    }),
    {},
  );

  return (
    <Flex pr5 pl5 alignItems="center">
      <Flex pt3 pb3 alignItems="center" flex="1" gap="sm" wrap="wrap">
        <TextField
          compact
          placeholder="Search Library Item title or type"
          icon="magnifying-glass"
          value={filter.term}
          showClearButton={Boolean(filter.term)}
          onClear={() => onChange('term', '')}
          onChange={(e) => onChange('term', e.target.value)}
          css={{ width: '256px' }}
          data-testid="am-li-search"
        />
        <MultiSelect
          multi
          selectAll={{ selectAllLabel: 'All Library Item Types' }}
          options={libraryItemTypeOptions}
          value={filter.libraryItemType}
          onChange={(selected) => onChange('libraryItemType', selected)}
          footer={{
            showClear: true,
            clearLabel: 'Clear',
            clearDisabled: !filter.libraryItemType.length,
            handleClear: () => onChange('libraryItemType', []),
          }}
        >
          <FilterButton
            filtersSelected={filter.libraryItemType.length > 0}
            showRemove={false}
            aria-label="li-type"
          >
            <Flex flow="row" alignItems="center" gap="xs">
              <Text>{`Library item type${
                filter.libraryItemType.length ? ': ' : ''
              }`}</Text>
              {Boolean(filter.libraryItemType.length) && (
                <Text
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: '$medium',
                  }}
                >
                  {filter.libraryItemType.length === libraryItemTypeOptionsCount
                    ? 'All'
                    : filter.libraryItemType[0]}
                </Text>
              )}
              {getFilterBadge(
                filter.libraryItemType,
                libraryItemTypeOptionsCount,
              )}
            </Flex>
          </FilterButton>
        </MultiSelect>

        <MultiSelect
          multi
          options={statusOptions}
          value={filter.status}
          onChange={(selected) => onChange('status', selected)}
          footer={{
            showClear: true,
            clearLabel: 'Clear',
            clearDisabled: !filter.status.length,
            handleClear: () => onChange('status', []),
          }}
          componentCss={{
            option: statusIconCss,
          }}
        >
          <FilterButton
            filtersSelected={filter.status.length > 0}
            showRemove={false}
            aria-label="li-status"
          >
            <Flex flow="row" alignItems="center" gap="xs">
              <Text>{`Status${filter.status.length ? ': ' : ''}`}</Text>
              {Boolean(filter.status.length) && (
                <Text
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: '$medium',
                  }}
                >
                  {filter.status.length === statusOptions.length
                    ? 'All'
                    : statusOptions.find(
                        ({ value }) => value === filter.status[0],
                      ).label}
                </Text>
              )}
              {getFilterBadge(filter.status, statusOptions.length)}
            </Flex>
          </FilterButton>
        </MultiSelect>

        <MultiSelect
          multi
          options={deviceFamilyOptions}
          value={filter.device}
          onChange={(selected) => onChange('device', selected)}
          footer={{
            showClear: true,
            clearLabel: 'Clear',
            clearDisabled: !filter.device.length,
            handleClear: () => onChange('device', []),
          }}
        >
          <FilterButton
            filtersSelected={filter.device.length > 0}
            showRemove={false}
            aria-label="li-device"
          >
            <Flex flow="row" alignItems="center" gap="xs">
              <Text>{`Device family${filter.device.length ? ': ' : ''}`}</Text>
              {Boolean(filter.device.length) && (
                <Text
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: '$medium',
                  }}
                >
                  {filter.device.length === deviceFamilyOptions.length
                    ? 'All'
                    : deviceFamilyOptions.find(
                        ({ value }) => value === filter.device[0],
                      ).label}
                </Text>
              )}
              {getFilterBadge(filter.device, deviceFamilyOptions.length)}
            </Flex>
          </FilterButton>
        </MultiSelect>

        <MultiSelect
          multi
          options={installationOptions}
          value={filter.installation}
          onChange={(selected) => onChange('installation', selected)}
          footer={{
            showClear: true,
            clearLabel: 'Clear',
            clearDisabled: !filter.installation.length,
            handleClear: () => onChange('installation', []),
          }}
        >
          <FilterButton
            filtersSelected={filter.installation.length > 0}
            showRemove={false}
            aria-label="li-installation"
          >
            <Flex flow="row" alignItems="center" gap="xs">
              <Text>{`Installation type${
                filter.installation.length ? ': ' : ''
              }`}</Text>
              {Boolean(filter.installation.length) && (
                <Text
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: '$medium',
                  }}
                >
                  {filter.installation.length === installationOptions.length
                    ? 'All'
                    : installationOptions.find(
                        ({ value }) => value === filter.installation[0],
                      ).label}
                </Text>
              )}
              {getFilterBadge(filter.installation, installationOptions.length)}
            </Flex>
          </FilterButton>
        </MultiSelect>

        <DropdownMenu
          contentProps={{ align: 'start' }}
          css={{
            zIndex: 10,
            "& [role='menuitem']:has(svg)": {
              backgroundColor: '$dropdown_surface_selected_enabled',
              color: '$blue50',
            },
          }}
          options={libraryItemSortOptions.map((opt) => ({
            ...opt,
            icon: filter.sort === opt.value ? 'fa-check-14px' : undefined,
            iconPosition: 'right',
            onClick: () => onChange('sort', opt.value),
          }))}
        >
          <Button
            compact
            data-testid="li-sort"
            variant="subtle"
            icon={{ name: 'arrow-down-arrow-up' }}
            css={{ '& svg': { width: '20px', height: '20px' } }}
          />
        </DropdownMenu>
      </Flex>
    </Flex>
  );
};

export default FilterBar;
