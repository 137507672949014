/* istanbul ignore file */
import {
  Badge,
  Box,
  Flex,
  Heading,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';
import { useReactFlow } from 'reactflow';
import { singularizeName } from 'src/features/library-items/library/common';
import { useShallow } from 'zustand/react/shallow';
import {
  HOTFIX__hasConflictingItemInMap,
  getLibraryItem,
  pluralizeWord,
} from '../../helpers';
import useBlueprintFlow from '../../store';
import { FlowTippy, TippyContainer } from '../../theme';

const ExclusionsBadge = () => {
  const [
    model,
    items,
    setSelectedExclusionLibraryItemId,
    isEditingAssignments,
    clearSelectedAssignmentLibraryItems,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.model,
      state.libraryItems,
      state.setSelectedExclusionLibraryItemId,
      state.isEditingAssignments,
      state.clearSelectedAssignmentLibraryItems,
    ]),
  );
  const { getNodes } = useReactFlow();
  const [isOpen, setIsOpen] = useState(false);

  const hasConflictingItemInMap = (item) =>
    HOTFIX__hasConflictingItemInMap(item, getNodes(), items);

  const totalExclusions = model.library_item_exclusions?.reduce(
    (a, c) => c.computer_ids.length + a,
    0,
  );

  if (!items || items.length === 0 || !totalExclusions) {
    return null;
  }

  return (
    <Flex css={{ position: 'absolute', top: '65px', left: '10px' }}>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger>
          <Badge color="yellow" icon="hexagon-xmark">
            {totalExclusions} manual device{' '}
            {pluralizeWord('exclusion', totalExclusions)} in place
          </Badge>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          css={{
            zIndex: '$max',
            boxShadow: '$elevation2',
            padding: '12px 0',
            borderRadius: '4px',
            background: '#fff',
            minWidth: '320px',
            '& > div:last-child > span': {
              left: '0 !important',
            },
          }}
        >
          <Flex flow="column" gap="sm">
            <Text size="1" css={{ padding: '0 16px' }}>
              Manual device exclusions exist on the following Library Items in
              this map:
            </Text>
            {model.library_item_exclusions.map(
              ({ library_item_id, computer_ids }) => {
                const item = getLibraryItem(library_item_id, items);
                const hasConflictInMap = hasConflictingItemInMap(item);
                const numOfExclusions = computer_ids.length;

                return (
                  <Flex
                    alignItems="center"
                    flex="1"
                    css={{
                      padding: '6px 16px',
                      gap: '9px',
                      '&:hover': {
                        background: '$dropdown_surface_hover',
                      },
                    }}
                    onClick={() => {
                      setSelectedExclusionLibraryItemId(item.id);
                      clearSelectedAssignmentLibraryItems();
                      setIsOpen(false);
                    }}
                  >
                    <img
                      style={{ width: '20px', height: '20px' }}
                      src={item.icon || item.defaultConfiguration.icon}
                      alt="Icon"
                      data-testid="library-item-icon"
                    />
                    <Box css={{ flex: 1, width: 0 }}>
                      <Flex alignItems="center">
                        <Heading
                          size="5"
                          css={{
                            lineHeight: '$2',
                            wordWrap: 'break-word',
                            fontWeight: 500,
                          }}
                        >
                          {item.name}
                        </Heading>

                        {hasConflictInMap && (
                          <FlowTippy
                            popperOptions={{ strategy: 'fixed' }}
                            content={
                              <TippyContainer gap="xs">{`Only one ${singularizeName(
                                item.defaultConfiguration.name,
                              )} Library Item may be assigned per device. Devices which qualify for more than one will receive whichever is scoped to them last (farthest right) on the map.`}</TippyContainer>
                            }
                          >
                            <Flex
                              data-testid="scli-icon"
                              css={{ marginLeft: '$1' }}
                            >
                              <Icon name="square-stack-slash-fill" size="sm" />
                            </Flex>
                          </FlowTippy>
                        )}
                      </Flex>

                      <Text
                        variant="description"
                        size="1"
                        css={{ fontWeight: '$medium', fontSize: '11px' }}
                      >
                        {item.defaultConfiguration.name}
                        {item.instanceName && ` - ${item.instanceName}`}
                      </Text>
                    </Box>

                    {numOfExclusions > 0 && (
                      <FlowTippy
                        popperOptions={{ strategy: 'fixed' }}
                        content={
                          <TippyContainer gap="xs">{`${numOfExclusions} manual device ${pluralizeWord('exclusion', numOfExclusions)} on this Library Item in this map.${/* istanbul ignore next */ isEditingAssignments ? ' Click to edit.' : ''}`}</TippyContainer>
                        }
                      >
                        <Flex css={{ marginLeft: '$1' }}>
                          <Badge
                            css={{ padding: '0px 5px 1px 4px' }}
                            compact
                            color="yellow"
                            icon="hexagon-xmark"
                          >
                            {numOfExclusions}
                          </Badge>
                        </Flex>
                      </FlowTippy>
                    )}
                  </Flex>
                );
              },
            )}
          </Flex>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default ExclusionsBadge;
