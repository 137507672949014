import { Tabs, Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  array,
  arrayOf,
  bool,
  elementType,
  func,
  node,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Prompt, useLocation } from 'react-router';

import { bulkFlushLibraryItemStatuses } from 'app/components/library/api';
import HubSpotHandler from 'components/common/hubspot-handler';
import { usePermissions } from 'contexts/account';
import { useHandlers } from 'features/library-items/common/library-item-page/library-item-page.hooks';
import { Library, Summary } from 'features/library-items/template';
import { i18n } from 'i18n';
import LibraryContext from '../../routes/library.context';
import { BlueprintConflictsModal } from '../blueprint-conflicts';
import FlushModal from '../flush-modal';
import Assignments from './assignments';
import { CancelEditingModal } from './cancel-editing-modal';
import { ConfirmNavigationModal } from './confirm-navigation-modal';
import DeleteModal from './delete-modal';
import DuplicateModal from './duplicate-modal';
import Header from './header';
import {
  getDeleteQuickAction,
  getDuplicateQuickAction,
  shouldShowWarningModal,
} from './helpers';
import Instance from './instance';
import SummaryInfo from './summary-info';
import WarningModal from './warning-modal';

export const LibraryItemContext = createContext(null);

const LibraryItemPage = (props) => {
  const {
    model,
    setModel,
    history,
    pageState,
    savedModel,
    blueprintConflicts,
    isEditable,
    isDeletable,
    crumb,
    blueprintOptions,
    defaultIcon,
    summaryInfoProps,
    withoutTabs,
    StatusTab,
    getStatusTabProps,
    ActivityTab,
    isNameEditable,
    isIconEditable,
    extraSummary,
    canHaveInstanceName,
    children,
    testId,
    extraBanners,
    facetMap,
    supportsInstallOn,
    supportsRules,
    supportsDuplication,
    customModal,
    customQuickActions,
  } = props;

  const { itemConfig } = useContext(LibraryContext);
  const loc = useLocation();
  const [isFlushModalOpen, setIsFlushModalOpen] = useState(false);
  const [isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen] =
    useState(false);
  const [isCancelEditingModalOpen, setIsCancelEditingModalOpen] =
    useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [userHasConfirmedNavigation, setUserHasConfirmedNavigation] =
    useState(false);

  const [nextLocation, setNextLocation] = useState(null);

  const [assignmentsAreValid, setAssignmentsAreValid] = useState(true);

  const onConfirmNavModalClose = () => setIsConfirmNavigationModalOpen(false);
  const onConfirmNavModalLeave = () => {
    setIsConfirmNavigationModalOpen(false);
    setUserHasConfirmedNavigation(true);
  };

  // A handler to manage the "Are you sure you want to leave?" modal
  const handleBlockedNavigation = (location) => {
    if (!userHasConfirmedNavigation && location.pathname !== loc.pathname) {
      setIsConfirmNavigationModalOpen(true);
      setNextLocation(location.pathname);
      return false;
    }
    return true;
  };

  // Redirect to the desired location when the user
  // confirms they would like to leave the page
  useEffect(() => {
    if (userHasConfirmedNavigation && nextLocation) {
      history.push(nextLocation);
    }
  }, [userHasConfirmedNavigation]);

  /* on mount, scroll to top */
  useEffect(() => document.body.scrollIntoView(), []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [pageState]);

  // Confirms with the user before unloading which is triggered
  // by a refreshing the page, closing the tab, or manually entering a new URL
  /* istanbul ignore next - this handles actions happening outside of the app */
  const handleBeforeUnload = (e) => {
    if (pageState.isEditing) {
      e.preventDefault();
      // Sets the browser flag to 'true', meaning that
      // it should show the default modal
      e.returnValue = '';
    }
    return '';
  };

  const { onSave, onRemove, onEdit, onCancel, onClose, onDuplicate } =
    useHandlers({ ...props, setIsFlushModalOpen });

  const onCancelEditingModalOpen = () => setIsCancelEditingModalOpen(true);
  const onCancelEditingModalClose = () => setIsCancelEditingModalOpen(false);
  const onCancelEditingModalConfirm = () => {
    setIsCancelEditingModalOpen(false);
    onCancel();
  };

  const onWarningModalOpen = () => setIsWarningModalOpen(true);
  const onWarningModalClose = () => setIsWarningModalOpen(false);
  const onWarningModalConfirm = async () => {
    try {
      await onSave();
    } catch (error) {
      console.error(error);
    }

    setIsWarningModalOpen(false);
  };

  const onDuplicateModalOpen = () => setIsDuplicateModalOpen(true);
  const onDuplicateModalClose = () => setIsDuplicateModalOpen(false);
  const onDuplicateModalConfirm = () => onDuplicate();

  const onDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const onDeleteModalClose = () => setIsDeleteModalOpen(false);
  const onDeleteModalConfirm = () => onRemove();

  const isShowWarningModal = shouldShowWarningModal(model, savedModel);

  // Update the `assignmentsAreValid` state with whether or not the Assignments fields are valid.
  // At least one device must be selected in the "Install on" field (if supported).
  useEffect(() => {
    const assignDevicesIsInvalid = supportsInstallOn && !model?.devices?.length;

    if (assignDevicesIsInvalid) {
      setAssignmentsAreValid(false);
    } else {
      setAssignmentsAreValid(true);
    }
  }, [model?.devices]);

  const validatedPageState = {
    ...pageState,
    isValid: pageState.isValid && assignmentsAreValid && !isWarningModalOpen,
  };

  const permissions = usePermissions();
  const quickActions = [
    ...customQuickActions,
    ...(supportsDuplication
      ? getDuplicateQuickAction(onDuplicateModalOpen, permissions)
      : []),
    ...(isDeletable
      ? getDeleteQuickAction(onDeleteModalOpen, permissions)
      : []),
  ];

  return (
    <LibraryItemContext.Provider value={{ onSave, onConfirmNavModalLeave }}>
      {/* <Prompt /> prevents redirect by nature, so we must account for the edge cases where the
      app initiates a redirect, such as adding a new library item and deleting an existing one. */}
      <Prompt
        when={
          pageState.isEditing && !pageState.isSaving && !pageState.isDeleting
        }
        message={handleBlockedNavigation}
      />
      {!!customModal && customModal({ onSave })}
      {blueprintConflicts && (
        <BlueprintConflictsModal
          blueprintConflicts={blueprintConflicts}
          blueprints={model.selectedBlueprints}
          isAllBlueprints={model.isAllBlueprints}
          itemId={model.id}
          itemName={model.name}
          itemTemplate={model.template}
          itemInstanceName={model.instanceName}
          itemTypeName={itemConfig.name}
        />
      )}
      {isFlushModalOpen && (
        <FlushModal
          onClose={() => setIsFlushModalOpen(false)}
          onFlush={async () => {
            try {
              await bulkFlushLibraryItemStatuses(model.id);
              setIsFlushModalOpen(false);
            } catch (error) {
              toaster(i18n.t('Failed to flush devices'));
            }
          }}
        />
      )}
      {isConfirmNavigationModalOpen && (
        <ConfirmNavigationModal
          onClose={onConfirmNavModalClose}
          onLeave={onConfirmNavModalLeave}
        />
      )}
      <CancelEditingModal
        isOpen={isCancelEditingModalOpen}
        onClose={onCancelEditingModalClose}
        onConfirm={onCancelEditingModalConfirm}
      />
      <WarningModal
        isSaving={pageState.isSaving}
        isOpen={isWarningModalOpen}
        onClose={onWarningModalClose}
        onConfirm={onWarningModalConfirm}
      />
      <DuplicateModal
        isOpen={isDuplicateModalOpen}
        onClose={onDuplicateModalClose}
        onConfirm={onDuplicateModalConfirm}
      />
      <DeleteModal
        itemName={model.name}
        itemType={model.type}
        isDeleting={pageState.isDeleting}
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
        onConfirm={onDeleteModalConfirm}
      />
      <Library testId={testId}>
        <div className="b-mt3 b-mb2">
          <Library.Breadcrumb
            crumbs={[{ title: 'LIBRARY', url: '/library' }, { title: crumb }]}
          />
        </div>
        <Library.InactiveBanner isVisible={!model.isActive}>
          This Library Item is inactive. Settings configured for this Library
          Item will not be deployed to devices within the assigned Blueprints
          while inactive.
        </Library.InactiveBanner>
        <Library.InactiveBanner
          isVisible={model.isSalable !== undefined && !model.isSalable}
        >
          This app was removed from the App Store and can no longer be installed
          on devices.
        </Library.InactiveBanner>
        {extraBanners}
        <Library.Summary>
          <Header
            icon={model.icon || model.iconSrc}
            defaultIcon={defaultIcon}
            isDisabled={pageState.isDisabled}
            name={model.name}
            isActive={model.isActive}
            isNameEditable={isNameEditable}
            isIconEditable={isIconEditable}
            setModel={setModel}
            isSubmitted={pageState.isSubmitted}
            placeholder={savedModel.name || 'Add a title'}
            isEditing={pageState.isEditing}
            quickActions={quickActions}
          />

          <Summary.Body>
            <SummaryInfo
              summaryInfoProps={summaryInfoProps}
              isDisabled={pageState.isDisabled}
            />
            {extraSummary}
            <Instance
              canHaveInstanceName={canHaveInstanceName}
              instanceName={model.instanceName || ''}
              isDisabled={pageState.isDisabled}
              setModel={setModel}
            />
            <Assignments
              isEditing={pageState.isEditing}
              isDisabled={pageState.isDisabled}
              history={history}
              blueprintOptions={blueprintOptions}
              devices={model.devices}
              selectedBlueprints={model.selectedBlueprints}
              excludedBlueprints={model.excludedBlueprints}
              isAllBlueprints={model.isAllBlueprints}
              setModel={setModel}
              setIsConfirmNavigationModalOpen={() =>
                handleBlockedNavigation({ pathname: '/blueprints' })
              }
              facetMap={facetMap}
              rules={model.rules}
              supportsRules={supportsRules}
              supportedDeviceFamilies={summaryInfoProps.devices}
              baseDeviceFamilies={summaryInfoProps.base_device_families}
              supportsInstallOn={supportsInstallOn}
            />
          </Summary.Body>
        </Library.Summary>

        <Tabs
          tabs={[
            { label: 'Settings' },
            ...(!pageState.isAdding && !withoutTabs
              ? [
                  { label: 'Status', route: 'status' },
                  { label: 'Activity', route: 'activity' },
                ]
              : []),
          ]}
          style={{ margin: '19px 0 var(--b-gap3)' }}
        >
          <Library.Settings tabid="Settings">
            <HubSpotHandler />
            {children}
            <Library.Actions
              pageState={validatedPageState}
              itemId={model.id}
              onEdit={onEdit}
              onDelete={(isDeletable && onDeleteModalOpen) || null}
              onSave={(isShowWarningModal && onWarningModalOpen) || onSave}
              onCancel={onCancelEditingModalOpen}
              onClose={onClose}
              isEditable={isEditable}
            />
          </Library.Settings>
          {!pageState.isAdding && !withoutTabs && (
            <StatusTab
              tabid="Status"
              {...getStatusTabProps(model, setModel, blueprintOptions)}
            />
          )}
          {!pageState.isAdding && !withoutTabs && (
            <ActivityTab
              tabid="Activity"
              itemId={model.id}
              noWrapper
              context={{}}
            />
          )}
        </Tabs>
      </Library>
    </LibraryItemContext.Provider>
  );
};

LibraryItemPage.propTypes = {
  model: object.isRequired,
  setModel: func.isRequired,
  history: object.isRequired,
  pageState: shape({
    isEditing: bool.isRequired,
    isDeleteModal: bool.isRequired,
    isDeleting: bool.isRequired,
    isSaving: bool.isRequired,
    isDisabled: bool.isRequired,
    isValid: bool.isRequired,
    isSubmitted: bool.isRequired,
    isAdding: bool.isRequired,
  }).isRequired,
  handlers: shape({
    onEdit: func,
    onSave: func,
    onCancel: func,
    onClose: func,
    onRemove: func,
    onValidate: func,
  }).isRequired,
  isEditable: bool.isRequired,
  isDeletable: bool,
  passportFilesToUpload: object,
  publicSelfServiceIconFilesToUpload: object,
  crumb: string.isRequired,
  restoreModel: func.isRequired,
  savedModel: object.isRequired,
  setSavedModel: func.isRequired,
  blueprintOptions: arrayOf(
    shape({
      value: string,
      label: string,
    }),
  ),
  triggerValidation: func.isRequired,
  defaultIcon: string,
  summaryInfoProps: shape({
    name: oneOfType([string, node]).isRequired,
    instanceName: string,
    description: node.isRequired,
    publisher: string.isRequired,
    devices: arrayOf(string),
    requirements: arrayOf(string),
    currentVersion: string,
  }).isRequired,
  withoutTabs: bool,
  StatusTab: elementType,
  getStatusTabProps: func,
  ActivityTab: elementType,
  service: object.isRequired,
  transformFromApi: func.isRequired,
  transformToApi: func.isRequired,
  blueprintConflicts: object, // if exist than check bp conflicts
  type: string, // need to check blueprint conflicts
  identifier: string, // need to check blueprint conflicts
  isNameEditable: bool,
  isIconEditable: bool,
  askFlush: func,
  extraSummary: node,
  canHaveInstanceName: bool,
  children: node.isRequired,
  testId: string,
  extraBanners: node,
  supportsInstallOn: bool,
  supportsRules: bool,
  supportsDuplication: bool,
  customModal: func,
  customQuickActions: array,
  facetMap: object,
};

LibraryItemPage.defaultProps = {
  isDeletable: true,
  blueprintOptions: null,
  publicSelfServiceIconFilesToUpload: {
    'selfService.iconFile.file': 'selfService.iconS3Key',
  },
  defaultIcon: null,
  withoutTabs: false,
  StatusTab: null,
  getStatusTabProps: () => ({}),
  ActivityTab: null,
  blueprintConflicts: null,
  type: null,
  identifier: null,
  isNameEditable: true,
  isIconEditable: false,
  askFlush: null,
  canHaveInstanceName: false,
  extraSummary: null,
  testId: null,
  extraBanners: null,
  supportsInstallOn: false,
  supportsRules: false,
  supportsDuplication: false,
  customModal: null,
  customQuickActions: [],
};

export default LibraryItemPage;
