import {
  Button,
  Checkbox,
  LogoUploader,
  Select,
  sidePanel as SidePanel,
  TextInput,
  setClass,
  useShadowOnScroll,
} from '@kandji-inc/bumblebee';
import type { ChangeEvent } from 'react';
import React, { useEffect, useReducer, useRef } from 'react';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import uuid from 'uuid/v4';

import type { ManageBookmarksProps } from '../../bookmarks.types';
import { bookmarkPreview, defaultBookmarkIcon, starIcon } from './assets';

type Props = {
  drawerStatus: ManageBookmarksProps['drawerStatus'];
  settings: ManageBookmarksProps['settings'];
  update: ManageBookmarksProps['update'];
  selectedBookmark: ManageBookmarksProps['settings']['bookmarks'][number];
  categoryOptions: Array<{
    value: string;
    label: string;
  }>;
};

const RECOMMENDED_ICON_SIZE_PX = 96;
export const DEFAULT_BOOKMARK_ICON = defaultBookmarkIcon;

const PREVIEW_STYLE = {
  defaultIcon: {
    position: 'absolute',
    height: '30px',
    width: '30px',
    top: '67px',
    right: '98px',
  },
  favicon: {
    position: 'absolute',
    height: '48px',
    width: '48px',
    top: '60px',
    right: '92px',
    borderRadius: '10px',
  },
  textContainer: {
    position: 'absolute',
    top: '63px',
    left: '90px',
    width: '260px',
  },
  urlText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '11px',
    whiteSpace: 'nowrap',
  },
  previewContainer: {
    position: 'relative',
  },
  previewBg: {
    width: '100%',
  },
} as const;

const NEW_BOOKMARK_ITEM = {
  url: '',
  title: '',
  iconDataUrl: null,
  iconSrc: null,
  category: null,
  isRecommended: false,
} as const;

/* istanbul ignore next */
function reducer(state, action) {
  switch (action.type) {
    case 'set_bookmark': {
      const { selectedBookmark } = action.payload;
      return selectedBookmark
        ? { ...selectedBookmark }
        : { ...NEW_BOOKMARK_ITEM, id: uuid() };
    }
    case 'update_bookmark': {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }
    case 'update_icons': {
      const { dataUrl, src, icon_url, icon_s3_key } = action.payload;
      return {
        ...state,
        iconDataUrl: dataUrl,
        iconSrc: src,
        ...(icon_url !== undefined ? { icon_url } : {}),
        ...(icon_s3_key !== undefined ? { icon_s3_key } : {}),
      };
    }
    default:
      return state;
  }
}

const Drawer = (props: Props) => {
  const {
    drawerStatus: [status, setStatus],
    settings,
    update,
    selectedBookmark,
    categoryOptions,
  } = props;

  const { bookmarks } = settings;
  const isVisible =
    status === 'open' || status === 'validating' || status === 'invalid';
  const isValidating = status === 'validating';
  const isShowInvalid = status === 'invalid';

  const { isShowHeaderShadow, setBody } = useShadowOnScroll() as unknown as {
    isShowHeaderShadow: boolean;
    setBody: (body: HTMLElement) => void;
  };
  const { current: invalidMap } = useRef(new Map());
  const [bookmarkItem, dispatch] = useReducer(reducer, NEW_BOOKMARK_ITEM);

  useEffect(() => {
    if (isVisible) {
      dispatch({ type: 'set_bookmark', payload: { selectedBookmark } });
    }
  }, [isVisible, selectedBookmark]);

  useEffect(() => {
    if (isValidating) {
      let firstInvalidRef: HTMLInputElement | null = null;

      invalidMap.forEach(({ isInvalid, ref }) => {
        if (typeof isInvalid === 'function') {
          const invalidRes = isInvalid(bookmarkItem);

          /* istanbul ignore next */
          if (invalidRes && !firstInvalidRef) {
            firstInvalidRef = ref;
          }
        } else if (isInvalid && !firstInvalidRef) {
          firstInvalidRef = ref;
        }
      });

      if (firstInvalidRef) {
        elementScrollIntoView(firstInvalidRef, {
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });

        setStatus('invalid');
      } else {
        if (selectedBookmark) {
          const newBookmarks = bookmarks.map((bookmark) =>
            bookmark.url === selectedBookmark.url ? bookmarkItem : bookmark,
          );
          update('bookmarks', newBookmarks);
        } else {
          update('bookmarks', [...bookmarks, bookmarkItem]);
        }

        invalidMap.clear();
        setStatus('closed');
      }
    }
  }, [
    isValidating,
    invalidMap,
    bookmarks,
    bookmarkItem,
    selectedBookmark,
    update,
    setStatus,
  ]);

  const requiredValidator = (v) => ({
    message: 'Required',
    invalid: () => {
      if (isValidating || isShowInvalid) {
        return !v;
      }
      return false;
    },
    trigger: [isValidating, isShowInvalid],
  });

  const uniqueUrlValidator = (v) => ({
    message: 'This URL is already a Bookmark in this Library Item',
    invalid: () =>
      bookmarks.some(
        (bookmark) => bookmark.url === v && bookmark?.id !== bookmarkItem?.id,
      ),
    trigger: [isValidating, isShowInvalid, 'onBlur'],
  });

  const handleDrawerDone = () => {
    setStatus('validating');
  };

  return (
    <SidePanel isVisible={isVisible}>
      <div className="b-side-panel-layout" data-testid="bookmarks-drawer">
        <div
          className={setClass(
            'b-side-panel-layout__header',
            isShowHeaderShadow && '--with-shadow',
          )}
        >
          <h2 className="b-h2">{selectedBookmark ? 'Edit' : 'Add'} Bookmark</h2>
        </div>
        <div
          ref={setBody}
          className="b-side-panel-layout__body b-mt-tiny"
          style={{
            overflow: 'auto',
          }}
        >
          <div
            ref={(r) =>
              invalidMap.set('url', {
                ref: r,
                isInvalid: false,
              })
            }
            className="b-mb3"
          >
            <div style={PREVIEW_STYLE.previewContainer}>
              <img
                className="b-mb2"
                src={bookmarkPreview}
                alt="Bookmark Preview"
                style={PREVIEW_STYLE.previewBg}
              />

              <img
                src={
                  bookmarkItem.icon_url ||
                  bookmarkItem.iconDataUrl ||
                  DEFAULT_BOOKMARK_ICON
                }
                alt="Bookmark icon preview"
                style={
                  bookmarkItem.iconDataUrl
                    ? PREVIEW_STYLE.favicon
                    : PREVIEW_STYLE.defaultIcon
                }
              />

              <div style={PREVIEW_STYLE.textContainer}>
                <div className="b-flex-gmicro">
                  <p className="b-txt-light">{bookmarkItem.title || 'Title'}</p>

                  {bookmarkItem.isRecommended && (
                    <img
                      className="k-ss2-preview-app-star"
                      src={starIcon}
                      alt="Recommended"
                    />
                  )}
                </div>

                <p className="b-txt-light" style={PREVIEW_STYLE.urlText}>
                  {bookmarkItem.url || 'https://'}
                </p>
              </div>
            </div>

            <p className="b-txt b-mb-micro">URL</p>

            <p className="b-txt-light b-mb1">
              Any URL scheme recognized by macOS can be used, including
              https://, mailto:, and file:/.
            </p>

            <TextInput
              data-testid="bookmark-url-field"
              value={bookmarkItem.url}
              placeholder="https://"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                dispatch({
                  type: 'update_bookmark',
                  payload: { key: 'url', value: e.target.value },
                })
              }
              validator={(v) => [requiredValidator(v), uniqueUrlValidator(v)]}
              onInvalidate={(isInvalid) =>
                invalidMap.set('url', {
                  ref: invalidMap.get('url').ref,
                  isInvalid,
                })
              }
            />
          </div>

          <div
            className="b-mb2"
            ref={(r) =>
              invalidMap.set('custom_icon', {
                ref: r,
                isInvalid: invalidMap?.get('custom_icon')?.isInvalid || false,
              })
            }
          >
            <p className="b-txt b-mb-micro">Icon</p>
            <p className="b-txt-light b-mb1">
              By default, we will use our bookmark icon, but you can also upload
              a custom image. A square {RECOMMENDED_ICON_SIZE_PX}x
              {RECOMMENDED_ICON_SIZE_PX} pixel PNG file with a transparent
              background is recommended.
            </p>

            <LogoUploader
              smallPreview
              icon={
                bookmarkItem.icon_url ||
                bookmarkItem.iconDataUrl ||
                DEFAULT_BOOKMARK_ICON
              }
              onSelect={(img) => {
                dispatch({
                  type: 'update_icons',
                  payload: {
                    dataUrl: img.dataUrl,
                    src: img.name,
                  },
                });
              }}
              onRemove={() => {
                invalidMap.set('custom_icon', {
                  ref: invalidMap.get('custom_icon').ref,
                  isInvalid: false,
                });

                dispatch({
                  type: 'update_icons',
                  payload: {
                    dataUrl: null,
                    src: null,
                    icon_url: null,
                    icon_s3_key: null,
                  },
                });
              }}
              canRemove={bookmarkItem.icon_url || bookmarkItem.iconDataUrl}
              validators={[
                // Image dimensions validation:
                /* istanbul ignore next */ (file, image) => {
                  if (!image) {
                    return null;
                  }

                  if (image.width < RECOMMENDED_ICON_SIZE_PX) {
                    invalidMap.set('custom_icon', {
                      ref: invalidMap.get('custom_icon').ref,
                      isInvalid: true,
                    });

                    return `Image should be at least ${RECOMMENDED_ICON_SIZE_PX} x ${RECOMMENDED_ICON_SIZE_PX} pixels`;
                  }

                  // Reset to be valid:
                  invalidMap.set('custom_icon', {
                    ref: invalidMap.get('custom_icon').ref,
                    isInvalid: false,
                  });

                  return null;
                },
                // File type validation:
                /* istanbul ignore next */ (file) => {
                  if (!file) {
                    return null;
                  }

                  if (!file?.type?.includes('png')) {
                    invalidMap.set('custom_icon', {
                      ref: invalidMap.get('custom_icon').ref,
                      isInvalid: true,
                    });

                    return 'Image needs to be in PNG format';
                  }

                  // Reset to be valid:
                  invalidMap.set('custom_icon', {
                    ref: invalidMap.get('custom_icon').ref,
                    isInvalid: false,
                  });

                  return null;
                },
              ]}
              runValidatorsOn={[bookmarkItem.dataUrl]}
            />
          </div>

          <div
            ref={(r) =>
              invalidMap.set('title', {
                ref: r,
                isInvalid: false,
              })
            }
          >
            <p className="b-txt b-mb1">Title</p>

            <TextInput
              data-testid="bookmark-title-field"
              value={bookmarkItem.title}
              // placeholder=""
              maxLength={32}
              fieldsGrid="k-ss2-input-grid"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                dispatch({
                  type: 'update_bookmark',
                  payload: { key: 'title', value: e.target.value },
                })
              }
              validator={(v) => [requiredValidator(v)]}
              onInvalidate={(isInvalid) =>
                invalidMap.set('title', {
                  ref: invalidMap.get('title').ref,
                  isInvalid,
                })
              }
            />
          </div>

          <div className="b-mb2">
            <p className="b-txt b-mb1">Category</p>

            <Select
              inputId="bookmark-category-select"
              value={categoryOptions.find(
                (cat) => cat.value === bookmarkItem.category,
              )}
              options={categoryOptions}
              placeholder="Assign a category"
              onChange={(selected) =>
                dispatch({
                  type: 'update_bookmark',
                  payload: { key: 'category', value: selected.value },
                })
              }
              errorText={
                isShowInvalid &&
                invalidMap.get('category')?.isInvalid(bookmarkItem)
                  ? 'Required'
                  : ''
              }
            />
          </div>

          <div>
            <Checkbox
              testId="recommended-checkbox"
              label="Recommended"
              checked={bookmarkItem.isRecommended}
              onChange={() =>
                dispatch({
                  type: 'update_bookmark',
                  payload: {
                    key: 'isRecommended',
                    value: !bookmarkItem.isRecommended,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="b-side-panel-layout__footer">
          <div className="b-flex-justify-end">
            <div className="b-grid-ctas">
              <Button
                kind="outline"
                onClick={() => {
                  invalidMap.clear();
                  setStatus('closed');
                }}
              >
                Close
              </Button>
              <Button onClick={handleDrawerDone}>Done</Button>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
  );
};

export default React.memo(Drawer);
