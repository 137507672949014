import {
  Button,
  Radio,
  TextInput,
  Toggle,
  Toaster as toaster,
  useInputsValidators,
} from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React, { useEffect } from 'react';
import { useRemovableCustomOrDefaultValue } from '../hooks';

const DescriptionWithDefault = (props) => {
  const {
    label,
    helper,
    placeholder,
    fieldName,
    maxLength,
    bottomSlot,
    update,
    defaults,
    setting,
    needReset,
    triggerValidate,
  } = props;

  const { onInvalidate, refs } = useInputsValidators([fieldName], update);

  const [isDefault, setIsDefault, isHidden, setIsnHidden] =
    useRemovableCustomOrDefaultValue(
      defaults[fieldName],
      setting[fieldName],
      (v) => update(fieldName, v),
      needReset,
    );
  useEffect(() => {
    onInvalidate(0)(!isHidden && !isDefault && !setting[fieldName]);
  }, [isHidden, isDefault]);

  return (
    <div className="k-ss2-drawer-setting">
      <div className="k-ss2-drawer-setting-with-toggle">
        <div>
          <p className="b-txt"> {label} </p>
          <p className="b-txt-light"> {helper} </p>
        </div>
        <div>
          <Toggle
            checked={!isHidden}
            onToggle={() => setIsnHidden(!isHidden)}
          />
        </div>
      </div>
      {!isHidden && (
        <>
          <div className="k-ss2-drawer-setting-extra1">
            <div className="b-grid-controls">
              <Radio
                checked={isDefault}
                onChange={() => setIsDefault(true)}
                label="Display provided description"
              />
              <Radio
                checked={!isDefault}
                onChange={() => setIsDefault(false)}
                label="Display custom description"
              />
            </div>
            <div
              ref={refs[fieldName]}
              className="b-mt2"
              style={{ position: 'relative' }}
            >
              <TextInput
                textArea
                maxLength={maxLength}
                fieldsGrid="k-ss2-input-grid"
                disabled={isDefault}
                value={setting[fieldName] || ''}
                validator={(v) => [
                  {
                    message: 'Selected option is a required field.',
                    invalid: () => !isDefault && !v,
                    trigger: ['onBlur', triggerValidate],
                  },
                ]}
                onInvalidate={onInvalidate(fieldName)}
                onChange={(e) => update(fieldName, e.target.value)}
                placeholder={placeholder}
              />
              {isDefault && (
                <Button
                  style={{ position: 'absolute', bottom: 0, left: 0 }}
                  kind="link"
                  icon="copy"
                  size="small"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(defaults[fieldName])
                      .then(() => toaster(i18n.t('Copied to clipboard.')))
                      .catch(() =>
                        toaster(i18n.t('Could not copy to clipboard.')),
                      );
                  }}
                >
                  Copy
                </Button>
              )}
            </div>
          </div>
          {bottomSlot}
        </>
      )}
    </div>
  );
};

export default DescriptionWithDefault;
