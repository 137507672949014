import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import { bool, func } from 'prop-types';
import React from 'react';

import { i18n } from 'i18n';
import { TYPE_PROP_TYPE } from '../../../../constants';
import { useCreateIntegration } from '../../hooks/use-create-integration';
import eventNotifications from '../assets/event-notifications-icon.svg';
import realTimeUpd from '../assets/real-time-upd-icon.svg';

function FooterButtons({ isAuthorizing, updateField, onClose, type }) {
  const createIntegrationMutation = useCreateIntegration();
  return (
    <>
      <Button kind="outline" onClick={onClose} className="b-mr">
        Cancel
      </Button>

      <Button
        onClick={() => {
          updateField('isAuthorizing', true);
          createIntegrationMutation.mutate(type, {
            onSuccess: (res) => {
              window.location.href = res.data.redirect_url;
            },
            onError: () => {
              toaster(i18n.common.error());
              updateField('isAuthorizing', false);
              onClose();
            },
          });
        }}
        disabled={isAuthorizing}
        isProgress={isAuthorizing}
      >
        Get started
      </Button>
    </>
  );
}

FooterButtons.propTypes = {
  isAuthorizing: bool.isRequired,
  updateField: func.isRequired,
  onClose: func.isRequired,
  type: TYPE_PROP_TYPE.isRequired,
};

function getTickets(title, supportSection = '') {
  return [
    {
      text:
        'Provides real-time status updates on devices and' +
        ` delivers them directly to your ${title} account.`,
      title: 'Real-time status updates',
      link: `https://support.kandji.io/${supportSection}`,
      icon: realTimeUpd,
    },
    {
      text:
        'Customize notifications based on certain event-based triggers and ' +
        'specify which channels you would like to be notified on.',
      title: 'Event-driven notifications',
      link: `https://support.kandji.io/${supportSection}`,
      icon: eventNotifications,
    },
  ];
}

function getDescription(title) {
  return (
    `Establishing an integration with the ${title} ` +
    'messaging and communications platform allows you to receive' +
    ' direct device activities that occur in the Kandji instance.'
  );
}

const INITIAL_STATE = { isAuthorizing: false };

export { FooterButtons, getTickets, INITIAL_STATE, getDescription };
