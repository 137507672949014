/* istanbul ignore file - legacy code */
import { i18n } from 'i18n';
import featureFlags from 'src/config/feature-flags';
import {
  apiToDevice,
  apiTypes,
  canAddLibraryTypes,
  categories,
  deviceTypes,
  getItemCategory,
  osPrefixes,
  publishers,
} from '../common';
import allLibraryItemsConfigurations, {
  libraryItemsToAdd,
} from '../library-item-configurations';

const getItemConfig = (item) =>
  Object.values(allLibraryItemsConfigurations).find((d) => {
    if (d.type === apiTypes.PROFILE) {
      return d.type === item.type && d.identifier === item.identifier;
    }
    return d.type === item.type;
  });

const transformItem = (item) => {
  const hasLicenses = item.licenses_total !== null;
  const itemConfig = getItemConfig(item);

  if (!itemConfig) {
    console.error('ITEM WITH NO CONFIGURATION', item);
  }

  const devices = Object.keys(apiToDevice)
    .filter((deviceKey) => item[deviceKey])
    .map((deviceKey) => apiToDevice[deviceKey]);

  const getFormattedItemName = (itemConfig) => {
    const { type, name } = itemConfig;
    if (type === apiTypes.PROFILE) {
      const profileType = name === 'Custom Profile' ? 'Custom' : name;
      return i18n.t(`{profileType} Profile`, {
        profileType,
        _context:
          'type of an Apple configuration profile to install on a device',
      });
    }
    switch (type) {
      case apiTypes.KANDJI_SETUP:
      case apiTypes.KANDJI_LOGIN:
      case apiTypes.DEP_PROFILE:
      case apiTypes.SSH:
      case apiTypes.RECOVERY_PASSWORD:
      case apiTypes.DEVICE_NAME:
      case apiTypes.WALLPAPER:
      case apiTypes.THREAT_SECURITY_POLICY:
      case apiTypes.DEVICE_CONTROL:
        return itemConfig.getName();
      default:
        return '';
    }
  };

  return {
    ...item,
    licenses: hasLicenses && {
      used: item.licenses_used || 0,
      total: item.licenses_total,
    },
    devices: devices.length
      ? devices
      : itemConfig?.devices || [deviceTypes.MAC],
    icon: item.icon || itemConfig?.icon,
    backupIcon: itemConfig.icon,
    defaultConfiguration: itemConfig,
    getUrl: itemConfig.getUrl,
    getAddUrl: itemConfig.getAddUrl,
    isSelfServiceEnabled: item.is_self_service,
    selfServiceInstallType: item.install_enforcement,
    shouldShowStatuses: itemConfig?.category !== categories.ENROLLMENT,
    formattedItemName: getFormattedItemName(itemConfig),
    counts: item.counts || {
      success: 0,
      failed: 0,
      pending: 0,
    },
  };
};

const transformLibraryItems = (
  items,
  { environment, account },
  isLoadingCounts,
) => {
  try {
    const categoricalItems = Object.values(apiTypes).reduce((acc, type) => {
      const category = getItemCategory(type);
      acc[category] = {
        category,
        data: [],
      };

      return acc;
    }, {});

    const sortedItems = items.sort(
      /* istanbul ignore next */
      (a, b) =>
        a.name.localeCompare(b.name) ||
        a.instance_name ||
        ''.localeCompare(b.instance_name || ''),
    );

    sortedItems.forEach((item) => {
      const category = getItemCategory(item.type);
      const itemConfig = Object.values(allLibraryItemsConfigurations).find(
        (conf) => conf.type === item.type,
      );
      const isHidden = itemConfig?.isHidden?.({ environment, account });
      if (category && !isHidden && getItemConfig(item)) {
        categoricalItems[category].data.push({
          ...transformItem(item),
          isLoadingCounts,
        });
      }
    });

    return Object.values(categoricalItems);
  } catch (e) {
    console.error('Transform Library', e);
  }
  return [];
};

const transformLibraryAddItem = (item, libConfigItem) => {
  // TODO: Check if the backend sends this info in data
  const typeToDevices = {
    'automatic-app': deviceTypes.MAC,
    'ios-release': deviceTypes.IPHONE,
    'ipados-release': deviceTypes.IPAD,
    'tvos-release': deviceTypes.TV,
    'macos-release': deviceTypes.MAC,
    'mdm-macos-release': deviceTypes.MAC,
  };

  const device = typeToDevices[item.type];

  return {
    ...item,
    category: getItemCategory(item.type),
    devices: device ? [device] : libConfigItem.devices,
    osRequirements:
      device && item.data?.minimum_os_version
        ? [`${osPrefixes[device]} ${item.data.minimum_os_version}+`]
        : libConfigItem.osRequirements,
    publisher: item.data?.publisher || item.publisher || publishers.APPLE,
    icon: item.data?.icon_url,
    libraryConfig: libConfigItem,
    getUrl: libConfigItem.getUrl,
    getAddUrl: libConfigItem.getAddUrl,
    isHidden: libConfigItem.isHidden,
  };
};

/* Transforms the list of managed library items. Serves the add-library-item. */
const getAddLibraryItemsList = (apiItems) => {
  const transformed = libraryItemsToAdd
    .filter((libConfig) => {
      // Need to filter the addable items based on feature flags here because `libraryItemsToAdd` is evaluated before flags are available:
      const itemsFeatureFlaggedOff = [];
      if (featureFlags.getFlag('DC-722-disk-management-li')) {
        itemsFeatureFlaggedOff.push('Media Access');
      }
      return !itemsFeatureFlaggedOff.includes(libConfig.name);
    })
    .map((libConfig) => {
      if (libConfig.canAdd === canAddLibraryTypes.PREDEFINED_BY_BACKEND) {
        return apiItems
          .filter((item) => item.type === libConfig.type)
          .map((item) => transformLibraryAddItem(item, libConfig));
      }
      return libConfig;
    })
    .flat()
    .reduce(
      (a, c) => ({
        ...a,
        [c.category]: {
          type: c.type,
          category: c.category,
          data: [...(a[c.category]?.data || []), c],
        },
      }),
      {},
    );

  return Object.values(transformed);
};

export {
  transformLibraryItems,
  getAddLibraryItemsList,
  transformItem,
  getItemConfig,
};
