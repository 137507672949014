import React from 'react';

import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';

import { i18n } from 'i18n';
import { startFromScratch } from '../../assets';
import { PENDO_IDS } from '../../constants';
import { getRatingLocks } from '../../helpers';

const TemplateCard = ({
  name,
  description,
  itemsCount,
  onSelect,
  onClickDetails,
}) => (
  <Card interactive onClick={onSelect} css={{ cursor: 'pointer' }}>
    <Flex flow="column" gap="md" data-testid="template-card">
      <Flex gap="md" alignItems="center" justifyContent="space-between">
        <Flex gap="md" alignItems="center">
          <Box
            css={{
              padding: '$2',
              borderRadius: '50%',
              backgroundColor: 'var(--colors-blue10)',
            }}
          >
            <Icon name="kandji-blueprint" color="var(--colors-blue60)" />
          </Box>
          <Heading size="4" css={{ fontWeight: '$medium' }}>
            {name}
          </Heading>
        </Flex>
        <Flex flow="column" gap="xs" alignItems="center">
          {getRatingLocks(itemsCount, 'sm')}
          <Text size={1} variant="description" css={{ fontSize: '8px' }}>
            {i18n.common.numItems(itemsCount)}
          </Text>
        </Flex>
      </Flex>
      <Text
        css={{
          display: '-webkit-box',
          maxHeight: '45px',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
          whiteSpace: 'normal',
          overflow: 'hidden',
        }}
        variant="description"
        size={1}
      >
        {description}
      </Text>
      <Box>
        <Button
          variant="link"
          onClick={(e) => {
            e.stopPropagation();
            onClickDetails();
          }}
          compact
          css={{ backgroundColor: 'unset !important' }}
        >
          {i18n.t('View details')}
        </Button>
      </Box>
    </Flex>
  </Card>
);

type Step1Props = {
  templates: any[];
  onSelectStartingPoint: (startingPoint: string, template?: any) => void;
  onSelectViewDetails: (template: any) => void;
};

const Step1 = (props: Step1Props) => {
  const { templates, onSelectStartingPoint, onSelectViewDetails } = props;

  const allTemplates = templates.reduce((acc, template) => {
    acc.push(...template.templates);
    return acc;
  }, []);

  return (
    <Flex flow="column" gap="md">
      <Grid columns={2} gap={2}>
        <div id={PENDO_IDS.startFromScratch}>
          <Card
            interactive
            css={{ textAlign: 'center', '&:hover': { cursor: 'pointer' } }}
            onClick={() => onSelectStartingPoint('from-scratch')}
          >
            {startFromScratch}
            <Heading
              size="5"
              css={{ fontWeight: '$medium' }}
              data-testid="start-from-scratch-heading"
            >
              {i18n.t('Start from scratch')}
            </Heading>
          </Card>
        </div>
      </Grid>

      <Heading size="4" css={{ fontWeight: '$medium' }}>
        {i18n.t('Or choose from a template...')}
      </Heading>

      <Grid columns={2} gap={4}>
        {allTemplates.map((template) => (
          <TemplateCard
            key={template.name}
            name={template.name}
            description={template.description}
            itemsCount={Object.keys(template.params)?.length}
            onSelect={() => onSelectStartingPoint('from-template', template)}
            onClickDetails={() => onSelectViewDetails(template)}
          />
        ))}
      </Grid>
    </Flex>
  );
};

export default Step1;
