import React, { useContext } from 'react';

import { Row } from '@kandji-inc/bumblebee';

import {
  Card,
  Controls,
  Header,
  Rows,
  SubHeader,
  Title,
} from 'features/library-items/template';

import { AccountContext } from 'contexts/account';

import { i18n } from 'i18n';
import TextInputReadOnly from '../../components/common/text-input-readonly';

export default () => {
  const { currentCompany: data } = useContext(AccountContext);

  const { legacy_device_domain, device_domain } = data;

  const deviceDomains = [legacy_device_domain, device_domain];

  const loopDeviceDomains = deviceDomains.map((value) => (
    <TextInputReadOnly
      key={value}
      value={value}
      toast={i18n.t('Copied to clipboard.')}
    />
  ));

  return (
    <Card>
      <Header>
        <h3 className="b-h3">Device domains</h3>
      </Header>
      <SubHeader>
        <p className="b-txt">
          {i18n.t(
            'These domains are unique to your instance and must be accessible by your devices.',
          )}{' '}
          <a
            className="b-alink"
            href="https://support.kandji.io/support/solutions/articles/72000559832"
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.t('Learn more...')}
          </a>
        </p>
      </SubHeader>
      <Rows>
        <Row>
          <Title>
            <p className="b-txt">
              {i18n.t("Your organization's device domains are:")}
            </p>
          </Title>
          <Controls>{loopDeviceDomains}</Controls>
        </Row>
      </Rows>
    </Card>
  );
};
