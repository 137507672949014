// src/constants.ts
var DEFAULT_LOCALE = "en";
var REACT_APP_TRANSIFEX_CDS_HOST = window.REACT_APP_TRANSIFEX_CDS_HOST || process.env.REACT_APP_TRANSIFEX_CDS_HOST;
var REACT_APP_TRANSIFEX_TOKEN = window.REACT_APP_TRANSIFEX_TOKEN || process.env.REACT_APP_TRANSIFEX_TOKEN;

// src/providers/NoopProvider.tsx
import {
  MessageFormatRenderer,
  escape as txEscape,
  isString as txIsString
} from "@transifex/native";

// src/providers/BaseProvider.tsx
import { jsx } from "react/jsx-runtime";
var BaseProvider = class {
  constructor() {
    this.currentLocale = DEFAULT_LOCALE;
  }
  setLocale(localeCode) {
    this.currentLocale = localeCode;
    return Promise.resolve();
  }
  getLocale() {
    return this.currentLocale;
  }
  translate(str, params) {
    throw new Error("Not implemented");
  }
  translateWithHtml(str, params) {
    const newParams = { ...params, _escapeVars: true };
    const htmlStr = this.translate(str, newParams);
    return /* @__PURE__ */ jsx("span", {
      dangerouslySetInnerHTML: { __html: htmlStr }
    });
  }
};
BaseProvider.provider_name = "";

// src/providers/NoopProvider.tsx
var renderer = new MessageFormatRenderer();
var NoopProvider = class extends BaseProvider {
  translate(str, params) {
    let safeParams = {};
    if (params === void 0) {
      safeParams = {};
    } else if (params._escapeVars) {
      Object.keys(params).forEach((param) => {
        const value = params[param];
        safeParams[param] = txIsString(value) ? txEscape(value) : value;
      });
    } else {
      safeParams = params;
    }
    return renderer.render(str, this.currentLocale, safeParams);
  }
};
NoopProvider.provider_name = "noop";

// src/providers/QAProvider.tsx
var QAProvider = class extends NoopProvider {
  translate(str, params) {
    return `//${super.translate(str, params)}//`;
  }
};
QAProvider.provider_name = "qa";

// src/providers/TransifexProvider.tsx
import { t, tx } from "@transifex/native";
var TransifexProvider = class extends BaseProvider {
  constructor(cdsHost, token) {
    super();
    tx.init({ cdsHost, token });
  }
  setLocale(localeCode) {
    this.currentLocale = localeCode;
    return tx.setCurrentLocale(localeCode);
  }
  translate(str, params) {
    return t(str, params);
  }
};
TransifexProvider.provider_name = "transifex";

// src/index.ts
var provider;
var getI18nProviderByName = (providerName) => {
  switch (providerName) {
    case QAProvider.provider_name:
      return new QAProvider();
    case TransifexProvider.provider_name: {
      const cdsHost = REACT_APP_TRANSIFEX_CDS_HOST;
      const token = REACT_APP_TRANSIFEX_TOKEN;
      if (cdsHost && token) {
        return new TransifexProvider(cdsHost, token);
      }
      console.error("Missing Transifex environment variables");
    }
  }
  return new NoopProvider();
};
var setI18nProvider = (providerName) => {
  provider = getI18nProviderByName(providerName);
};
var setI18nLocale = (localeCode) => {
  if (!provider) {
    throw new Error("i18n provider must be set before using setI18nLocale");
  }
  return provider.setLocale(localeCode);
};
var getI18nLocale = () => {
  if (!provider) {
    throw new Error("i18n provider must be set before using getI18nLocale");
  }
  return provider.getLocale();
};
var render = (cb) => {
  if (!provider) {
    throw new Error("i18n provider must be set before using render");
  }
  return cb(getI18nLocale());
};
var t2 = (str, params) => {
  if (!provider) {
    throw new Error(
      `i18n provider must be set before using t('${str}'). Is it executing before runtime?`
    );
  }
  return provider.translate(str, params);
};
var ut = (str, params) => {
  if (!provider) {
    throw new Error(
      `i18n provider must be set before using ut('${str}'). Is it executing before runtime?`
    );
  }
  return provider.translateWithHtml(str, params);
};
export {
  getI18nLocale,
  render,
  setI18nLocale,
  setI18nProvider,
  t2 as t,
  ut
};
