import { Toaster as toaster } from '@kandji-inc/bumblebee';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';

import { InterfaceContext } from 'contexts/interface';
import { useFlags } from 'src/config/feature-flags';
import * as constants from 'src/constants';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { copyToClipboard } from 'theme/function';

import { i18n } from 'i18n';
import referralImg from '../../assets/img/resources/referral.png';
import LaunchNotesEmbed from './LaunchNotes';
import AppleSeed from './icons/AppleSeed';
import AutoAppIcon from './icons/AutoApps';
import BooksIcon from './icons/Books';
import FeatureRequestIcon from './icons/FeatureRequest';
import RSSIcon from './icons/RSS';
import SlackIcon from './icons/Slack';
import Training from './icons/Training';

const SHOW_REFERRAL = constants.REACT_APP_ENV_NAME !== 'PRD-EU';

/* Grid */

const Container = styled(Grid, {
  maxWidth: 1650,
  gap: 25,
  gridTemplateColumns: '1fr 1fr 1fr',
  gridAutoFlow: 'dense',

  '@media (max-width: 1700px)': {
    gridTemplateColumns: '1fr 1fr',
  },

  '@media (max-width: 1250px)': {
    gridTemplateColumns: '1fr',
  },
});

/* Cards */

const baseCard = {
  maxWidth: 550,
  minWidth: 435,
  flexDirection: 'column',
  padding: 20,
  backgroundColor: '$neutral0',
  borderRadius: 8,
  boxShadow: '$elevation1',
  height: 'fit-content',
  minHeight: 167,

  '@media (max-width: 1700px)': {
    maxWidth: 650,
    minHeight: 0,
  },

  '@media (max-width: 1250px)': {
    maxWidth: 550,
  },
};

const Card = styled(Flex, {
  ...baseCard,
});

const CardNotes = styled(Flex, {
  ...baseCard,
  padding: 0, // override padding

  height: 870,
  maxHeight: 870,
  gridRow: 'span 4',

  '@media (max-width: 1700px)': {
    gridRow: 'span 6 !important',
    maxWidth: 650,

    minHeight: 1125,
    height: 1125,
  },

  '@media (max-width: 1250px)': {
    height: 550,
    maxWidth: 550,
    gridRow: 'span 1 !important',
  },
});

const CardFeed = styled(Flex, {
  ...baseCard,
  ...(!SHOW_REFERRAL ? { gridColumn: '2 / 3' } : {}),

  '@media (max-width: 1700px)': {
    maxWidth: 650,
    gridColumn: '1 / 2 !important',
  },

  '@media (max-width: 1250px)': {
    maxWidth: 550,
  },
});

/* Release Notes Content */

const TitleContainer = styled(Box, {
  padding: '16px 24px',
  backgroundColor: '$neutral0',

  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,

  position: 'sticky',
  top: 0,
  zIndex: 1,
});

const ContentContainer = styled(Box, {
  padding: '0 24px',
  backgroundColor: '$neutral0',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  overflow: 'auto',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

/* Info Card Content */

const HeadingCard = styled(Heading, {
  marginBottom: 10,
});

const InfoCard = styled(Flex, {
  alignItems: 'center',
  gap: 25,
});

const InfoCardImage = styled('img', {
  width: 60,
  height: 'auto',
});

const Desc = styled(Text, {
  margin: '10px 0',
  fontSize: 16,
  fontWeight: '$regular',
});

const Link = styled(Flex, {
  marginTop: 'auto',
  justifyContent: 'flex-end',

  '@media (max-width: 1700px)': {
    marginTop: 6,
  },

  '& a': {
    padding: '8px 12px',
    backgroundColor: '$blue50',
    color: '$neutral0',
    borderRadius: '$rounded',
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: '$medium',
  },
});

const CodeSnippet = styled(Box, {
  marginBottom: 10,
  backgroundColor: '#f5f5f5',
  border: '1px solid #ddd',
  borderRadius: 8,
  padding: 5,
  fontFamily: 'monospace',
  color: '#666',
  cursor: 'pointer',
  wordBreak: 'break-word',
});

const Resources = () => {
  useWhiteBackground();

  const { setIsOpenModalFeatureRequest } = useContext(InterfaceContext);
  const {
    'bzbe-07112024-updates-page': pageEnabled,
    'bzbe-07112024-updates-page-auto-apps': isAutoAppsFeedEnabled,
  } = useFlags([
    'bzbe-07112024-updates-page',
    'bzbe-07112024-updates-page-auto-apps',
  ]);

  if (!pageEnabled) {
    return null;
  }

  const [isScrolling, setIsScrolling] = useState(false);
  const contentContainerRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (contentContainerRef.current.scrollTop > 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  }, []);

  useEffect(() => {
    const contentContainer = contentContainerRef.current;
    contentContainer.addEventListener('scroll', handleScroll);
    return () => contentContainer.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <h1 className="b-h1 b-mt3 b-mb3">{i18n.t('Resources')}</h1>
      <LaunchNotesEmbed />
      <Container>
        <CardNotes>
          <TitleContainer
            style={{
              boxShadow: isScrolling
                ? '0px 1px 4px 0px rgba(15, 19, 23, 0.24)'
                : 'none',
            }}
          >
            <Heading
              size="3"
              css={{ fontWeight: '$medium', fontSize: 24, lineHeight: '36px' }}
            >
              Updates
            </Heading>
          </TitleContainer>
          <ContentContainer ref={contentContainerRef}>
            {/* LaunchNotes embed custom component */}
            <launchnotes-embed-inline
              token="public_nxeV5sqvmi3h0XKopxFThf5L"
              project="pro_5CJpgorhQkpmF"
              limit="50"
              primary-color="#FFFFFF"
              hide-l-n-branding
            ></launchnotes-embed-inline>
          </ContentContainer>
        </CardNotes>

        <Card>
          <InfoCard>
            <SlackIcon />
            <Box>
              <HeadingCard size="3" css={{ fontWeight: '$medium' }}>
                Join Mac Admins Slack
              </HeadingCard>
              <Desc>Join the #kandji channel on Mac Admins slack.</Desc>
            </Box>
          </InfoCard>
          <Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.kandji.io/resources/macadmins/"
            >
              Join
            </a>
          </Link>
        </Card>

        {SHOW_REFERRAL && (
          <Card>
            <InfoCard>
              <InfoCardImage src={referralImg} alt="Kandji Referral" />
              <Box>
                <HeadingCard size="3" css={{ fontWeight: '$medium' }}>
                  Make a referral, get a gift
                </HeadingCard>
                <Desc>Refer IT and security professionals to Kandji.</Desc>
              </Box>
            </InfoCard>
            <Link>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.kandji.io/company/customer-referral-program/"
              >
                Refer
              </a>
            </Link>
          </Card>
        )}

        <Card>
          <InfoCard>
            <BooksIcon />
            <Box>
              <HeadingCard size="3" css={{ fontWeight: '$medium' }}>
                Knowledge Base
              </HeadingCard>
              <Desc>Access documentation and more from Kandji support.</Desc>
            </Box>
          </InfoCard>
          <Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.kandji.io"
            >
              Access
            </a>
          </Link>
        </Card>

        <Card>
          <InfoCard>
            <FeatureRequestIcon />
            <Box>
              <HeadingCard size="3" css={{ fontWeight: '$medium' }}>
                Submit Feature Request
              </HeadingCard>
              <Desc>Share how we can better support your organization.</Desc>
            </Box>
          </InfoCard>
          <Link>
            <Button
              variant="primary"
              onClick={() => setIsOpenModalFeatureRequest(true)}
            >
              Submit
            </Button>
          </Link>
        </Card>

        <Card>
          <InfoCard>
            <AppleSeed />
            <Box>
              <HeadingCard size="3" css={{ fontWeight: '$medium' }}>
                AppleSeed for IT
              </HeadingCard>
              <Desc>
                Leverage AppleSeed to test and provide feedback on Apple beta
                software.
              </Desc>
            </Box>
          </InfoCard>
          <Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://beta.apple.com/it"
            >
              Participate
            </a>
          </Link>
        </Card>

        <Card>
          <InfoCard>
            <Training />
            <Box>
              <HeadingCard size="3" css={{ fontWeight: '$medium' }}>
                Apple IT Training
              </HeadingCard>
              <Desc>
                Get certified and learn how to deploy, manage, and secure Apple
                devices.
              </Desc>
            </Box>
          </InfoCard>
          <Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://training.apple.com/it"
            >
              Learn
            </a>
          </Link>
        </Card>

        <CardFeed>
          <InfoCard>
            <RSSIcon />
            <Box>
              <HeadingCard size="3" css={{ fontWeight: '$medium' }}>
                RSS Feeds
              </HeadingCard>
              <Desc css={{ marginBottom: 20 }}>
                Use the following RSS feeds to get Kandji updates.
              </Desc>
            </Box>
          </InfoCard>
          <Desc>Release Notes</Desc>
          <CodeSnippet
            onClick={() => {
              copyToClipboard('https://www.kandji.io/updates/feed');
              toaster(i18n.t('Copied to clipboard.'));
            }}
          >
            https://www.kandji.io/updates/feed
          </CodeSnippet>
          {isAutoAppsFeedEnabled && (
            <>
              <Desc>Auto Apps</Desc>
              <CodeSnippet
                onClick={() => {
                  copyToClipboard('https://www.kandji.io/aa-updates/feed');
                  toaster(i18n.t('Copied to clipboard.'));
                }}
              >
                https://www.kandji.io/aa-updates/feed
              </CodeSnippet>
            </>
          )}
        </CardFeed>

        <Card>
          <InfoCard>
            <AutoAppIcon />
            <Box>
              <HeadingCard size="3" css={{ fontWeight: '$medium' }}>
                Auto App Updates
              </HeadingCard>
              <Desc>View the latest updates in the Auto Apps catalog.</Desc>
            </Box>
          </InfoCard>
          <Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.kandji.io/aa-updates"
            >
              View
            </a>
          </Link>
        </Card>
      </Container>
    </>
  );
};

export default Resources;
