import { Button, Toaster as toaster } from '@kandji-inc/bumblebee';
import React from 'react';

import { newIntegrationsService } from '../../../../data-service/new-integrations-service';
import {
  IPCard,
  IPCardSection,
  IPCardTitle,
} from '../../building-blocks/card-parts';
import { CONFIG_SHAPE, TYPE_PROP_TYPE } from '../util';
import './summary-card.styles.scss';
import { i18n } from 'i18n';

function SummaryCard({ type, config }) {
  return (
    <IPCard>
      <IPCardTitle>
        <img
          src={config.icons.large}
          alt="int-icon"
          className="summary-card_icon"
        />
        <h1 className="b-h1 b-ml">{config.title}</h1>
      </IPCardTitle>
      <IPCardSection>
        <p className="summary-card_text">{config.description}</p>
      </IPCardSection>
      <IPCardSection>
        <Button
          icon="circle-plus"
          kind="link"
          className="cmn-button--size-medium"
          onClick={() => {
            newIntegrationsService
              .createIntegration({ type })
              .then(({ data }) => {
                window.location.href = data.redirect_url;
              })
              .catch((e) => {
                console.log(e);
                toaster(i18n.common.error());
              });
          }}
        >
          Add {config.integrationAlias}
        </Button>
      </IPCardSection>
    </IPCard>
  );
}

SummaryCard.propTypes = {
  config: CONFIG_SHAPE.isRequired,
  type: TYPE_PROP_TYPE.isRequired,
};

export default SummaryCard;
