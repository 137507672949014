import { Body, Button, DialogV2 as Dialog, Flex } from '@kandji-inc/nectar-ui';
import * as React from 'react';

export function ManualEnrollmentCodeDialog({
  isOpen,
  toggleDialog,
  handleGenerate,
}) {
  const [isGenerating, setIsGenerating] = React.useState<boolean>(false);

  return (
    <Dialog
      isOpen={isOpen}
      title={`Generate new code?`}
      onOpenChange={toggleDialog}
      css={{ width: 500 }}
      content={
        <Flex flow="column">
          <Body css={{ marginBottom: 20 }}>
            The previous enrollment code for this Blueprint will be revoked, and
            a new one will be generated.
          </Body>
        </Flex>
      }
      footer={
        <Flex gap="sm" justifyContent="end">
          <Button
            compact
            onClick={/* istanbul ignore next */ () => toggleDialog(false)}
            disabled={isGenerating}
            variant="subtle"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            compact
            disabled={isGenerating}
            onClick={async () => {
              setIsGenerating(true);
              await handleGenerate();
              setIsGenerating(false);
              toggleDialog(false);
            }}
          >
            Generate new code
          </Button>
        </Flex>
      }
    />
  );
}
