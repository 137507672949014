import {
  Box,
  Button,
  Flex,
  Pagination,
  Separator,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Toaster_UNSTABLE as Toaster,
  Tr,
  useDialog,
  useToast_UNSTABLE,
} from '@kandji-inc/nectar-ui';
import deepcopy from 'deepcopy';
import { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { UserRoles } from 'src/app/common/constants';
import { Loader } from 'src/app/components/interface/Loader';
import useAccount from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import useAdjustSidebarChatBubble from 'src/features/integrations/hooks/use-adjust-sidebar-chat-bubble';
import { get } from 'src/features/util/lib';
import { usePagination } from 'src/features/visibility/prism/hooks';
import { NULL_VALUE_N_DASH } from 'src/features/visibility/prism/utils/column-helpers/column-utils';

import { noResults } from '../../assets';
import { Empty } from '../../components';
import { highlightedText, pluralizeWord } from '../../helpers';
import useComputers from '../../services/use-computers';
import AssignBlueprintModal from './AssignBlueprint';
import DeleteDeviceModal from './DeleteDevice';
import EmptyState from './EmptyState';
import FilterBar, { type Filter, defaultFilter } from './FilterBar';
import { columns } from './table-columns';
import useSort, { getSortParam } from './use-sort';
import useBulkActions from './useBulkActions';

const Devices = () => {
  useAdjustSidebarChatBubble();
  const { userRole } = useAccount();
  const isAuditor = userRole === UserRoles.auditor;
  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;
  const { id } = useParams<{ id?: string }>();
  const { sidebarDocked } = useContext(InterfaceContext);
  const { paginationState, setPagination, resetPagination } = usePagination();
  const [sort, onSortColumn] = useSort();
  const [debouncedFilters, setFilters, filters] = useDebouncedState<Filter>(
    deepcopy(defaultFilter),
  );
  const [selected, setSelected] = useState({});
  const [isDeviceDeleteModalOpen, setDeviceDeleteModalOpen] = useDialog();
  const [isAssignBlueprintModalOpen, setAssignBlueprintModalOpen] = useDialog();
  const { toast } = useToast_UNSTABLE();
  const [tableContainerRef, setTableContainerRef] = useState<HTMLElement>();

  const { deleteDevices, changeBlueprint } = useBulkActions({
    onAfterMutation: () => {
      setSelected({});
      setFilters(deepcopy(defaultFilter));
      resetPagination();
    },
  });

  const {
    count,
    computers: devices,
    isFetched,
    isLoading,
  } = useComputers([paginationState, sort, debouncedFilters], {
    filters: JSON.stringify([
      {
        name: 'blueprint',
        operator: 'equals',
        value: [id],
      },
    ]),
    search: debouncedFilters.term,
    sizePerPage: paginationState.pageSize,
    ordering: getSortParam(sort.id, sort.state),
    page: paginationState.pageIndex + 1,
  });

  const displayToast = (title: string, content?: string) =>
    toast({
      title,
      content,
      variant: 'success',
      duration: 5000,
      style: {
        // istanbul ignore next
        left: sidebarDocked
          ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
          : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
        bottom: '12px',
        position: 'absolute',
      },
    });

  useLayoutEffect(() => {
    /* istanbul ignore next */
    const addShadowOnScroll = (e) => {
      const thead = tableContainerRef.querySelector('thead');
      if (thead) {
        const { scrollTop } = e.target as HTMLElement;
        if (!scrollTop) {
          thead.style.boxShadow = 'unset';
        } else {
          thead.style.boxShadow = '2px 2px 4px 0px rgba(15, 19, 23, 0.16)';
        }
      }
    };

    if (tableContainerRef) {
      tableContainerRef.addEventListener('scroll', addShadowOnScroll);
    }

    return () =>
      tableContainerRef?.removeEventListener('scroll', addShadowOnScroll);
  }, [tableContainerRef]);

  const isFilterActive = useMemo(
    () => JSON.stringify(debouncedFilters) !== JSON.stringify(defaultFilter),
    [debouncedFilters],
  );
  const shouldShowEmptyState = isFetched && !isFilterActive && count === 0;
  const shouldShowNoFilterResultState = isFilterActive && count === 0;
  const shouldShowTable = !isLoading && !shouldShowEmptyState;

  const currentPage = paginationState.pageIndex + 1;
  const totalPages = Math.ceil((count || 0) / paginationState.pageSize);
  const commonProps = {
    devices,
    setSelected,
    selected,
    filter: debouncedFilters,
  };

  const TableRows = useMemo(
    (): JSX.Element =>
      devices?.map((device, idx) => {
        const { id } = device;
        const isSelected = selected[id];

        return (
          <Tr key={id} data-testid={`am-device-row-${device.name}_${idx}`}>
            {columns.map((column) => {
              const Cell = column.cell;
              const value = get<string | any>(device, column.dataKey);
              const selectedCss = isSelected
                ? {
                    backgroundColor: 'rgba(16, 106, 242, 0.08)',
                    borderTop: '1px solid $blue30',
                    borderBottom: '1px solid $blue30',
                  }
                : {};

              return (
                <Td
                  key={column.name}
                  title={null}
                  css={{
                    ...(column.meta?.row?.css || {}),
                    ...selectedCss,
                  }}
                >
                  {Cell ? (
                    <Cell {...commonProps} device={device} />
                  ) : (
                    highlightedText(
                      value || NULL_VALUE_N_DASH,
                      debouncedFilters.term,
                    )
                  )}
                </Td>
              );
            })}
          </Tr>
        );
      }),
    [devices, selected],
  );

  const selectedRows = useMemo(() => Object.keys(selected), [selected]);

  return (
    <Box data-testid="am-devices" hFull>
      {isLoading && (
        <Flex hFull alignItems="center" justifyContent="center">
          <Loader />
        </Flex>
      )}
      {shouldShowEmptyState && <EmptyState />}
      {shouldShowTable && (
        <Box
          css={{ height: 'calc(100% - 61px)', overflow: 'auto' }}
          ref={setTableContainerRef}
        >
          <FilterBar
            filter={filters}
            onChange={(field, val) =>
              setFilters((prev) => ({ ...prev, [field]: val }))
            }
            blueprintId={id}
          />
          {shouldShowNoFilterResultState ? (
            <Empty
              image={noResults}
              title="No results found."
              message="We couldn't find a match. Try changing your filter parameters, or search with different keywords."
              css={{ height: 'inherit' }}
            />
          ) : (
            <Table
              aria-label="device-table"
              data-testid={`am-device-table-sort-${sort.id}-${sort.state}`}
            >
              <Thead
                style={{
                  position: 'sticky',
                  top: 0,
                }}
              >
                <Tr>
                  {columns.map((column) => {
                    const HeaderCell = column.headerCell;
                    const isSortColumn = sort.id === column.sortKey;
                    const state = isSortColumn ? sort.state : 'none';
                    const onSort = () =>
                      isSortColumn
                        ? onSortColumn()
                        : onSortColumn(column.sortKey, 'asc');

                    return (
                      <Th
                        key={column.name}
                        title={null}
                        sort={
                          column.isSortable && {
                            state,
                            onSort,
                          }
                        }
                        showMenu
                        css={column.meta?.header?.css}
                        data-testid={`am-device-header-${column.name}`}
                      >
                        {HeaderCell ? (
                          <HeaderCell {...commonProps} />
                        ) : (
                          column.name
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody data-testid="am-device-tbody">{TableRows}</Tbody>
            </Table>
          )}
        </Box>
      )}
      {shouldShowTable && (
        <Box
          css={{
            position: 'fixed',
            bottom: 0,
            padding: '$3 $5',
            borderTop: '1px solid $neutral20',
            width: sidebarDocked
              ? `calc(100% - ${SIDEBAR_DOCKED_OFFSET}px)`
              : `calc(100% - ${SIDEBAR_CLOSE_OFFSET}px)`,
            backgroundColor: '$neutral0',
          }}
        >
          <Pagination
            menuAbove
            currentPage={currentPage}
            totalPages={totalPages || 1}
            totalItems={count}
            itemsPerPage={paginationState.pageSize}
            onPageChange={(page) =>
              setPagination((prev) => ({
                ...prev,
                pageIndex: page - 1,
              }))
            }
            bulkActions={
              selectedRows.length > 0 && (
                <Flex>
                  <Flex gap="sm" alignItems="center">
                    <Button
                      variant="default"
                      onClick={() => setAssignBlueprintModalOpen(true)}
                      disabled={isAuditor}
                    >
                      Change Blueprint
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => setDeviceDeleteModalOpen(true)}
                      disabled={isAuditor}
                    >
                      {pluralizeWord('Delete device', selectedRows.length)}
                    </Button>
                  </Flex>
                  <Separator
                    orientation="vertical"
                    css={{
                      margin: '0 18px',
                      background: '#D7E1ED',
                    }}
                  />
                  <Flex gap="sm" alignItems="center">
                    <Text size="2" variant="description">
                      {selectedRows.length} selected
                    </Text>
                    <Button
                      compact
                      variant="subtle"
                      css={{ color: '$blue50' }}
                      onClick={() => setSelected({})}
                    >
                      Clear
                    </Button>
                    {/* <Separator
                      orientation="vertical"
                      css={{
                        background: '#D7E1ED',
                      }}
                    />
                    <Button compact variant="subtle" css={{ color: '$blue50' }}>
                      Select all {devicesRes?.count}
                    </Button> */}
                  </Flex>
                </Flex>
              )
            }
            data-testid="am-device-pagination"
          />
        </Box>
      )}

      <DeleteDeviceModal
        isOpen={isDeviceDeleteModalOpen}
        isMultiple={Object.keys(selected).length > 1}
        toggle={setDeviceDeleteModalOpen}
        onConfirm={() => {
          deleteDevices({ selectedDevices: selected })
            .then(() =>
              displayToast(
                `${selectedRows.length} ${pluralizeWord(
                  'device',
                  selectedRows.length,
                )} will be deleted.`,
              ),
            )
            .catch(() =>
              displayToast(
                `Failed to delete ${pluralizeWord(
                  'device',
                  selectedRows.length,
                )}.`,
                'Please try again.',
              ),
            );
        }}
      />
      <AssignBlueprintModal
        isOpen={isAssignBlueprintModalOpen}
        toggle={setAssignBlueprintModalOpen}
        currentBlueprintId={id}
        isMultiple={Object.keys(selected).length > 1}
        onConfirm={({ name, id: blueprintId }) =>
          changeBlueprint({ selectedDevices: selected, blueprintId })
            .then(() =>
              displayToast(
                `${selectedRows.length} ${pluralizeWord(
                  'device',
                  selectedRows.length,
                )} will be reassigned to Blueprint ${name}.`,
                'This may take some time to complete.',
              ),
            )
            .catch(() =>
              displayToast(
                `Failed to reassign ${pluralizeWord(
                  'device',
                  selectedRows.length,
                )}.`,
                'Please try again.',
              ),
            )
        }
      />
      <Toaster />
    </Box>
  );
};

export default Devices;
