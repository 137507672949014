import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Banner } from '@kandji-inc/nectar-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { i18n } from 'i18n';
import KandjiSupportLink from '../../common/KandjiSupportLink';
import { isM1Device, regExpMapper } from '../../common/helpers';
import { getPin } from '../api';
import PINModal from './PINModal';

const ShowPINField = styled('span')`
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

const LockedNotification = ({
  computerId,
  lockStatus,
  deviceModel,
  osVersion,
}) => {
  const [pin, setPin] = useState(null);
  const [showPin, setShowPin] = useState(false);
  const onShowClick = useCallback(() => {
    getPin(computerId)
      .then((res) => {
        setPin(res.pin);
        setShowPin((prev) => !prev);
      })
      .catch(() => {
        toaster(i18n.t('Failed to get the PIN code'));
      });
  }, []);
  const onClose = () => {
    setShowPin(false);
  };
  const disabledLockPin =
    isM1Device(deviceModel) && regExpMapper['11.0-11.4'].test(osVersion);
  const disabledLockPinText =
    ' Lock device PINs are not supported on Mac computers with Apple silicon prior to macOS 11.5.';
  const supportLink = (
    <KandjiSupportLink
      href="https://support.kandji.io/support/solutions/articles/72000560469"
      text=" Learn more."
    />
  );

  return (
    <Banner
      theme="danger"
      icon="lock"
      text={
        <>
          {lockStatus === 'yes' && (
            <span>
              <b>This device is locked.</b>
              {!disabledLockPin
                ? '  It can be unlocked using the PIN.'
                : disabledLockPinText}
              {supportLink}
            </span>
          )}
          {lockStatus === 'pending' && (
            <span>
              {!disabledLockPin ? (
                <>
                  <b>
                    This device will be locked with
                    {
                      <ShowPINField onClick={onShowClick}>
                        this PIN
                      </ShowPINField>
                    }{' '}
                    the next time it connects to MDM.
                  </b>
                </>
              ) : (
                <>
                  <b>This device will lock the next time it connects to MDM.</b>
                  {disabledLockPinText}
                </>
              )}
              {supportLink}
            </span>
          )}
          {lockStatus === 'yes' && !disabledLockPin && (
            <>
              <ShowPINField onClick={onShowClick}>Show PIN</ShowPINField>
            </>
          )}
          {pin && <PINModal pin={pin} showPin={showPin} onClose={onClose} />}
        </>
      }
    />
  );
};

LockedNotification.propTypes = {
  computerId: PropTypes.string.isRequired,
  lockStatus: PropTypes.string.isRequired,
  deviceModel: PropTypes.string.isRequired,
  osVersion: PropTypes.string.isRequired,
};

export default LockedNotification;
