import type {
  AllowedSelectValue,
  SelectOptionMemberList,
} from '@kandji-inc/nectar-ui';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { LineLoader } from 'app/components/interface/LineLoader';
import { useSidebarToast } from 'src/hooks/useSidebarToast';

import { Hint, dropdownSizeTokens, styled } from '@kandji-inc/nectar-ui';
import ViewCheckbox from '../../../layouts/partials/view-checkbox';
import ViewHeaderH2 from '../../../layouts/partials/view-header-h2';
import ViewRows from '../../../layouts/partials/view-rows';

import type { CheckboxOption } from '../../../layouts/partials/view-checkbox';

import { StyledContainer as container } from '../../../../../components/styled-components/main';
import { INT_TYPES } from '../../../../../constants';
import useListIntegrations from '../../../../hooks/use-list-integrations';
import SelectUserGroup from '../../../../ms-device-compliance/components/select-user-group';
import useGetIntegrationDetails from '../../../../ms-device-compliance/hooks/use-get-integration-details';
import useGetUserGroupListOptions from '../../../../ms-device-compliance/hooks/use-get-user-group-list-options';

const StyledContainer = styled(container, {
  $$selectMdSize: dropdownSizeTokens.$$md,

  paddingBottom: '$$selectMdSize',
  gap: '$5',
});

const StyledPlatformInfo = styled(container, {
  gap: '$3',
  width: '100%',
});

interface SetupProps {
  data: {
    platformInfo: {
      title: string;
      description: string;
      validationMessage: string;
      list: Array<CheckboxOption>;
    };
  };
}

const Setup = ({ data }: SetupProps) => {
  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  const { displayToast } = useSidebarToast();

  const { data: integrationId, ...listQuery } = useListIntegrations({
    integrationType: INT_TYPES.msDeviceCompliance,
    select: (res) => res?.data?.results?.[0]?.uuid || '',
  });

  useEffect(() => {
    const isMissingIntegration = !listQuery.isPending && !integrationId;
    if (isMissingIntegration) {
      displayToast({
        title:
          'Please set up the Microsoft Device Compliance integration first',
        variant: 'error',
      });
    }
  }, [integrationId, listQuery.isPending]);

  const { data: msDomainName } = useGetIntegrationDetails(integrationId, {
    queryOptions: {
      select: (res) => res?.data?.ms_domain_name || '',
    },
  });

  const { platformInfo } = data;

  const formInputs = getValues();
  const validateInputs = !!(formInputs.macosDevices || formInputs.iosDevices);
  const showMessage =
    !!(errors.macosDevices || errors.iosDevices) && !validateInputs;

  const [groupOptions, setGroupOptions] = useState<
    SelectOptionMemberList<AllowedSelectValue, 'value', 'label'>
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const accountInfo = [
    {
      name: 'Microsoft domain',
      value: msDomainName || '',
    },
  ];

  useGetUserGroupListOptions({ setGroupOptions, setIsLoading });

  return isLoading ? (
    <LineLoader />
  ) : (
    <StyledContainer>
      <ViewRows list={accountInfo} />

      <StyledPlatformInfo>
        <ViewHeaderH2
          title={platformInfo.title}
          description={platformInfo.description}
        />

        {platformInfo.list.map((option: CheckboxOption) => (
          <React.Fragment key={option.name}>
            <ViewCheckbox
              options={[
                {
                  ...option,
                  validations: {
                    validate: () => validateInputs,
                  },
                },
              ]}
            />
            <SelectUserGroup field={option.name} options={groupOptions} />
          </React.Fragment>
        ))}

        {showMessage && (
          <Hint variant="error" label={platformInfo.validationMessage} />
        )}
      </StyledPlatformInfo>
    </StyledContainer>
  );
};

export default Setup;
