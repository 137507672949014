import { useQuery } from '@tanstack/react-query';
import { api } from 'src/app/api/base';
import { URL_AUTH_CONNECTOR } from 'src/app/api/urls';
import { authStrategyMap } from 'src/features/library-items/data-service/sso/use-sso-service';
import type { AuthConnection } from '../ManualEnrollment.types';

export async function getAuthConnectors(): Promise<AuthConnection[]> {
  try {
    const res = await api(URL_AUTH_CONNECTOR).get({});
    const { data } = res;
    const connections = data.connections ?? [];

    return connections.filter(
      (connection: AuthConnection) =>
        connection.strategy !== authStrategyMap.EMAIL_PASSWORD,
    );
  } catch (error) {
    return [];
  }
}

export function useGetAuthConnectors() {
  return useQuery({
    queryKey: ['get-auth-connectors'],
    queryFn: getAuthConnectors,
  });
}
