import {
  BasePagination,
  Button,
  Flex,
  PaginationPrimitives as Pagination,
  type PaginationProps,
  SelectV2 as Select,
  styled,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useADEListViewStore } from '../store/ADEListViewStore';

const SelectedContainer = styled('div', {
  display: 'flex',
  color: '$neutral70',
  fontWeight: 500,
  gap: 8,
  height: 28,
  alignItems: 'center',
  marginRight: 8,
});

const SelectAllContainer = styled('div', {
  paddingLeft: 8,
  borderLeft: '1px solid $neutral20',
  display: 'flex',
});

const StyledButton = styled(Button, {
  fontVariantNumeric: 'unset',
});

interface ADEListViewPaginationProps extends PaginationProps {
  isAllDevices: boolean;
}

export function ADEListViewPagination({
  currentPage,
  totalPages,
  onPageChange,
  totalItems,
  itemsPerPage,
  isAllDevices,
}: ADEListViewPaginationProps) {
  const {
    selectAllDevices,
    selectedDevices,
    setSelectedDevices,
    setSelectAllDevices,
  } = useADEListViewStore((state) => ({
    selectAllDevices: state.selectAllDevices,
    selectedDevices: state.selectedDevices,
    setSelectedDevices: state.setSelectedDevices,
    setSelectAllDevices: state.setSelectAllDevices,
  }));

  const selectedCount = selectAllDevices ? totalItems : selectedDevices.length;

  const [valueText, setValueText] = React.useState(
    Pagination.getPageString(currentPage, totalPages),
  );
  const isLastPage = currentPage === totalPages;
  const isFirstPage = currentPage === 1;

  const handlePageChange = (page: number) => {
    setValueText(Pagination.getPageString(page, totalPages));
    onPageChange(page);
  };

  React.useEffect(() => {
    setValueText(Pagination.getPageString(currentPage, totalPages));
  }, [currentPage, totalPages]);

  const options = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <BasePagination css={{ width: 'unset', flex: 1 }}>
      <Flex>
        {!isAllDevices && selectedCount > 0 ? (
          <>
            <SelectedContainer>
              {selectedCount} selected
              <StyledButton
                variant="link"
                onClick={() => {
                  setSelectedDevices([]);
                  setSelectAllDevices(false);
                }}
              >
                Clear
              </StyledButton>
            </SelectedContainer>
            {!selectAllDevices && (
              <SelectAllContainer>
                <StyledButton
                  data-testid="ade-pagination-select-all"
                  variant="link"
                  onClick={() => setSelectAllDevices(true)}
                >
                  Select all {totalItems}
                </StyledButton>
              </SelectAllContainer>
            )}
          </>
        ) : (
          <Pagination.Summary
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
          />
        )}
      </Flex>
      <Flex gap="xs">
        <Pagination.IconButton
          aria-label="select first page"
          type="button"
          onClick={() => handlePageChange(1)}
          disabled={isFirstPage}
          icon="arrow-left-to-line"
        />
        <Pagination.IconButton
          aria-label="select previous page"
          type="button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={isFirstPage}
          icon="fa-arrow-left-to-line-control"
        />
        <Select.Default
          value={currentPage.toString()}
          onValueChange={(e) => handlePageChange(parseInt(e, 10))}
          triggerProps={{
            value: valueText,
            disabled: totalPages === 1,
          }}
          options={options.map((option) => ({
            value: option.toString(),
            label: option.toString(),
          }))}
        />
        <Pagination.IconButton
          aria-label="select next page"
          type="button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={isLastPage}
          icon="fa-arrow-right-to-line-control"
        />
        <Pagination.IconButton
          aria-label="select last page"
          type="button"
          onClick={() => handlePageChange(totalPages)}
          disabled={isLastPage}
          icon="arrow-right-to-line"
        />
      </Flex>
    </BasePagination>
  );
}
