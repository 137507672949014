import {
  Badge,
  Box,
  Card,
  Flex,
  Icon,
  Link,
  Text,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { links } from 'src/app/common/constants';
import type {
  AppSummary,
  VulnerableDevice,
} from 'src/features/edr/vulnerability/vulnerability.types';
import { highlightedText } from 'src/pages/ADEListView/utils/highlightText';
import DeviceIcon from '../../../../assets/device.png';
import { DevicePath } from './device-path';

const DetailText = styled(Text, {
  fontSize: '$1',
  color: '$neutral70',
});

type DeviceCardProps = {
  device: VulnerableDevice;
  searchTerm: string;
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
};

const DeviceCard = (props: DeviceCardProps) => {
  const { device, searchTerm, isExpanded, setIsExpanded } = props;
  const {
    app_summary,
    assigned_user,
    blueprint_name,
    blueprint_id,
    device_id,
    model,
    name,
    os_version,
    serial_number,
  } = device;

  return (
    <Card
      variant={isExpanded ? 'elevate-2' : 'flat'}
      hoverVariant="elevate-2"
      css={{ padding: '$0' }}
    >
      <Flex flow="column" css={{ gap: 6 }}>
        <Flex
          alignItems="center"
          gap="sm"
          p4
          onClick={() => setIsExpanded(!isExpanded)}
          css={{
            paddingBottom: isExpanded ? '$1' : '$4',
            '&:hover': { cursor: 'pointer' },
          }}
        >
          <Box
            css={{
              width: 16,
              height: 16,
              svg: { width: 16, height: 16, verticalAlign: 'top' },
            }}
          >
            <Icon
              name={isExpanded ? 'fa-angle-up-small' : 'fa-angle-down-small'}
              color="var(--color-neutral-70)"
            />
          </Box>

          <img
            src={DeviceIcon}
            alt="device"
            style={{ width: 44, height: 44 }}
          />

          <Flex flow="column" gap="xs">
            <Flex alignItems="center" gap="sm">
              <Tooltip
                theme="light"
                side="bottom"
                css={{ zIndex: 2, padding: '$3 $4' }}
                content={
                  <Flex flow="column">
                    <Flex gap="xs">
                      <Text css={{ fontWeight: '$medium' }}>Assigned user</Text>
                      <Text>{assigned_user || '-'}</Text>
                    </Flex>
                    <Flex gap="xs">
                      <Text css={{ fontWeight: '$medium' }}>OS Version</Text>
                      <Text>{os_version}</Text>
                    </Flex>
                    <Flex gap="xs">
                      <Text css={{ fontWeight: '$medium' }}>Model name</Text>
                      <Text>{highlightedText(model, searchTerm)}</Text>
                    </Flex>
                  </Flex>
                }
              >
                <Text size="3" css={{ fontWeight: '$medium' }}>
                  {highlightedText(name, searchTerm)}
                </Text>
              </Tooltip>
              <Link
                href={`${links.devices}/${device_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  name="arrow-up-right-from-square"
                  size="sm"
                  color="var(--colors-neutral70)"
                />
              </Link>
              {app_summary.length > 1 && (
                <Tooltip
                  theme="dark"
                  side="bottom"
                  content={`${app_summary.length} instances`}
                  css={{ zIndex: 2 }}
                >
                  <Badge compact>{app_summary.length}</Badge>
                </Tooltip>
              )}
            </Flex>

            <Flex gap="sm">
              <DetailText>{serial_number}</DetailText>
              <Flex alignItems="center">
                <Icon
                  name="kandji-blueprint"
                  size="xs"
                  color="var(--colors-neutral70)"
                />
                <Link
                  href={`${links.blueprints}/${blueprint_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="subtle"
                  css={{
                    '&:hover': {
                      textDecorationColor: '$neutral70',
                    },
                  }}
                >
                  <DetailText
                    css={{
                      fontWeight: '$medium',
                      paddingLeft: '2px',
                    }}
                  >
                    {blueprint_name}
                  </DetailText>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {isExpanded && (
          <Flex flow="column" wFull css={{ padding: '0 $4 $2' }}>
            {app_summary.map((app: AppSummary) => (
              <DevicePath app={app} searchTerm={searchTerm} />
            ))}
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

export { DeviceCard };
