import './styles.scss';

import {
  Select,
  TextInput,
  Toaster as toaster,
  useInvalidations,
} from '@kandji-inc/bumblebee';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import isEqual from 'lodash/isEqual';

import { Card, Header, Settings } from 'features/library-items/template';

import { i18n } from 'i18n';
import Actions from './actions';
import { initialPage, useInitialForm } from './initial';
import { findOption, optionsCountRange, optionsIndustry } from './options';
import useCompanySettings from './use-company-settings';
import { requiredField } from './validation';

export default ({ children }) => {
  const history = useHistory();

  const { invalidations, onInvalidate } = useInvalidations({ inputs: 1 });
  const { initialForm, setInitialForm } = useInitialForm();
  const { updateCompanySettings } = useCompanySettings();

  const [page, setPage] = useState(initialPage);
  const [form, setForm] = useState(initialForm);

  const { isEditing, isSaving } = page;
  const {
    company_name,
    company_industry,
    company_size,
    device_count_estimate,
  } = form;

  const updatePage = (k, v) => setPage((p) => ({ ...p, [k]: v }));
  const updateForm = (k, v) => setForm((p) => ({ ...p, [k]: v }));

  const isDisabled = !isEditing || isSaving;

  const onBack = () => history.push('/devices');

  const onCancel = () => {
    updatePage('isEditing', false);
    setForm(initialForm);
  };

  const onEdit = () => updatePage('isEditing', true);

  const onSave = () => {
    updatePage('isSaving', true);
    updatePage('isEditing', false);
    updateCompanySettings(form);
    setInitialForm(form);
    updatePage('isSaving', false);
    toaster(i18n.t('Company settings saved.'));
  };

  useEffect(() => {
    updatePage(
      'isValid',
      !isEqual(initialForm, form) && !invalidations.some(Boolean),
    );
  }, [initialForm, form, invalidations]);

  return (
    <Settings className="k-company-settings">
      <Card>
        <Header>
          <h3 className="b-h3">Company overview</h3>
        </Header>
        <div className="b-flex-col fields">
          <div className="b-mb2">
            <p className="b-txt b-mb-tiny">Company name</p>
            <TextInput
              placeholder="Kandji"
              value={company_name}
              disabled={isDisabled}
              validator={requiredField('Company name is required')}
              onInvalidate={onInvalidate(0)}
              onChange={(e) => updateForm('company_name', e.target.value)}
              maxLength={100}
              aria-label="company-name"
              hideMaxLength
              compact
            />
          </div>
          <div className="b-mb2">
            <p className="b-txt b-mb-tiny">
              Device count <span className="b-txt-light">(optional)</span>
            </p>
            <Select
              placeholder="Select the estimated number of devices"
              options={optionsCountRange}
              value={findOption(device_count_estimate, optionsCountRange)}
              disabled={isDisabled}
              onChange={(v) => updateForm('device_count_estimate', v.value)}
              aria-label="device-count"
              compact
            />
          </div>
          <div className="b-mb2">
            <p className="b-txt b-mb-tiny">
              Industry <span className="b-txt-light">(optional)</span>
            </p>
            <Select
              placeholder="Select industry"
              options={optionsIndustry}
              value={findOption(company_industry, optionsIndustry)}
              disabled={isDisabled}
              onChange={(v) => updateForm('company_industry', v.value)}
              aria-label="industry"
              compact
            />
          </div>
          <div>
            <p className="b-txt b-mb-tiny">
              Company size <span className="b-txt-light">(optional)</span>
            </p>
            <Select
              placeholder="Select company size"
              options={optionsCountRange}
              value={findOption(company_size, optionsCountRange)}
              disabled={isDisabled}
              onChange={(v) => updateForm('company_size', v.value)}
              aria-label="company-size"
              compact
            />
          </div>
        </div>
      </Card>

      {children}

      <Actions
        page={page}
        onBack={onBack}
        onCancel={onCancel}
        onEdit={onEdit}
        onSave={onSave}
      />
    </Settings>
  );
};
