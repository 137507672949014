import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class SoftwareUpdateService extends NewLibraryItemService {
  static ALLOWED_OPTIONS = {
    allowed: {
      label: 'Allowed',
      value: 'Allowed',
    },
    alwaysOn: {
      label: 'Always On',
      value: 'AlwaysOn',
    },
    alwaysOff: {
      label: 'Always Off',
      value: 'AlwaysOff',
    },
  };

  static NOTIFICATIONS_OPTIONS = {
    always: {
      label: 'Always',
      value: true,
    },
    hourBefore: {
      label: 'Only starting one hour before the enforcement deadline',
      value: false,
    },
  };

  static RECOMMENDED_CADENCE_OPTIONS = {
    all: {
      label: 'All',
      value: 'All',
    },
    oldest: {
      label: 'Oldest',
      value: 'Oldest',
    },
    newest: {
      label: 'Newest',
      value: 'Newest',
    },
  };

  static DEFER_MACOS_UPDATES_OPTIONS = {
    doNotDefer: {
      label: 'Do not defer macOS updates',
      value: 'doNotDefer',
    },
    deferAll: {
      label: 'Defer all macOS updates',
      value: 'deferAll',
    },
    deferByType: {
      label: 'Defer macOS updates by type',
      value: 'deferByType',
    },
  };
}

export const softwareUpdateService = new SoftwareUpdateService();

export default SoftwareUpdateService;
