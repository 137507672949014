// istanbul ignore file
import { Button, ButtonSelect, Flex, styled } from '@kandji-inc/nectar-ui';
import type { Column } from '@tanstack/react-table';
import * as React from 'react';
import { ReorderTab, ShowHideTab } from './column-editor-tabs';

export const ColumnEditorWindow = ({
  columns,
  columnOrder,
  columnVisibility,
  onColumnVisibilityChange,
  onColumnOrderChange,
  onClose,
  viewId,
}: {
  columns: {
    pinnedColumns?: string[];
    columnDefs?: Column<{}, unknown>[];
    hiddenColumns?: string[];
    alwaysHiddenColumns?: string[];
  };
  columnOrder: string[];
  columnVisibility: Record<string, boolean>;
  onColumnVisibilityChange: (visibility: Record<string, boolean>) => void;
  onColumnOrderChange: (newOrder: string[]) => void;
  onClose: () => void;
  viewId: string | null;
}) => {
  const [selectedTab, setSelectedTab] = React.useState('show-hide');

  React.useEffect(() => {
    if (viewId) {
      setSelectedTab('show-hide');
    }
  }, [viewId]);

  return (
    <Container>
      <PanelHeader>
        <h4>Edit columns</h4>
        <Button
          compact
          variant="subtle"
          icon={{ name: 'xmark' }}
          onClick={onClose}
        />
      </PanelHeader>
      <Flex
        flow="column"
        gap="sm"
        css={{ alignSelf: 'stretch', alignItems: 'flex-start' }}
      >
        <ButtonSelect.Root
          type="single"
          value={selectedTab}
          onValueChange={(value) => {
            if (value) setSelectedTab(value);
          }}
          css={{ alignSelf: 'stretch' }}
        >
          <ButtonSelect.Item value="show-hide" css={{ width: '100%' }}>
            Show or hide columns
          </ButtonSelect.Item>
          <ButtonSelect.Item value="reorder" css={{ width: '100%' }}>
            Reorder columns
          </ButtonSelect.Item>
        </ButtonSelect.Root>
      </Flex>
      <Content>
        <InnerContainer>
          {selectedTab === 'show-hide' ? (
            <ShowHideTab
              columns={columns}
              columnOrder={columnOrder}
              columnVisibility={columnVisibility}
              onColumnVisibilityChange={onColumnVisibilityChange}
              onColumnOrderChange={onColumnOrderChange}
            />
          ) : (
            <ReorderTab
              columns={columns}
              columnOrder={columnOrder}
              columnVisibility={columnVisibility}
              onColumnVisibilityChange={onColumnVisibilityChange}
              onColumnOrderChange={onColumnOrderChange}
            />
          )}
        </InnerContainer>
      </Content>
    </Container>
  );
};

const Container = styled(Flex, {
  display: 'flex',
  width: '440px',
  padding: '0px 24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  borderLeft: '1px solid $separator_default',
  background: '$surface_primary',
  boxShadow: '0px 0px 0px 0px rgba(15, 19, 23, 0.00)',
  height: '100%',
});

const PanelHeader = styled(Flex, {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch',
  '& h4': {
    flex: '1 0 0',
    color: '$text_primary',
    fontFeatureSettings: "'liga' off",
    fontFamily: 'Untitled Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '32px /* 160% */',
    letterSpacing: '-0.8px',
  },
});

const Content = styled(Flex, {
  display: 'flex',
  height: '784px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
  background: '$neutral0',
  overflow: 'hidden',
  flex: 1,
});

const InnerContainer = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch',
  height: '100%',
  paddingBottom: '24px',
});
