/* istanbul ignore file */
import { t } from '@kandji-inc/nectar-i18n';

export const common = {
  error: () => t('Something went wrong'),
  numItems: (count: number) =>
    t('{count, plural, one {# item} other {# items}}', { count }),
  numDevices: (count: number) =>
    t('{count, plural, one {# device} other {# devices}}', { count }),
};
