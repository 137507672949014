import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Select, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React, { useState } from 'react';
import { blueprintTypes } from 'src/app/common/constants';

const ComputerChangeBlueprint = (props) => {
  const {
    blueprintNames,
    blueprintRecord,
    changeBlueprint,
    getBlueprints,
    getComputer,
    info,
    onHide,
    startGetBlueprint,
    updateComputer,
    blueprints,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedBlueprint, setSelectedBlueprint] = useState();

  const blackListOfBP = info.blackListOfBlueprint || [];
  const flowBlueprints = blueprints
    .filter((bp) => bp.type === blueprintTypes.flow)
    .map((bp) => bp.id);

  const blueprintOptions = Object.keys(blueprintNames)
    .filter((bpId) => !blackListOfBP.includes(bpId))
    .map((el) => ({ value: el, label: blueprintNames[el] }));

  const onChangeBlueprint = /* istanbul ignore next */ () => {
    const computerIds = info.computers || [info.computerId];
    if (!info.computers && !info.computerId) {
      return toaster(i18n.t('No Device Selected'));
    }

    setIsLoading(true);
    changeBlueprint(computerIds, selectedBlueprint)
      .then(() => {
        // when we change blueprint for one computer we do it synchronously
        // if computers > 1 then backend will do it in the background
        if (computerIds.length > 1) {
          onHide();
          setIsLoading(false);
          toaster(
            i18n.t(
              'Blueprint is currently being changed for selected devices, this may take a few minutes...',
            ),
          );
          return;
        }

        if (blueprintRecord.id) {
          startGetBlueprint(blueprintRecord.id);
        }
        const promises = [
          getBlueprints?.(),
          updateComputer?.({ blueprint_id: selectedBlueprint }),
        ];
        if (info.computerId) {
          promises.push(getComputer?.(info.computerId));
        } else if (info.fetchComputers) {
          promises.push(info.fetchComputers?.());
        }
        Promise.all(promises).then(() => {
          onHide();
          setIsLoading(false);
          toaster(i18n.t('New blueprint was set'));
        });
      })
      .catch((err) => {
        onHide();
        setIsLoading(false);
        console.log(err);
      });
    return null;
  };

  const content = (
    <Flex flow="column" gap="lg">
      <Text>
        Selected devices will begin moving as soon as this change is made. This
        may take some time.
      </Text>
      <Select
        label="Assign device to the Blueprint:"
        searchable
        placeholder="Select Option"
        options={blueprintOptions}
        onChange={setSelectedBlueprint}
      />
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="primary"
        loading={isLoading}
        disabled={!selectedBlueprint}
        onClick={onChangeBlueprint}
      >
        Change
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Assign Blueprint"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default ComputerChangeBlueprint;
