import { Paragraph } from '@kandji-inc/nectar-ui';
import { useHistory } from 'react-router-dom';
import { useSidebarToast } from 'src/hooks/useSidebarToast';
import ActionDialog from '../../../../components/action-dialog';
import useDeleteIntegration from '../../hooks/use-delete-integration';

type Props = {
  isDialogOpen: boolean;
  integrationId: string;
  handleToggle: () => void;
};

const DeleteIntegration = (props: Props) => {
  const { isDialogOpen, integrationId, handleToggle } = props;
  const history = useHistory();
  const { displayToast } = useSidebarToast();

  const deleteIntegrationMutation = useDeleteIntegration();
  const isDeleting = deleteIntegrationMutation.isPending;
  const handleDelete = () => {
    /* istanbul ignore next */
    if (integrationId) {
      deleteIntegrationMutation.mutate(integrationId, {
        onSuccess: () => {
          handleToggle();
          history.push('/integrations');
        },
        onError: (error) => {
          const errorTitle =
            error?.message ||
            'Could not delete the integration. Please try again.';
          displayToast({
            title: errorTitle,
            variant: 'error',
          });
        },
      });
    }
  };

  const dialogContent = () => (
    <>
      <Paragraph>
        Deleting this integration removes the ability for Intune to access
        compliance details on your Apple fleet and grant end-users to resources.
        This action cannot be undone.
      </Paragraph>
      <Paragraph>Are you sure you want to delete this integration?</Paragraph>
    </>
  );

  return (
    <ActionDialog
      size="sm"
      title="Delete integration"
      content={dialogContent()}
      isOpen={isDialogOpen}
      nextText="Delete integration"
      nextClick={handleDelete}
      backText="Cancel"
      backClick={handleToggle}
      isProcessing={isDeleting}
    />
  );
};

export default DeleteIntegration;
