import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Banner } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import KandjiSupportLink from '../../common/KandjiSupportLink';
import { isPinEnabled } from '../../common/helpers';
import { getPin } from '../api';
import PINModal from './PINModal';

const ShowPINField = styled('span')`
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

const ErasedNotification = ({
  computerId,
  eraseStatus,
  deviceFamily,
  deviceModel,
  osVersion,
  activationLockSupported,
}) => {
  const [pin, setPin] = useState(null);
  const [showPin, setShowPin] = useState(false);
  const onShowClick = useCallback(() => {
    getPin(computerId)
      .then((res) => {
        setPin(res.pin);
        setShowPin((prev) => !prev);
      })
      .catch(() => {
        toaster(i18n.t('Failed to get the PIN code'));
      });
  }, []);
  const onClose = () => {
    setShowPin(false);
  };
  const supportLink = (
    <KandjiSupportLink
      href="https://support.kandji.io/support/solutions/articles/72000560469"
      text=" Learn more."
    />
  );

  const pinEnabled = isPinEnabled(
    deviceFamily,
    osVersion,
    deviceModel,
    activationLockSupported,
  );

  const isPendingEraseWithoutPinMessage = (
    <b>This device will be erased the next time it connects to MDM.</b>
  );

  const isPendingEraseWithPinMessage = (
    <b>
      This device will be erased and locked with
      <ShowPINField onClick={onShowClick}>this PIN</ShowPINField> the next time
      it connects to MDM.
    </b>
  );

  const hasBeenErasedWithoutPinMessage = <b>This device was erased.</b>;

  const hasBeenErasedWithPinMessage = (
    <b>
      This device was erased. It can be unlocked using
      <ShowPINField onClick={onShowClick}>this PIN.</ShowPINField>
    </b>
  );

  return (
    <Banner
      theme="danger"
      text={
        <>
          {eraseStatus === 'pending' && (
            <span>
              {pinEnabled
                ? isPendingEraseWithPinMessage
                : isPendingEraseWithoutPinMessage}
            </span>
          )}
          {eraseStatus === 'yes' && (
            <span>
              {pinEnabled
                ? hasBeenErasedWithPinMessage
                : hasBeenErasedWithoutPinMessage}
              {supportLink}
            </span>
          )}
          {pin && <PINModal pin={pin} showPin={showPin} onClose={onClose} />}
        </>
      }
    />
  );
};

ErasedNotification.propTypes = {
  computerId: PropTypes.string.isRequired,
  eraseStatus: PropTypes.string.isRequired,
  deviceFamily: PropTypes.string.isRequired,
  deviceModel: PropTypes.string.isRequired,
  osVersion: PropTypes.string.isRequired,
  activationLockSupported: PropTypes.bool,
};

ErasedNotification.defaultProps = {
  activationLockSupported: false,
};

export default ErasedNotification;
