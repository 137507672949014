/* istanbul ignore file */
import { Button, Icon, setClass } from '@kandji-inc/bumblebee';
import { usePermissions } from 'contexts/account';
import Menu from 'features/self-service-new/menu';
import { i18n } from 'i18n';
import React from 'react';
import { Link } from 'react-router-dom';
import colors from '../colors';
import { paths } from '../common';

const BlueprintListItem = (props) => {
  const { blueprint, onDelete, onDuplicate, onEdit } = props;
  const permissions = usePermissions();

  return (
    <Link
      key={blueprint.id}
      className="bl-blueprint-list__item decorate-off"
      to={paths.library(blueprint.id)}
    >
      <div className="b-flex">
        <div
          className="bl-blueprint-list__item-img"
          style={{
            background: colors[blueprint.color] || 'orange',
          }}
        >
          <i className={setClass(blueprint.icon, 'ss-icon')} />
        </div>
      </div>
      <h3 className="b-h3 bl-blueprint-list__item-name">{blueprint.name}</h3>
      <p className="b-txt-light bl-blueprint-list__item-description">
        {blueprint.description}
      </p>
      <div className="bl-blueprint-list__item-counts b-txt-light b-mt-tiny">
        <Icon name="desktop" className="b-mr-tiny" />
        <p className="b-txt-light">
          {i18n.common.numDevices(blueprint.computers_count)}
        </p>
      </div>
      {permissions.canManageBlueprints && (
        <div className="bl-blueprint-list__item-action">
          <Menu
            options={[
              {
                name: i18n.t('Edit Blueprint'),
                icon: 'pencil',
                onClick: onEdit,
              },
              {
                name: i18n.t('Duplicate Blueprint'),
                icon: 'copy',
                onClick: onDuplicate,
              },
              { type: 'line' },
              {
                name: i18n.t('Delete Blueprint'),
                icon: 'trash-can',
                theme: 'error',
                onClick: onDelete,
              },
            ]}
            placement="bottom"
          >
            <div>
              <Button data-testid="test" kind="link" icon="ellipsis" />
            </div>
          </Menu>
        </div>
      )}
    </Link>
  );
};

export default BlueprintListItem;
