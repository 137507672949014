/* istanbul ignore file */
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Badge,
  Box,
  Button,
  DropdownMenuPrimitives as DropdownMenu,
  Flex,
  Icon,
  Loader,
  ScrollContainer,
  Separator,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import deviceImagesMap from 'src/components/common/image-device/map';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import { getDeviceIconByFamily } from 'src/features/visibility/prism/utils/column-helpers/column-utils';
import { DeviceSearchList } from 'src/features/visibility/pulse/views/CreatePulseCheck/components/DeviceSearchList';
import useComputers from '../services/use-computers';
import useBlueprintFlow from '../store';

const VScrollArea = styled(Flex, {
  flexDirection: 'column',
  maxHeight: '432px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '$1',
  },
  '&:hover': {
    '&::-webkit-scrollbar-track': {
      background: 'rgba(243, 247, 250)',
      borderRadius: '$rounded',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(80, 94, 113, 0.24)',
      borderRadius: '$rounded',
      height: '50px',
    },
  },
});

const DeviceLink = styled(Link, {
  display: 'flex',
  gap: '$1',
  alignContent: 'center',

  '&:hover': {
    svg: {
      display: 'block',
    },
  },

  svg: {
    display: 'none',
  },
});
type ManualExclusionsProps = {
  devices: Array<string>;
  libraryId: string;
  canEdit: boolean;
};

const ManualExclusionsAccordion = styled(Accordion, {
  display: 'flex',
  flexDirection: 'column',
  justifySelf: 'end',
  padding: '$3',
  border: '1px solid $card_border',
  borderRadius: '$2',
});

const ManualExclusions = (props: ManualExclusionsProps) => {
  /* istanbul ignore next */
  const { devices = [], libraryId, canEdit } = props;

  // Don't show the accordion if in view-only state and there are no exclusions to show:
  /* istanbul ignore next */
  if (!canEdit && devices.length < 1) {
    return null;
  }

  const [model, setDeviceExclusions, selectedAssignmentLibraryItems] =
    useBlueprintFlow(
      useShallow((state) => [
        state.model,
        state.setDeviceExclusions,
        state.selectedAssignmentLibraryItems,
      ]),
    );

  /* istanbul ignore next */
  const handleSelectDevice = (device) => {
    if (device.selected) {
      removeDevice(device);
      return;
    }

    addDevice(device);
  };

  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams<{ id?: string }>();
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [debouncedTerm, setTerm, term] = useDebouncedState<string>('', 300);
  const searchRef = useRef<HTMLInputElement>(null);

  const isTermLongEnough = debouncedTerm.length > 1;
  const { computers: allDevices, count: totalDeviceCount } = useComputers();
  const { computers, isFetching } = useComputers(
    [debouncedTerm],
    {
      filters: JSON.stringify([
        {
          name: 'blueprint',
          operator: 'equals',
          value: [id],
        },
      ]),
      search: debouncedTerm,
    },
    Boolean(isTermLongEnough),
  );

  /* istanbul ignore next */
  const handleDropdownOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      setTimeout(() => searchRef.current?.focus(), 0);
    } else {
      setTerm('');
    }
  };

  const getDeviceRenderInfo = (computer) => ({
    id: computer.id,
    deviceId: computer.id,
    serialNumber: computer.serial_number,
    deviceName: computer.name,
    deviceFamily: computer.device_family,
    model: computer.model,
    modelId: computer.model,
    selected: selectedDevices.some((d) => d.id === computer.id),
    userName: computer.user?.name,
    userEmail: computer.user?.email,
    assetTag: computer.asset_tag,
  });

  const devicesMeta = (computers || []).map(getDeviceRenderInfo);
  const allDevicesMeta = allDevices.map(getDeviceRenderInfo);
  const blueprintHasDevices = totalDeviceCount > 0;
  const hasDevicesMatchingFilter =
    !isFetching && isTermLongEnough && devicesMeta.length > 0;

  useEffect(() => {
    setSelectedDevices(
      allDevices.filter((d) => devices.includes(d.id)).map(getDeviceRenderInfo),
    );
  }, [allDevices, selectedAssignmentLibraryItems]);

  const removeDevice = (device) => {
    setDeviceExclusions(
      model.library_item_exclusions.map((exclusion) => {
        if (exclusion.library_item_id === libraryId) {
          return {
            ...exclusion,
            computer_ids: exclusion.computer_ids.filter((d) => d !== device.id),
          };
        }
        return exclusion;
      }),
    );

    // update local state:
    setSelectedDevices((prev) => {
      return prev.filter((d) => d.deviceId !== device.deviceId);
    });
  };

  const clearAllDevices = () => {
    setDeviceExclusions(
      model.library_item_exclusions.filter(
        (exclusion) => exclusion.library_item_id !== libraryId,
      ),
    );
    setSelectedDevices([]);
  };

  const addDevice = (device) => {
    const hasExistingExclusions = model.library_item_exclusions.find(
      (excl) => excl.library_item_id === libraryId,
    );

    setDeviceExclusions(
      hasExistingExclusions
        ? model.library_item_exclusions.map((exclusion) => {
            if (exclusion.library_item_id === libraryId) {
              return {
                ...exclusion,
                computer_ids: [...exclusion.computer_ids, device.id],
              };
            }
            return exclusion;
          })
        : [
            ...model.library_item_exclusions,
            {
              library_item_id: libraryId,
              computer_ids: [device.id],
            },
          ],
    );
  };

  return (
    <ManualExclusionsAccordion type="single">
      <AccordionItem value="item-2">
        <AccordionTrigger
          customIcon={<Icon name="angle-down" size="xs" />}
          iconPosition="left"
          data-testid="manual-exclusions-accordion-trigger"
        >
          <Flex alignItems="center" gap="md">
            <Text size="2" css={{ fontWeight: '$medium' }}>
              Manual device exclusions
            </Text>
            {devices.length > 0 && (
              <Badge color="yellow" icon="hexagon-xmark">
                {devices.length}
              </Badge>
            )}
          </Flex>
        </AccordionTrigger>
        <AccordionContent
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '$4',
            paddingTop: '$2',
          }}
        >
          <Text size="1" variant="secondary">
            For troubleshooting purposes, you can temporarily exclude specific
            devices from receiving this Library Item by specifying them below.
            This exclusion list is applied anywhere on the map where this
            Library Item is used.
          </Text>

          <ScrollContainer showScrollShadowBottom maxHeight="150px">
            <Flex flow="column" gap="md">
              {devices.map((deviceId) => {
                const device = allDevicesMeta.find((d) => d.id === deviceId);
                const deviceIcon =
                  deviceImagesMap[device?.model] ??
                  getDeviceIconByFamily(device?.model);
                return (
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    gap="md"
                    key={deviceId}
                  >
                    <Flex gap="sm">
                      <img
                        height="20"
                        width="20"
                        src={deviceIcon}
                        alt={device.device_family}
                      />
                      <DeviceLink
                        to={`/devices/${deviceId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Text
                          css={{
                            fontWeight: '$medium',
                          }}
                        >
                          {device.deviceName}
                        </Text>
                        <Icon size="sm" name="arrow-up-right-from-square" />
                      </DeviceLink>
                    </Flex>
                    {canEdit && (
                      <Button
                        compact
                        size={1}
                        variant="subtleDanger"
                        icon={{ name: 'xmark' }}
                        onClick={() => {
                          removeDevice(device);
                        }}
                        css={{
                          '& svg': {
                            width: '12px',
                            height: '12px',
                          },
                        }}
                        data-testid="remove-device-btn"
                      />
                    )}
                  </Flex>
                );
              })}
            </Flex>
          </ScrollContainer>

          {canEdit && (
            <Flex justifyContent="space-between">
              <DropdownMenu.Root
                open={isOpen}
                onOpenChange={handleDropdownOpenChange}
                modal={false}
              >
                <DropdownMenu.Trigger asChild>
                  <Button variant="subtle" compact icon={{ name: 'plus' }}>
                    Add device
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                  css={{
                    zIndex: 3,
                    width: '544px',
                    marginBottom: '20px',
                  }}
                  align="end"
                  side="bottom"
                >
                  {!blueprintHasDevices && (
                    <Flex
                      flow="column"
                      alignItems="center"
                      justifyContent="center"
                      css={{ padding: '20px 0' }}
                    >
                      <Text css={{ color: '$neutral90', lineHeight: '$2' }}>
                        No devices in this Blueprint.
                      </Text>
                      <Text css={{ color: '$neutral70', lineHeight: '$2' }}>
                        Add devices to view their assignments in the map.
                      </Text>
                    </Flex>
                  )}

                  {
                    /* istanbul ignore next */ isFetching && (
                      <Flex
                        flow="column"
                        alignItems="center"
                        justifyContent="center"
                        css={{ padding: '20px 0' }}
                      >
                        <Loader size="sm" />
                        <Text css={{ color: '$neutral50', lineHeight: '$2' }}>
                          Loading device matches now...
                        </Text>
                      </Flex>
                    )
                  }

                  {!isFetching &&
                    isTermLongEnough &&
                    devicesMeta.length === 0 && (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        css={{ padding: '20px 0' }}
                      >
                        <Text css={{ color: '$neutral70', lineHeight: '$2' }}>
                          No matching devices found in this Blueprint.
                        </Text>
                      </Flex>
                    )}

                  {blueprintHasDevices && (
                    <>
                      <Box css={{ padding: '6px 12px' }}>
                        {!isFetching && !isTermLongEnough && (
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            css={{ padding: '20px 0' }}
                          >
                            <Text
                              css={{ color: '$neutral50', lineHeight: '$2' }}
                            >
                              Start typing to view results
                            </Text>
                          </Flex>
                        )}

                        <TextField
                          ref={searchRef}
                          showClearButton={Boolean(term.length)}
                          onClear={/* istanbul ignore next */ () => setTerm('')}
                          iconLeft
                          icon="magnifying-glass"
                          placeholder="Search by device name, serial number, asset tag, user name, or e-mail"
                          value={term}
                          onChange={
                            /* istanbul ignore next */ (e) =>
                              setTerm(e.target.value)
                          }
                          data-testid="device-lookup-search"
                        />
                      </Box>
                      <Flex
                        alignItems="start"
                        css={{ height: '8px', padding: '4px 0' }}
                      >
                        <Separator
                          dir="horizontal"
                          css={{ backgroundColor: '$neutral20' }}
                        />
                      </Flex>

                      {hasDevicesMatchingFilter && (
                        <>
                          <VScrollArea>
                            <DeviceSearchList
                              devices={devicesMeta}
                              searchTerm={debouncedTerm}
                              searchFields={[
                                'deviceName',
                                'serialNumber',
                                'assetTag',
                                'userName',
                                'userEmail',
                              ]}
                              onSelect={(device) => {
                                handleSelectDevice(device);
                                setSelectedDevices((prev) => {
                                  if (device.selected) {
                                    return prev.filter(
                                      (d) => d.deviceId !== device.deviceId,
                                    );
                                  }

                                  return prev.concat(device);
                                });
                                handleDropdownOpenChange(false);
                              }}
                              options={{
                                displayEmptyUser: false,
                              }}
                            />
                          </VScrollArea>
                        </>
                      )}
                    </>
                  )}
                </DropdownMenu.Content>
              </DropdownMenu.Root>
              {devices.length > 0 && (
                <Button
                  compact
                  variant="subtle"
                  onClick={clearAllDevices}
                  data-testid="manual-exclusions-clear-all"
                >
                  Clear all
                </Button>
              )}
            </Flex>
          )}
        </AccordionContent>
      </AccordionItem>
    </ManualExclusionsAccordion>
  );
};

export default ManualExclusions;
