import { updateSetting as update } from '@kandji-inc/bumblebee';
import * as React from 'react';
import { useLocation } from 'react-router';

import { timezones } from 'app/common/constants';
import useDefaultTimezone from 'components/common/hooks/use-default-timezone';
import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { useLibraryItem } from 'features/library-items/common/hooks/use-library-item';
import LibraryItemPage from 'features/library-items/common/library-item-page';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import ActivityTab from 'features/library-items/items/custom-profile/activity-tab';
import { INITIAL_MODEL } from 'features/library-items/items/tvos-releases/constants';
import {
  createTransformToApi,
  transformFromApi,
} from 'features/library-items/items/tvos-releases/service/transformers';
import { tvosReleasesService } from 'features/library-items/items/tvos-releases/service/tvos-releases-service';
import useTvosReleasesService from 'features/library-items/items/tvos-releases/service/use-tvos-releases-service';
import UpdatesCard from 'src/features/library-items/common/updates-card/UpdatesCard';
import PreviewHoverTippy from 'src/features/util/components/preview-hover-tippy';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import configs from '../../library/library-item-configurations/items/operating-systems';
import type { TvOSReleasesPageProps } from './tvos-releases.types';

function TvosReleases() {
  const { pathname } = useLocation();
  const isAdd = pathname.includes('/add');
  const service = useTvosReleasesService({ isAdd });

  const {
    pageProps,
    PlaceHolder,
  }: {
    pageProps: TvOSReleasesPageProps;
    PlaceHolder: (props: unknown) => JSX.Element;
  } = useLibraryItem({
    initialState: INITIAL_MODEL,
    useService: service,
  });

  const { model, setModel, pageState } = pageProps;
  const [validationDep, triggerValidation] = useUniqValue();
  const config = configs['tvOS Release'];

  const defaultTimezone = useDefaultTimezone();
  const selectedTimezone =
    model.updates?.enforcementTimezone ||
    timezones.find((tz) => tz.value === defaultTimezone);

  const breadcrumb = model.instanceName
    ? `${model.name} - ${model.instanceName}`
    : model.name;

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <LibraryItemPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      type={config.type}
      identifier={config.identifier}
      crumb={breadcrumb}
      service={tvosReleasesService}
      triggerValidation={triggerValidation}
      transformFromApi={transformFromApi}
      transformToApi={createTransformToApi({ selectedTimezone })}
      ActivityTab={ActivityTab}
      isNameEditable={false}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      canHaveInstanceName
      summaryInfoProps={{
        name: (
          <div className="b-flex">
            <div className="b-mr-tiny">{config.name}</div>
            {model.isPreviewChip && <PreviewHoverTippy />}
          </div>
        ),
        instanceName: model.instanceName,
        description: model.description || '',
        publisher: model.publisher,
        devices: config.devices,
        requirements: [model.osRequirements],
        currentVersion: model.currentVersion,
      }}
      supportsRules
      supportsDuplication
    >
      <UpdatesCard
        name={model.name}
        update={update('updates', setModel)}
        setting={model.updates}
        isDisabled={pageState.isDisabled}
        selectedTimezone={selectedTimezone}
        validationDep={validationDep}
        appName="tvOS"
      />
    </LibraryItemPage>
  );
}

export default TvosReleases;
