import { Button } from '@kandji-inc/bumblebee';
import { sendInviteToUser } from 'app/_actions/company';
import { i18n } from 'i18n';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FormGroup, Input, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Field, SubmissionError, reduxForm } from 'redux-form';
import { UserRoleOptions, UserRoles } from 'src/app/common/constants';
import validator from 'validator';
import {
  clearModal as callClearModal,
  setSnackbar as callSetSnackbar,
} from '../../../_actions/ui';
import { LineLoader } from '../LineLoader';
import { CompanyAccessLevelHelper } from '../tooltips/AccessLevelsHelper';
import { ModalWrapper } from './ModalWrapper';
import { ButtonsWrapper, CancelButtonWrapper } from './elements';

export const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormGroup className="col-lg-12 col-sm-12">
    <label>{label}</label>

    <Input {...input} placeholder={label} type={type} autoComplete="off" />
    {touched && error && <small className="c-light-pink">{error}</small>}
  </FormGroup>
);

const renderSelect = ({
  input,
  label,
  placeholder,
  helper,
  meta: { touched, error },
  options,
  disabled,
  widthCol = 6,
  style,
}) => (
  <FormGroup
    className={`col-lg-${widthCol} col-sm-${widthCol}`}
    style={{ paddingLeft: 0, ...style }}
  >
    {label && (
      <label>
        {label}{' '}
        {helper && (
          <i
            className="fas fa-question-circle light-grey-icon"
            id={input.name}
          />
        )}
        {helper}
      </label>
    )}
    <Select
      {...input}
      value={options.filter(({ value }) => value === input.value)}
      onChange={({ value }) => input.onChange(value)}
      options={options}
      className="form-invite"
      disabled={disabled}
      removeSelected
      placeholder={placeholder}
      searchable
      onBlur={() => input.onBlur(input.value)}
      clearable={false}
      styles={{
        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: (provider) => ({
          ...provider,
          height: '31px',
        }),
        valueContainer: (provider) => ({
          ...provider,
          height: '31px',
        }),
        control: (provider) => ({
          ...provider,
          minHeight: '31px',
        }),
      }}
    />
    {touched && error && <small className="c-light-pink">{error}</small>}
  </FormGroup>
);

export class UserInvite extends ModalWrapper {
  constructor(props) {
    super(props);
    this.modalName = 'UserInvite';
    this.submitButton = React.createRef();
  }

  submit = (params) => {
    const { setSnackbar } = this.props;
    const clearData =
      (prop) =>
      ({ [prop]: _, ...rest }) =>
        rest;
    this.setState({ isLoading: true });
    return sendInviteToUser(clearData('email2')(params))
      .then(() => {
        this.onHide();
        setSnackbar('Invite was sent');
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error) {
          throw new SubmissionError({ email: err.response.data.error });
        } else {
          this.onHide();
          setSnackbar(i18n.common.error());
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  renderHeader() {
    const { currentCompany } = this.props;
    return `Invite User to ${currentCompany.name}`;
  }

  renderBody() {
    const { handleSubmit, initialized } = this.props;
    if (!initialized) {
      return <LineLoader />;
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className="settings-contact-info">
          <Row noGutters>
            <Field
              name="first_name"
              type="text"
              label="First Name"
              component={renderField}
            />
          </Row>
          <Row noGutters>
            <Field
              name="last_name"
              type="text"
              label="Last Name"
              component={renderField}
            />
          </Row>
          <Row noGutters>
            <Field
              name="email"
              type="email"
              label="Email"
              component={renderField}
            />
          </Row>
          <Row noGutters>
            <Field
              name="email2"
              type="email"
              label="Confirm Email"
              component={renderField}
            />
          </Row>
          <Row noGutters>
            <Field
              name="role"
              type="select"
              label="Access Level"
              helper={CompanyAccessLevelHelper('role')}
              component={renderSelect}
              options={UserRoleOptions}
            />
          </Row>
        </div>

        <button type="submit" className="d-none" ref={this.submitButton} />
      </form>
    );
  }

  renderFooter() {
    const { pristine, submitting, valid } = this.props;
    const { isLoading } = this.state;
    const handleOnSubmit = () => {
      if (this.submitButton.current) {
        this.submitButton.current.click();
      }
    };
    return (
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button type="button" onClick={this.onHide} kind="outline">
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          type="button"
          disabled={pristine || isLoading || !valid}
          onClick={handleOnSubmit}
        >
          {submitting || isLoading ? 'Inviting' : 'Invite'}
        </Button>
      </ButtonsWrapper>
    );
  }
}

const validate = (values, props) => {
  const { usersList } = props;
  const errors = {};

  if (!values.email) {
    errors.email = 'required';
  } else if (!validator.isEmail(values.email)) {
    errors.email = 'invalid email address';
  }
  if (!values.email2) {
    errors.email2 = 'required';
  } else if (!validator.isEmail(values.email2)) {
    errors.email2 = 'invalid email address';
  }
  if (values.email !== values.email2) {
    errors.email2 = "emails doesn't match";
  } else if (
    usersList.filter((user) => user.email === values.email).length > 0
  ) {
    errors.email = 'User with this email already exists';
  }

  if (!values.first_name) {
    errors.first_name = 'required';
  }
  if (!values.last_name) {
    errors.last_name = 'required';
  }
  return errors;
};

const mapStateToProps = (state) => ({
  currentCompany: state.account.company,

  initialValues: {
    email: '',
    first_name: '',
    last_name: '',
    role: UserRoles.auditor,
  },
  usersList: state.users.usersList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearModal: callClearModal,
      setSnackbar: callSetSnackbar,
    },
    dispatch,
  );

UserInvite = reduxForm({
  form: 'userInviteForm',
  validate,
  onSubmit(values) {
    return values;
  },
})(UserInvite);

UserInvite = connect(mapStateToProps, mapDispatchToProps)(UserInvite);

export default UserInvite;
