import { Flex, Heading, Toast } from '@kandji-inc/nectar-ui';
import { InterfaceContext } from 'contexts/interface';
import useWhiteBg from 'features/util/hooks/use-white-bg';
import { i18n } from 'i18n';
import { useContext, useRef } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import featureFlags from 'src/config/feature-flags';
import useAccount from 'src/contexts/account';
import { EDR_PATHS } from '../../route';
import ThreatListUnassignedEmptyState from '../common/components/ThreatListUnassignedEmptyState';
import useThreatsToast from '../common/hooks/use-threats-toast';
import ThreatListByThreats from './ThreatListByThreats/ThreatListByThreats';

type ThreatListProps = Readonly<{
  isOnDeviceRecord?: boolean;
}>;

const ThreatList = (props: ThreatListProps) => {
  const { isOnDeviceRecord } = props;
  useWhiteBg(!isOnDeviceRecord);
  const { currentCompany }: any = useAccount();
  const { path, url } = useRouteMatch();
  const { sidebarOpened } = useContext(InterfaceContext);
  const toastRef = useRef(null);
  const { showToast } = useThreatsToast(toastRef);

  const isVulnFeatureFlagOn = featureFlags.getFlag('vm_06252024_vuln-poc');
  const isVulnSKUEnabled =
    currentCompany?.feature_configuration?.vulnerability_management?.enabled;
  const isVulnEnabled = isVulnFeatureFlagOn && isVulnSKUEnabled;

  return (
    <>
      <Flex flow="column">
        {!isOnDeviceRecord && !isVulnEnabled && (
          <Heading size="1" css={{ padding: '$6 $5 $2' }}>
            {i18n.t('Threats')}
          </Heading>
        )}
        {!isOnDeviceRecord && <ThreatListUnassignedEmptyState />}
        <Switch>
          <Route exact path={`${path}`}>
            <ThreatListByThreats showToast={showToast} />
          </Route>
          {/* 
            If the Vuln feature flag is on, redirect /root to /root/:threat-tab.
            If the Vuln feature flag is off, redirect /root to /root.
          */}
          {isVulnEnabled ? (
            <Redirect
              exact
              from={`${links.threat}/${EDR_PATHS.threats.path}`}
              to={`${url}`}
            />
          ) : (
            <Redirect to={`${path}`} />
          )}
        </Switch>
      </Flex>
      <Toast
        testId="threat-toaster"
        managerRef={toastRef}
        viewportCss={{
          left: sidebarOpened ? '256px' : '78px',
        }}
      />
    </>
  );
};

export default ThreatList;
