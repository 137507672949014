import { Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import VulnerabilityIcon from '../../assets/vulnerability.png';

const NoVulnerabilities = (props: {
  firstDetectedFilter: DetectionDateFilterFields;
  lastDetectedFilter: DetectionDateFilterFields;
  onDeviceRecord?: boolean;
}) => {
  const {
    firstDetectedFilter,
    lastDetectedFilter,
    onDeviceRecord = false,
  } = props;
  let copy = '';
  const target = onDeviceRecord ? 'on this device' : 'in your Mac fleet';

  // Split values like "7days" and "24hours" into "7 days" and "24 hours"
  const formatTimePeriod = (value: string) => {
    const match = value.match(/^(\d+)([a-zA-Z]+)$/);
    if (match) {
      const [, number, unit] = match;
      return `${number} ${unit}`;
    }
    return value;
  };

  // If only one date filter is applied, provide copy based on the date selected
  if (!firstDetectedFilter?.value || !lastDetectedFilter?.value) {
    const populatedFilter = firstDetectedFilter?.value.length
      ? firstDetectedFilter
      : lastDetectedFilter;

    if (!populatedFilter?.value) {
      copy = `No vulnerabilities have been detected ${target}.`;
    } else if (populatedFilter.value === 'custom_date_range') {
      copy = `No vulnerabilities have been detected ${target} within the specified detection period.`;
    } else if (populatedFilter.value === 'all_time') {
      copy = `Across all time, no vulnerabilities have been detected ${target}.`;
    } else {
      copy = `In the last ${formatTimePeriod(populatedFilter.value)}, no vulnerabilities have been detected ${target}.`;
    }
  } else {
    // If both date filters are applied, provide a generic message
    copy = `No vulnerabilities have been detected ${target} within the specified detection period.`;
  }

  return (
    <Flex alignItems="center" justifyContent="center" flow="column" hFull>
      <img
        src={VulnerabilityIcon}
        alt="Vulnerability"
        style={{ width: '50px' }}
      />
      <Heading size="4" css={{ fontWeight: '$medium', paddingTop: '$3' }}>
        No vulnerabilities detected
      </Heading>
      <Text>{copy}</Text>
    </Flex>
  );
};

export { NoVulnerabilities };
