import { useEffect } from 'react';
/* istanbul ignore file */
import '../styles/sidepanel-chat-bubble.styles.scss';

const FC_FRAME_ID = '#fc_frame';

/**
 * This hook is a utility hook for moving the chat bubble out of the way. This
 * is primarily for the when the sidebar is open. It moves the chat bubble above
 * the 'Done' button.
 */
const useAdjustSidebarChatBubble = (enabled = true, isDrawerOpen = false) => {
  useEffect(() => {
    if (!enabled && !isDrawerOpen) return;

    let chatBubble;
    new MutationObserver((_, observer) => {
      chatBubble = document?.querySelector(FC_FRAME_ID);
      if (chatBubble) {
        chatBubble?.classList?.add('fc_frame-side-panel-chat-bubble');
        observer.disconnect();
      }
    }).observe(document.documentElement, {
      childList: true,
      subtree: true,
    });

    if (isDrawerOpen) {
      chatBubble = document?.querySelector(FC_FRAME_ID);
      chatBubble?.classList?.add('fc_frame-drawer-offset-chat-bubble');
    } else {
      chatBubble = document?.querySelector(FC_FRAME_ID);
      chatBubble?.classList?.remove('fc_frame-drawer-offset-chat-bubble');
    }

    return () => {
      chatBubble?.classList?.remove('fc_frame-side-panel-chat-bubble');
      chatBubble?.classList?.remove('fc_frame-drawer-offset-chat-bubble');
    };
  }, [enabled, isDrawerOpen]);
};

export default useAdjustSidebarChatBubble;
