import { Toaster as toaster } from '@kandji-inc/bumblebee';
import React, { useState, useEffect, useContext } from 'react';
import './self-service.css';

import { InterfaceContext } from 'contexts/interface';
import deepcopy from 'deepcopy';
import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
import { LineLoader } from 'src/app/components/interface/LineLoader';
import uuidv4 from 'uuid/v4';
import { libraryItemService } from '../library-items/data-service/library-item/library-item-service';
import useSelfService from './api/use-self-service';
import KandjiIcon from './assets/kandji-icon__050523.png';
import CategoryTable from './category-table';
import PreviewSection from './previews';
import SidePanel, { useSidePanel } from './side-panel';
import u from './update';

const SelfService = () => {
  const [err, config, patch] = useSelfService();
  const { bannerTopOffset } = useContext(InterfaceContext);
  const { type, isOpen, openPanelType, closePanel, currArgs } = useSidePanel();
  const [model, setModel] = useState(config);
  const update = u(setModel);

  // TODO: rework after Settings > General UI refresh
  // useEffect(() => {
  //   const alertsLayer = document.querySelector('#UniversalAlertsLayer');
  //   const compSettings = document.querySelector('.k-company-settings');
  //   alertsLayer.classList.add('k-self-service__alerts-layer');
  //
  //   const originalPad = compSettings.style.paddingTop;
  //   compSettings.style.paddingTop = `${bannerTopOffset + 32}px`;
  //
  //   return () => {
  //     alertsLayer.classList.remove('k-self-service__alerts-layer');
  //     compSettings.style.paddingTop = originalPad;
  //   };
  // }, []);

  useEffect(() => {
    if (config) {
      setModel((p) => ({ ...p, ...config }));
    }
  }, [config]);

  /* Retrieves list of library items attached to the given self service category id. */
  const getConflicts = (id) =>
    libraryItemService
      .find({ for_library: true })
      .then((r) =>
        r.data.filter((d) => d?.data?.self_service_data?.category === id),
      )
      .catch(() => toaster(i18n.common.error()));

  /* Deletes the given self service category with id, and optionally
      reassigns all library items attached to that category to the given
      category reassignId */
  const onDelete = (id, reassignId) => {
    const categoryName = model.categories.find((c) => c.id === id)?.name;
    return patch({
      ...model,
      categories: model.categories.filter((cat) => cat.id !== id),
      migrations: reassignId ? [{ previous: id, current: reassignId }] : [],
    })
      .then((r) => {
        toaster(`${categoryName} has been deleted.`);
        return r;
      })
      .catch(() => toaster(i18n.common.error()));
  };

  const onSave = (category, isEdit = false) => {
    const copyCat = deepcopy(model.categories);

    if (isEdit) {
      const idx = model.categories.findIndex((cat) => cat.id === category.id);
      copyCat[idx] = category;
      return patch({
        ...model,
        categories: copyCat,
      }).catch(() => toaster(i18n.common.error()));
    }

    return patch({
      ...model,
      categories: [
        ...copyCat,
        {
          id: uuidv4(),
          name: category.name,
          icon: category.icon,
          description: category.description,
        },
      ],
    }).catch(() => toaster(i18n.common.error()));
  };

  const onSaveRecAllCategory = (category, isRecCategory = false) => {
    if (isRecCategory) {
      const { isCustomNameSelected, customName, description, icon, iconImg } =
        category;

      const recCat = {
        name: isCustomNameSelected ? customName : category.name,
        description,
        icon,
        iconImg,
      };
      return patch({
        ...model,
        recommended: recCat,
      }).catch(() => toaster(i18n.common.error()));
    }

    return patch({
      ...model,
      all: category,
    }).catch(() => toaster(i18n.common.error()));
  };

  if (err) {
    return <div>error</div>;
  }

  if (!model) {
    /* istanbul ignore next */
    return <LineLoader isDelayed />;
  }

  return (
    <div className="k-self-service">
      <Setting.Card>
        <Setting.Header>
          <div className="b-flex-vc b-flex-g">
            <img src={KandjiIcon} alt="Kandji" className="self-service__icon" />
            <h1 className="b-h1 k-self-service__heading">
              {i18n.t('Self Service')}
            </h1>
          </div>
        </Setting.Header>
        <Setting.SubHeader>
          <p className="b-txt">
            Self Service is an internal app store for your users. You can add
            App Store Apps, Auto Apps, Profiles and Managed Operating Systems.
            Self Service is located in the Applications folder, and will be
            available even if Library Items have not yet been configured for
            Self Service.{' '}
            <a
              href="https://support.kandji.io/support/solutions/folders/72000558148"
              className="b-alink"
              target="_blank"
              rel="noreferrer"
            >
              Learn more...
            </a>
          </p>
        </Setting.SubHeader>
      </Setting.Card>
      <PreviewSection
        onCustomize={() => openPanelType.customize()}
        model={model}
      />
      <CategoryTable
        model={model}
        update={setModel}
        getConflicts={getConflicts}
        onDelete={onDelete}
        patch={patch}
        onEditRecommended={openPanelType.editRecommended}
        onEditAll={openPanelType.editAll}
        // onAdd={tempAddFake}
        onAdd={openPanelType.add}
        onEdit={openPanelType.edit}
      />
      <SidePanel
        model={model}
        update={update}
        patch={patch}
        getConflicts={getConflicts}
        onDelete={onDelete}
        onSave={onSave}
        onSaveRecAllCategory={onSaveRecAllCategory}
        type={type}
        isOpen={isOpen}
        closePanel={closePanel}
        openPanelType={openPanelType}
        currArgs={currArgs}
      />
    </div>
  );
};

export default SelfService;
